import React, {FC, useEffect, useState} from "react";

const PieLegends: FC<any> = ({legends, data, colors, setRender, setData}) => {

  const [elementos, setElementos] = useState();

  const eventClickLegend = (label, index) => {
    let info: any[] = Array.from(data);
    if (elementos[index].estado == "activo") {
      elementos[index] = {
        ...elementos[index],
        "color": 'gray',
        "decoration": 'line-through',
        "estado": "borrado"
      }
      info[index]["id_delete"] = label;
      info[index]["value_delete"] = info[index]["value"];
      delete(info[index]["id"]);
      delete(info[index]["value"]);
      setElementos(elementos);
    } else {
      elementos[index] = {
        ...elementos[index],
        "color": '#000000',
        "decoration": 'none',
        "estado": "activo"
      }
      info[index]["id"] = label;
      info[index]["value"] = info[index]["value_delete"];
      delete(info[index]["id_delete"]);
      delete(info[index]["value_delete"]);
      setElementos(elementos);
    }
    setData(info);
    setRender(prevState => prevState + 1);
  }

  useEffect(() => {
    const elemento = legends.map(item => {
      return {
        "label": item,
        "color": '#000000',
        "decoration": 'none',
        "estado": "activo"
      }
    });
    setElementos(elemento)
  }, [legends]);

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
        {elementos?.map((key, index) => (
          <div key={key} style={{marginRight: '20px', display: 'flex', alignItems: 'center', cursor: "pointer"}}>
            <div style={{
              width: '10px',
              height: '10px',
              backgroundColor: colors[index],
              marginRight: '5px'
            }}></div>
            <span
              onClick={() => eventClickLegend(key.label, index)}
              style={{color: key.color, textDecoration: key.decoration}}
            >{key.label}</span>
          </div>
        ))}
      </div>
    </>
  );
}

interface IPieLegends {
  data: any,
  colors: string[],
  legends: string[],
  setRender: React.Dispatch<any>
}

export default PieLegends;
