import React from "react";
import {FC} from "react";
import MUIDataTable from "mui-datatables";
import {useTranslation} from "react-i18next";
import {useListPendientesJuridico} from "./useListPendientesJuridico";
import {IEstado} from "../../../../core/models/administracion/estadosModel";
import {optionsDataTable} from "../../../../shared/utilities";

const PendienteJuridico: FC<any> = () => {

  const {t} = useTranslation();

  const {
    juridicos,
    columns,
    datatable,
    changePage,
    handleSearch
  } = useListPendientesJuridico();

  const {count, length, page, searchText, filterList} = datatable;

  const options = {
    ...optionsDataTable,
    filter: false,
    count,
    rowsPerPage: length,
    page,
    rowsPerPageOptions: [],
    viewColumns: false,
    serverSide: true,
    sort: false,
    searchText,
    caseSensitive: false,
    onSearchChange(searchText) {
      handleSearch(searchText);
    },
    onChangePage(currentPage) {
      changePage(currentPage);
    },
  };

  return (
    <>
      <div style={{marginBottom: 45}}>
        <MUIDataTable
          title={(<h5>{t('Procesos Juridicos')}</h5>)}
          data={juridicos.map(proceso => ([
            [proceso.consecutivo, proceso.id],
            proceso.idpostcontractual?.consecutivo || proceso?.numerocontrato,
            (proceso.idestados as IEstado)?.nombre,
            proceso.id
          ]))}
          columns={columns}
          options={options}
        />
      </div>
    </>
  );
}

export default PendienteJuridico;
