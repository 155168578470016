import { getRequest, updateItem, deleteItem, postRequestForm } from '../peticionesService';
import urlApi from '../../api';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';
import { IPrecontractual, IPrecontractualForm } from '../../models/precontractual/precontractualModel';
import { IContractual } from '../../models/contractual/contractualModel';

/**
 * Permite obtener los registros en la bd
 */
export const contractosPrecontractual = async (id: number): Promise<IContractual[]> => {
  let data: IContractual[] = [];
  try {
    const resp = await getRequest(`${urlApi.PRE_PRECONTRACTUAL.CONTRACTS}${id}`);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener los registros en la bd
 */
export const listarPrecontractual = async (params?): Promise<IPrecontractual[]> => {
  let data: IPrecontractual[] = [];
  try {
    const resp = await getRequest(urlApi.PRE_PRECONTRACTUAL.LIST, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener el registro por id en la bd
 */
export const mostrarPrecontractual = async (id: number): Promise<IPrecontractual | null> => {
  let data: IPrecontractual | null;
  try {
    const resp = await getRequest(`${urlApi.PRE_PRECONTRACTUAL.SHOW}${id}`);
    data = resp.data?.record;
  } catch (error) {
    console.error(error);
    data = null;
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const guardarPrecontractual = async (
  values: IPrecontractualForm,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<IPrecontractual | null> => {
  const url = (!values.id) ? urlApi.PRE_PRECONTRACTUAL.CREATE : `${urlApi.PRE_PRECONTRACTUAL.EDIT}${values.id}`
  const response = await updateItem(url, values, notification, false, true, true);
  return response?.data?.record;
};

/**
 * Permite borrar un registro
 * @param id 
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const borrarPrecontractual = async (
  id: number,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = `${urlApi.PRE_PRECONTRACTUAL.DELETE}${id}`;
  return await deleteItem(url, notification);
};

/**
 * Permite editar un registro
 * @param id id registro
 * @param accion avanzar o regresar
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const estadosPrecontractual = async (
  id: number,
  accion: string,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<IPrecontractual | null> => {
  const url = `${urlApi.PRE_PRECONTRACTUAL.STATES}${id}`;
  const response = await updateItem(url, { id, accion }, notification, false, true, true);
  return response?.data?.record;
};
