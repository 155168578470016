import React, { FC, useEffect, useMemo, useState } from 'react';
import { Alert, Button, Spinner, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { borrarPreguntas, descargarPreguntas, guardarPreguntas, listarPreguntas } from '../../../../core/services/precontractual/preguntasService';
import { usePrecontractualContext } from '../../../../shared/contexts/precontractualContext';
import { IPreguntas, IPreguntasForm } from '../../../../core/models/precontractual/preguntasModel';
import ButtonCustom from '../../../../shared/components/ButtonCustom';
import { useMainContext } from '../../../../shared/contexts/mainContext';
import { changeLoading, closeModal, falseLoading, openModal, trueLoading } from '../../../../shared/contexts/actions/mainActions';
import { EEstadosPre, IBorrarRegistro } from '../../../../shared/utilities';
import FormPreguntas from '../Forms/FormPreguntas';
import ModalMsj from '../../../../shared/components/ModalMsj';
import { downloadFile, handleVerDocumento } from '../../../../shared/helpers/generales';
import { useUserContext } from '../../../../shared/contexts/userContext';

const TablaPreguntas: FC<ITablaPreguntas> = ({ tipo, idpreproveedor }) => {
  const { t } = useTranslation();
  const { mainState, mainDispatch, handleNotification } = useMainContext();
  const { estado, proceso, roles } = usePrecontractualContext();
  const { userState } = useUserContext();

  const [loading, setLoading] = useState(true);
  const [preguntas, setPreguntas] = useState<IPreguntas[]>([]);
  const [borrar, setBorrar] = useState<IBorrarRegistro>({
    show: false,
    id: 0
  });

  const preguntasFiltradas = useMemo(() => {
    if (idpreproveedor) {
      return preguntas.filter(item => item.idpreproveedor.id === idpreproveedor)
    } else {
      return preguntas;
    }
  }, [preguntas, idpreproveedor]);

  const habilitarPreguntar = useMemo(() => {
    return tipo === 1 && estado === EEstadosPre.PREGUNTAS;
  }, [estado, tipo]);

  const habilitarResponder = useMemo(() => {
    return roles.solicitante && estado === EEstadosPre.RESPUESTAS;
  }, [estado, roles]);

  const listarDatos = async () => {
    const preguntas = await listarPreguntas({
      filter: 'idprecontractual',
      valuefilter: proceso?.id,
    });
    setPreguntas(preguntas);
    setLoading(false);
  }


  const onSubmit = async (values: IPreguntasForm) => {
    mainDispatch(trueLoading());
    const guardar = await guardarPreguntas(values, handleNotification);
    if (guardar) {
      await listarDatos();
      mainDispatch(closeModal());
    }
    mainDispatch(falseLoading());
  }

  const changeModal = (id?: number) => {
    setBorrar({
      show: !borrar.show,
      id: (id) ? id : 0
    });
  };

  /**
   * Permite eliminar un registro
   * @param id 
   */
  const handleDelete = async (id: number) => {
    mainDispatch(changeLoading());
    if (await borrarPreguntas(id, handleNotification)) {
      await listarDatos();
    }
    mainDispatch(changeLoading());
    changeModal();
  };

  /**
   * Permite descargar un archivo
   * @param id 
   */
  const handleDownload = async () => {
    mainDispatch(changeLoading());
    const values = {
      id: proceso?.id ?? 0
    }
    const url = await descargarPreguntas(values, handleNotification);
    if (url) {
      downloadFile(url, `preguntas-${proceso?.id}.xlsx`);
    }
    mainDispatch(changeLoading());
  };

  const handleAdd = () => {
    mainDispatch(openModal({
      modal: {
        show: true,
        title: t('Realizar Pregunta'),
        body: () => (
          <FormPreguntas
            onSubmit={onSubmit}
            record={null}
            idpreproveedor={idpreproveedor}
            habilitarPreguntar={habilitarPreguntar}
            habilitarResponder={habilitarResponder}
          />
        )
      }
    }))
  };
  
  const handleEdit = (id: number) => {
    const record = preguntas.find(row => row.id === id);
    mainDispatch(openModal({
      modal: {
        show: true,
        title: t('Responder'),
        body: () => (record ? (
          <FormPreguntas
            onSubmit={onSubmit}
            record={record}
            idpreproveedor={idpreproveedor}
            habilitarPreguntar={habilitarPreguntar}
            habilitarResponder={habilitarResponder}
          />
         ) : null
        )
      }
    }))
  };

  useEffect(() => {
    listarDatos();
  }, [])

  return (
    <>
      {loading ? (
        <div className="d-flex flex-column justify-content-center text-center">
          <Spinner color="primary" className="m-auto" />
          <span className="mt-3">
            {t('Cargando preguntas...')}
          </span>
        </div>
      ) : (
        <>
          {userState.isAutenticated && preguntas.length > 0 && (
            <ButtonCustom
              color="info"
              onClick={handleDownload}
              loading={mainState.loading}
              disabled={mainState.loading}
              outline
              className="mb-2"
              size="sm"
            >
              <i className="uil-mr uil-file"></i>{t('Descargar Excel de Preguntas Proponentes y Proveedores')}
            </ButtonCustom>
          )}
          {tipo === 1 && !!proceso?.iddocrespuestas && (
            <ButtonCustom
              color="info"
              onClick={() => handleVerDocumento(proceso?.iddocrespuestas)}
              loading={mainState.loading}
              disabled={mainState.loading}
              outline
              className="mb-2"
              size="sm"
            >
              <i className="uil-mr uil-file"></i>{t('Ver Documento de Respuestas')}
            </ButtonCustom>
          )}
          <Table>
            <thead>
              <tr>
                <th align="center">{t('Pregunta')}</th>
                <th align="center">{t('Documento Pregunta')}</th>
                <th align="center">{t('Respuesta')}</th>
                <th align="center">{t('Documento Respuesta')}</th>
                <th align="center">{t('Proveedor')}</th>
                <th align="center">{t('Creada')}</th>
                {habilitarResponder && (
                  <th align="center"></th>
                )}
              </tr>
            </thead>
            <tbody>
              {preguntasFiltradas.map(row => (
                <tr key={row.id}>
                  <td align="center">{row.pregunta}</td>
                  <td align="center">
                    {!!row.iddocpregunta && (
                      <div>
                        <Button
                          color="primary"
                          className="mt-1"
                          onClick={() => handleVerDocumento(row.iddocpregunta)}
                          size="sm"
                          outline
                        >
                          <i className="uil-mr uil-eye"></i>
                          {t('Ver anexo')}
                        </Button>
                      </div>
                    )}
                  </td>
                  <td align="center">{row.respuesta}</td>
                  <td align="center">
                    {!!row.iddocrespuesta && (
                      <div>
                        <Button
                          color="primary"
                          className="mt-1"
                          onClick={() => handleVerDocumento(row.iddocrespuesta)}
                          size="sm"
                          outline
                        >
                          <i className="uil-mr uil-eye"></i>
                          {t('Ver anexo')}
                        </Button>
                      </div>
                    )}
                  </td>
                  <td align="center">{row.idpreproveedor.idproveedorcliente?.razonsocial}</td>
                  <td align="center">{row.creado}</td>
                  {habilitarResponder && (
                    <td align="center">
                      <Button
                        color="primary"
                        className="mr-1"
                        onClick={() => handleEdit(row.id || 0)}
                        size="sm"
                        outline
                      >
                        <i className="uil-edit"></i> {t('Responder')}
                      </Button>
                      <Button
                        color="danger"
                        className="mr-1"
                        onClick={() => changeModal(row.id || 0)}
                        size="sm"
                        outline
                        title={t('Eliminar')}
                      >
                        <i className="uil-trash-alt"></i>
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
          {preguntasFiltradas.length === 0 && (
            <Alert color="warning">{t('No se han realizado preguntas.')}</Alert>
          )}
          {habilitarPreguntar && (
            <ButtonCustom
              color="info"
              onClick={handleAdd}
              loading={mainState.loading}
              disabled={mainState.loading}
              outline
              className="mb-2"
              size="sm"
            >
              <i className="uil-mr uil-plus"></i>{t('Realizar Pregunta')}
            </ButtonCustom>
          )}
        </>
      )}
      <ModalMsj
        {...borrar}
        action={handleDelete}
        closeModal={changeModal}
      />
    </>
  );
};

interface ITablaPreguntas {
  tipo?: number;
  idpreproveedor?: number;
}

export default TablaPreguntas;
