import React, { FC, useEffect, useState, Suspense, useContext, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { IRoutes, routes as routesList } from './routes';
import { isUserAuthenticated } from '../shared/helpers/authUtils';
// import { userContext } from '../shared/contexts';
import { useUserContext } from '../shared/contexts/userContext';
import Auth from '../shared/layouts/Auth';
import SignIn from '../pages/auth/SignIn';
// import Third from '../shared/layouts/Third';
// import TerceroEditLink from '../pages/administracion/terceros/tercero';
import { PrivateRoute } from './PrivateRoute';
import Third from '../shared/layouts/Third';
import Dashboard from '../shared/layouts/Dashboard';
import AprobacionDocumento from '../pages/documentos/AprobacionDocumento';
import FirmaDocumento from '../pages/documentos/FirmaDocumento';
import TokenPolizasTercero from "../pages/polizas/TokenPolizasTercero";
import Token from '../pages/usuarios/token';

const renderRoutes: FC<any> = ({ routes }) => {
  return (
    routes.map(({ layout: Layout, component: Component, ...route }) => (
      <React.Fragment key={uuidv4()}>
        {
          route?.children && route.children.length > 0 ? (
            renderRoutes({ routes: route?.children })
          ) : (
            <PrivateRoute
              path={route.path}
              exact
              component={() => (
                <Layout>
                  <Component />
                </Layout>
              )}
            />
          )
        }
      </React.Fragment>
    ))
  )
}

const Router: FC = () => {
  const [routes, setRoutes] = useState<IRoutes[]>([]);
  const { userState } = useUserContext();
  const { isAutenticated } = userState;
  const logged = useMemo(() => isUserAuthenticated(true), [isAutenticated]);

  const redirect = window.location.pathname !== '/' ? window.location.pathname : '/home';

  useEffect(() => {
    setRoutes(routesList);
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Suspense fallback={
          <div className="splash active">
            <div className="splash-icon"></div>
          </div>
        }>
          <Route exact path="/" render={() => (
            <Redirect exact from="/" to={logged?.isAutenticated ? '/home' : `/login?redirect=${redirect}`} />
          )} />
          <Route
            path="/login"
            exact
            render={() => (
              <Auth>
                <SignIn />
              </Auth>
            )}
          />
          <Route
            path="/documentos/aprueba/:token"
            exact
            render={() => (
              userState.isAutenticated ?
              <Dashboard>
                <AprobacionDocumento />
              </Dashboard>
              :
              <Third>
                <AprobacionDocumento />
              </Third>
            )}
          />
          <Route
            path="/documentos/firma/:token"
            exact
            render={() => (
              userState.isAutenticated ?
              <Dashboard>
                <FirmaDocumento />
              </Dashboard>
              :
              <Third>
                <FirmaDocumento />
              </Third>
            )}
          />
          <Route
            path="/token/:id/:tokenurl"
            exact
            render={() => (
              <Third>
                <Token />
              </Third>
            )}
          />
          <Route
            path="/polizas/tercero/:token"
            exact
            render={() => (
              <Third>
                <TokenPolizasTercero />
              </Third>
            )}
          />
          {routes.map(({ layout: Layout, component: Component, ...route }) => {
            return (
              <React.Fragment key={uuidv4()}>
                {
                  route?.children && route.children.length > 0 ? (
                    renderRoutes({ routes: route?.children })
                  ) : (
                    <PrivateRoute
                      path={route.path}
                      exact
                      component={() => (
                        <Layout>
                          <Component />
                        </Layout>
                      )}
                    />
                  )
                }
              </React.Fragment>
            );
          })}
        </Suspense>
      </Switch>
    </BrowserRouter>
  );
}
export default Router;
