import React, {FC, useState} from "react";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink, TabContent, TabPane,
} from 'reactstrap';
import {useTranslation} from "react-i18next";
import classnames from "classnames";
import Graficas from "./components/Graficas";
import Manuales from "./components/manuales";
import Pendientes from "./components/pendientes";


const HomeComponent: FC = () => {
  const {t} = useTranslation();

  const [tab, setTab] = useState<string>('');
  const [tabModule, setTabModule] = useState<string>('graficas');

  const toggleTabModule = (tabSelected: string) => {
    if (tab !== tabSelected) {
      setTabModule(tabSelected);
    }
  };

  return (
    <Row>
      <Col md={12}>
        <div className="tab">
          <Nav tabs>
            <NavItem>
              <NavLink className={`cursor-pointer ${classnames({active: tabModule === 'graficas'})}`}
                       onClick={() => toggleTabModule('graficas')}>
                {t('Gráficas')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={`cursor-pointer ${classnames({active: tabModule === 'pendientes'})}`}
                       onClick={() => toggleTabModule('pendientes')}>
                {t('Mis Pendientes')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={`cursor-pointer ${classnames({active: tabModule === 'manuales'})}`}
                       onClick={() => toggleTabModule('manuales')}>
                {t('Manuales')}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={tabModule}>
            <TabPane tabId="graficas">
              <hr/>
              <Row>
                <Col md={12}>
                  <Graficas />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="pendientes">
              <hr/>
              <Row>
                <Col md={12}>
                  <Pendientes />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="manuales">
              <hr/>
              <Row>
                <Col md={12}>
                  <Manuales />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </Col>
    </Row>
  )
};
export default HomeComponent;
