import { FormGroup } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormFeedback, Input, Label, ModalBody, ModalFooter, Row } from 'reactstrap';
import dev from '../../../core/enviroments';
import { IModulos } from "../../../core/models/administracion/estadosModel";
import { IContractual } from '../../../core/models/contractual/contractualModel';
import { initialState, formSchema } from '../../../core/models/polizas/polizasModel';
import { crearDocumento, obtenerDocumentos } from '../../../core/services/procesos/documentoService';
import { savePoliza } from '../../../core/services/polizas/polizasService';
import ButtonCustom from '../../../shared/components/ButtonCustom';
import ButtonLoadFile from '../../../shared/components/ButtonLoadFile';
import OldDateField from '../../../shared/components/OldDateField';
import OldNumberField from '../../../shared/components/OldNumberField';
import { changeLoading, closeModal } from '../../../shared/contexts/actions/mainActions';
import { useMainContext } from '../../../shared/contexts/mainContext';
import { useUserContext } from '../../../shared/contexts/userContext';
import { usePolizaContext } from '../../../shared/contexts/polizaContext';
import { EModulos } from '../../../shared/utilities';
import DocumentosPolizas from './DocumentosPolizas';
import FormAmparos from './FormAmparos';

const FormPoliza: FC<IFormPoliza> = ({ amparos, data, listarPolizas, listarPolizasToken, disabled = false, rolAbogado = false, tokenTercero = false, token = null, modulo = '' }) => {
  const { mainState, mainDispatch, handleNotification } = useMainContext();
  const { disableFieldsAbogado, disableFieldsTercero } = usePolizaContext();
  const { userState } = useUserContext();
  const { t } = useTranslation();

  const [showAmparos, setShowAmparos] = useState<boolean>(false);
  const [caracteristicasList, setCaracteristicasList] = useState<any[]>([]);
  const [documentoPoliza, setDocumentoPoliza] = useState<any>([]);
  const [documentoPago, setDocumentoPago] = useState<any>([]);

  const handleSavePoliza = async (values: any) => {
    const data = {
      ...values,
      base: values?.base?.id,
    };
    mainDispatch(changeLoading());
    if (await savePoliza(data, handleNotification)) {
      if(tokenTercero){
        listarPolizasToken(values?.json?.base?.id);
      } else {
        listarPolizas(values?.json?.base?.id);
      }
    }
    mainDispatch(changeLoading());
  };

  const changeValue = async (e: any) => {
    mainDispatch(changeLoading());

    const json = {
      idcontractual: data?.base?.referencia,
      idrequerimientos: data?.id,
      idcargadopor: userState.data?.sub,
      categoria: (e.target.name === 'documentopoliza') ? 2 : 3,
    }

    const dataSend = {
      archivo: e.target.files[0],
      json
    };
    if (await crearDocumento(dataSend, handleNotification)) {
      handleDocumentos();
    }
    mainDispatch(changeLoading());
    // setDocMinuta(e.target.files[0]);
  };

  const handlePoliza = () => {
    if (tokenTercero) {
      listarPolizasToken(token);
    } else {
      listarPolizas(data?.base?.id);
    }
  };

  const handleDocumentos = async () => {
    const params = {
      filter: 'idrequerimientos',
      valuefilter: data?.id
    };
    const respFiles = await obtenerDocumentos(modulo, params);
    setDocumentoPoliza(respFiles.filter(file => file?.idcategoria?.nombremaquina === 'documento_poliza').map(file => ({
      ...file,
      creado: undefined,
      idcargadopor: undefined,
      idcontractual: (file?.idcontractual as IContractual)?.id
    })));
    setDocumentoPago(respFiles.filter(file => file?.idcategoria?.nombremaquina === 'documento_pago').map(file => ({
      ...file,
      creado: undefined,
      idcargadopor: undefined,
      idcontractual: (file?.idcontractual as IContractual)?.id
    })));
  };

  useEffect(() => {
    const filterAmparos = amparos.filter(amparo => amparo.requerimiento?.id === data?.requerimiento?.id);

    setShowAmparos(filterAmparos.length > 0);
    setCaracteristicasList(filterAmparos);
    handleDocumentos();
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialState({
        ...data,
        documentos: {
          documento_poliza: documentoPoliza,
          documento_pago: documentoPago,
        }
      })}
      validationSchema={formSchema}
      onSubmit={handleSavePoliza}
    >
      {({ values, errors, touched, isSubmitting }) => {
        const errorInicio: any = errors.inicio || '';
        const errorFin: any = errors.fin || '';
        return (
          <>
            <Form>
              <ModalBody>
                <Row>
                  <Col sm={12} md={6} className="mt-2">
                    <FormGroup>
                      <Label>{t('Aseguradora')}: </Label>
                      <Field
                        as={Input}
                        name="aseguradora"
                        placeholder={t('Ingrese la aseguradora')}
                        invalid={errors.aseguradora && touched.aseguradora}
                        disabled={disableFieldsTercero}
                      />
                      <FormFeedback>{errors.aseguradora && t(errors.aseguradora)}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6} className="mt-2">
                    <FormGroup>
                      <Label>{t('Número')}: </Label>
                      <Field
                        component={OldNumberField}
                        name='numero'
                        placeholder={t('Ingrese el número')}
                        className={(errors.numero) ? 'is-invalid form-control' : 'form-control'}
                        disabled={disableFieldsTercero}
                      />
                      <FormFeedback>{errors.numero && t(errors.numero)}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6} className="mt-2">
                    <FormGroup>
                      <Label>{t('Número de endoso')}: </Label>
                      <Field
                        component={OldNumberField}
                        name='numeroendoso'
                        placeholder={t('Ingrese el número de endoso')}
                        className={(errors.numeroendoso && touched.numeroendoso) ? 'is-invalid form-control' : 'form-control'}
                        invalid={errors.numeroendoso}
                        disabled={disableFieldsTercero}
                      />
                      <FormFeedback>{errors.numeroendoso && t(errors.numeroendoso)}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6} className="mt-2">
                    <FormGroup>
                      <Label>{t('Certificado')}: </Label>
                      <Field
                        as={Input}
                        name="certificado"
                        placeholder={t('Ingrese la certificado')}
                        invalid={errors.certificado && touched.certificado}
                        disabled={disableFieldsTercero}
                      />
                      <FormFeedback>{errors.certificado && t(errors.certificado)}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6} className="mt-2">
                    <FormGroup>
                      <Label>{t('Fecha inicial')}</Label>
                      <Field
                        name='inicio'
                        dateFormat="yyyy-MM-dd"
                        selected={values.inicio ? new Date(values.inicio) : null}
                        component={OldDateField}
                        className={(errors.inicio && touched.inicio) ? 'is-invalid form-control' : 'form-control'}
                        disabled={disableFieldsTercero}
                      />
                      <FormFeedback style={{
                        display: (errors.inicio && touched.inicio) ? 'block' : 'none'
                      }}>{t(errorInicio)}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6} className="mt-2">
                    <FormGroup>
                      <Label>{t('Fecha final')}</Label>
                      <Field
                        name='fin'
                        dateFormat="yyyy-MM-dd"
                        selected={values.fin ? new Date(values.fin) : null}
                        component={OldDateField}
                        className={(errors.fin && touched.fin) ? 'is-invalid form-control' : 'form-control'}
                        disabled={disableFieldsTercero}
                      />
                      <FormFeedback style={{
                        display: (errors.fin && touched.fin) ? 'block' : 'none'
                      }}>{t(errorFin)}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <hr />
                <DocumentosPolizas disabled={disableFieldsTercero} />
                {
                  (tokenTercero) && (
                    <>
                      <Row>
                        <Col sm={12} className="d-flex justify-content-end">
                          <ButtonCustom
                            className="d-flex align-items-center"
                            type="submit"
                            color="primary"
                            loading={isSubmitting || mainState.loading}
                            disabled={isSubmitting || mainState.loading}
                          >
                            {t('Guardar')}
                          </ButtonCustom>
                        </Col>
                      </Row>
                    </>
                  )
                }
                {/* <Row className="mt-4">
                  <Col sm={6}>
                    <Row>
                      <ButtonLoadFile
                        id="docPoliza"
                        name="documentopoliza"
                        title={t('Cargar documento de póliza')}
                        onChange={changeValue}
                        disabled={false}
                        show={documentoPoliza}
                        link={documentoPoliza}
                        path={`${dev.URL_SERVER}/uploads${documentoPoliza?.path}/${documentoPoliza?.nombre}.${documentoPoliza?.extension}`}
                        filename={documentoPoliza?.categoria}
                      />
                    </Row>
                  </Col>
                  <Col sm={6}>
                    <Row>
                      <ButtonLoadFile
                        id="docPago"
                        name="documentopago"
                        title={t('Cargar documento de pago')}
                        onChange={changeValue}
                        disabled={false}
                        show={documentoPago}
                        link={documentoPago}
                        path={`${dev.URL_SERVER}/uploads${documentoPago?.path}/${documentoPago?.nombre}.${documentoPago?.extension}`}
                        filename={documentoPago?.categoria}
                      />
                    </Row>
                  </Col>
                </Row> */}
                {
                  showAmparos && (
                    <>
                      <h5 className="mt-4">{t('Amparos')}</h5>
                      {
                        !disabled && (
                          <hr />
                        )
                      }
                      <FormAmparos
                        caracteristicas={caracteristicasList}
                        handlePoliza={handlePoliza}
                        id={data?.id}
                        disabled={disabled}
                        rolAbogado={rolAbogado}
                        tokenTercero={tokenTercero}
                        token={token}
                      />
                    </>
                  )
                }
              </ModalBody>
              <ModalFooter>
                <ButtonCustom
                  className="d-flex align-items-center"
                  color="danger"
                  loading={isSubmitting || mainState.loading}
                  disabled={isSubmitting || mainState.loading}
                  onClick={() => {
                    tokenTercero ?listarPolizasToken(data?.base?.id):listarPolizas(data?.base?.id);
                    mainDispatch(closeModal())
                  }}
                >
                  {t('Cerrar')}
                </ButtonCustom>
              </ModalFooter>
            </Form>
          </>
        )
      }}
    </Formik>
  );
};
interface IFormPoliza {
  amparos: any[];
  data: any;
  listarPolizas: (id: any) => Promise<void>
  listarPolizasToken: (id: any) => Promise<void>
  disabled: any;
  rolAbogado: any;
  tokenTercero: any;
  token: string | null;
  modulo: string | null;
};
export default FormPoliza;
