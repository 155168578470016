import { AxiosRequestConfig } from 'axios';
import { useEffect, useState } from 'react';
import dev from '../../core/enviroments';
import axiosInt from '../../core/services/axiosService';
import { userLogout } from '../contexts/actions/userActions';
import { mainTypeMsj } from '../contexts/interfaces/mainInterfaces';
import { Emsg, IUserReducer } from '../contexts/interfaces/userInterfaces';
import { useMainContext } from '../contexts/mainContext';
import { useUserContext } from '../contexts/userContext';
import { isUserAuthenticated } from '../helpers/authUtils';

export const useGetGeneric = (attr: IUseGet): [any, any, () => void] => {
  const { userDispatch, userState } = useUserContext();
  const [state, setState] = useState<any>({
    request: null,
    error: null
  });

  /**
   * Función para realizar peticiones get
   */
  const handleGet = async () => {
    try {
      let CONFIG: AxiosRequestConfig = {};
      // if (auth) { // En caso de necesitar estar logueado se pasan esos parametros
      if (userState?.isAutenticated) { // si su token se encuentra activo se ejecuta la petición
        const dataJson: IUserReducer = isUserAuthenticated(true);
        CONFIG.headers = {
          Authorization: dataJson.data?.token
        };
      } else if (!userState?.isAutenticated && userState?.data?.token) {
        const dataJson: IUserReducer = isUserAuthenticated(true);
        CONFIG.headers = {
          Authorization: dataJson.data?.token
        };
      } else {
        userDispatch(userLogout());
        return;
      }
      if (attr?.params) {
        CONFIG.params = attr?.params;
      }
      if (attr?.url) {
        setState({
          ...state,
          request: await axiosInt.get(`${dev.URL_SERVER}${attr?.url}`, CONFIG)
        });
      }
    } catch (error) {
      setState({
        ...state,
        error
      });
    }
  };

  useEffect(() => {
    if (attr?.url) {
      handleGet();
    }
  }, [attr?.url]);

  return [
    state.request,
    state.error,
    handleGet
  ];
};

export const useGet = ({ params, url = null, auth = true, initialState = null, handle = false }: IUseGet): [any, (any?, boolean?) => Promise<void>, boolean] => {
  const { userDispatch, userState } = useUserContext();
  const { handleNotification } = useMainContext();
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);

  /**
   * Función para realizar peticiones get
   */
  const handleGet = async (params, auth = true) => {
    setLoading(true);
    try {
      let CONFIG: AxiosRequestConfig = {};
      if (auth) { // En caso de necesitar estar logueado se pasan esos parametros
        if (userState?.isAutenticated) { // si su token se encuentra activo se ejecuta la petición
          const dataJson: IUserReducer = isUserAuthenticated(true);
          CONFIG.headers = {
            Authorization: dataJson.data?.token
          };
        } else if (!userState?.isAutenticated && userState?.data?.token) {
          const dataJson: IUserReducer = isUserAuthenticated(true);
          CONFIG.headers = {
            Authorization: dataJson.data?.token
          };
        } else {
          userDispatch(userLogout());
          return;
        }
      }
      if (params) {
        CONFIG.params = params;
      }

      const resp: any = await axiosInt.get(`${dev.URL_SERVER}${url}`, CONFIG);
      if (resp?.data?.code === 403) {
        handleNotification(Emsg.expired, mainTypeMsj.ERROR);
        userDispatch(userLogout());
        setLoading(false);
        return;
      }
      setState(resp?.data?.records || resp.data.record || resp.data.data || initialState);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      handleNotification('Error inesperado', mainTypeMsj.ERROR);
    }
  };

  useEffect(() => {
    if (url && !handle) {
      handleGet(params, auth);
    }
  }, [url]);

  return [
    state,
    handleGet,
    loading
  ];
};
interface IUseGet {
  url?: string | null;
  params?: any;
  auth?: true;
  initialState?: any;
  handle?: boolean;
};
