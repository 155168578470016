import React from "react";
import {FC} from "react";
import MUIDataTable from "mui-datatables";
import {useTranslation} from "react-i18next";
import {useListPendientesAsesoria} from "./useListPendientesAsesoria";
import {IEstado} from "../../../../core/models/administracion/estadosModel";
import {ERolsProceso, optionsDataTable} from "../../../../shared/utilities";
import {listSolicitud} from "../../../../core/services/listasDesplegables";

const PendienteAsesoria: FC<any> = () => {

  const {t} = useTranslation();

  const {
    asesorias,
    columns,
    datatable,
    changePage,
    handleSearch
  } = useListPendientesAsesoria();

  const {count, length, page, searchText, filterList} = datatable;

  const options = {
    ...optionsDataTable,
    filter: false,
    count,
    rowsPerPage: length,
    page,
    rowsPerPageOptions: [],
    viewColumns: false,
    serverSide: true,
    sort: false,
    searchText,
    caseSensitive: false,
    onSearchChange(searchText) {
      handleSearch(searchText);
    },
    onChangePage(currentPage) {
      changePage(currentPage);
    },
  };

  const getAbogado = (usuarios) => {
    let user = usuarios?.find(user => user.rol === ERolsProceso.ABOGADO)?.idusuarios
    return user ? user?.nombres + " " + user?.apellidos : '';
  }

  const getSolicitante = (usuarios) => {
    let user = usuarios?.find(user => user.rol === ERolsProceso.SOLICITANTE)?.idusuarios
    return user ? user?.nombres + " " + user?.apellidos : '';
  }

  return (
    <>
      <div style={{marginBottom: 45}}>
        <MUIDataTable
          title={(<h5>{t('Asesorias')}</h5>)}
          data={asesorias.map(proceso => {
            const abogado = getAbogado(proceso?.idusuarios);
            const solicitante = getSolicitante(proceso?.idusuarios);
            return [
              [proceso.consecutivo, proceso.id],
              listSolicitud.map(({id, nombre}) => (
                (id === proceso?.solicitud) && <span>{t(nombre)}</span>
              )),
              proceso?.idareas?.nombre,
              solicitante,
              abogado,
              (proceso.idestados as IEstado)?.nombre
            ]
          })}
          columns={columns}
          options={options}
        />
      </div>
    </>
  );
}

export default PendienteAsesoria;
