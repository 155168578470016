import React, { FC } from 'react';
// Model
import { initialState, formSchema, IFormManual, IManual } from '../../../../../core/models/administracion/manualesModel';
// Reactstrap
import {
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
  ModalBody,
  CustomInput,
} from 'reactstrap';
//FORM
import { Formik, Form, Field } from 'formik';
import ModalsFooter from '../../../../../shared/components/ModalsFooter';
import DocInput from '../../../../../shared/components/DocInput';
import { useTranslation } from 'react-i18next';

const FormManual: FC<IFormManuales> = ({ onSubmit, data }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={
        initialState(data)
      }
      validationSchema={formSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, setFieldValue }) => {

        return (
          <>
            <ModalBody>
              <Form id="formManual">
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <Field
                        id="activo"
                        name='activo'
                        as={CustomInput}
                        checked={values.activo}
                        type="switch"
                        label={t('Activar manual')}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label>{t('Nombre')}</Label>
                      <Field
                        as={Input}
                        name="nombre"
                        placeholder={t('Ingrese el nombre del manual')}
                        invalid={errors.nombre && touched.nombre}
                      />
                      <FormFeedback>{errors.nombre && t(errors.nombre)}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={12}>
                    <FormGroup>
                      <Label
                        className="btn btn-primary"
                        title={t('Cargar archivo')}
                      >
                        <i className="uil-mr uil-file-upload"></i>{t('Cargar archivo')}
                        <Input
                          style={{
                            display: 'none'
                          }}
                          type="file"
                          name="file"
                          accept='.pdf'
                          onChange={(e) => {
                            setFieldValue("file", e.currentTarget.files![0]);
                          }}
                        />
                      </Label>
                      <FormFeedback style={{
                        display: (errors.file && touched.file) ? 'block' : 'none'
                      }}>{errors.file && t(errors.file)}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={12}>
                    <DocInput file={values.file} nombreDoc={data?.archivo} modulo={'manuales'} />
                  </Col>
                </Row>
              </Form>
            </ModalBody>
            <ModalsFooter
              idForm="formManual"
            />
          </>
        )
      }}
    </Formik>
  );
};
interface IFormManuales {
  data?: IManual;
  onSubmit: (values: IFormManual) => void
};
export default FormManual;
