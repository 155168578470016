import { getRequest, updateItem, deleteItem, putRequest, postRequestCustom } from '../peticionesService';
import urlApi from '../../api';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';
import { IFormContra2 } from "../../models/contractual/contractualStep2Model";
import { IFormContra3 } from "../../models/contractual/contractualStep3Model";
import { IAprobaciones, ICommentForo, IContractual } from '../../models/contractual/contractualModel';
import moment from 'moment';
import { guardarContractualUsuarios } from './contraUsuariosService';
import { IContraUsuarios, IFormContraUsuarios } from '../../models/contractual/usuariosModel';
import {useUpdateRequest} from "../../../shared/hooks/useUpdate";

/**
 * Permite obtener los registros en la bd
 */
export const obtenerProcesos = async (params?: any): Promise<IContractual[]> => {
  let data: IContractual[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_CONTRACTUAL, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener el registro por id en la bd
 */
export const obtenerProceso = async (id: number): Promise<IContractual> => {
  let data: IContractual | any = {};
  try {
    const resp = await getRequest(`${urlApi.SHOW_CONTRACTUAL}${id}`);
    data = resp.data.record;

    if (resp?.data?.polizas) {
      data = {
        ...resp.data.record,
        polizas: resp?.data?.polizas
      }
    }
  } catch (error) {
    console.error(error);
  }
  return data;
};

export const obtenerStep2 = async (): Promise<IFormContra2[]> => {
  let data: IFormContra2[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_CONTRACTUAL);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

export const obtenerStep3 = async (): Promise<IFormContra3[]> => {
  let data: IFormContra3[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_CONTRACTUAL);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite cambiar el estado de un proceso
 * @param values valores con rol que edita
 * @param notification funcion que permite generar una notificacion en el sistema 
 */

export const guardarContractual = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  file = false,
  sendNotification = true,
  requestData = false,
): Promise<boolean | any> => {
  const url = `${urlApi.EDIT_CONTRACTUAL}${values?.id}`;
  return await updateItem(url, values, notification, file, sendNotification, requestData);
};

/**
 * Permite cambiar el estado de un proceso
 * @param values valores con rol que edita
 * @param notification funcion que permite generar una notificacion en el sistema 
 */

export const guardarRevisionLegal = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = `${urlApi.INFO_COMPLETA_CONTRACTUAL}${values?.id}`;
  let success = false;

  delete values?.id;
  try {
    const resp: any = await putRequest(url, values);
    if (resp?.data?.code === 200) {
      success = true;

      notification(resp.data.message, mainTypeMsj.SUCCESS);
    } else {
      notification(resp.data.message, mainTypeMsj.ERROR);
    }
  } catch (error) {
    console.error(error);
    notification('Error inesperado', mainTypeMsj.ERROR);
  }
  return success;
};

export const requierePoliza = async (
  value: string,
  id: string,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
): Promise<boolean> => {
  const url = `${urlApi.REQUIEREPOLIZA_CONTRA}${id}`;
  let success = false;
  try {
    const resp: any = await putRequest(url, {
      requierepolizas: value
    });
    if (resp?.data?.code === 200) {
      success = true;

      notification(resp.data.message, mainTypeMsj.SUCCESS);
    } else {
      notification(resp.data.message, mainTypeMsj.ERROR);
    }
  } catch (error) {
    console.error(error);
    notification('Error inesperado', mainTypeMsj.ERROR);
  }
  return success;
};

export const aprobarSolicitante = async (
  value: string,
  id: string,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
): Promise<boolean> => {
  const url = `${urlApi.APROBAR_CONTRA_SOLICITANTE}${id}`;
  let success = false;
  try {
    const resp: any = await putRequest(url, {
      aprobarsolicitante: value
    });
    if (resp?.data?.code === 200) {
      success = true;

      notification(resp.data.message, mainTypeMsj.SUCCESS);
    } else {
      notification(resp.data.message, mainTypeMsj.ERROR);
    }
  } catch (error) {
    console.error(error);
    notification('Error inesperado', mainTypeMsj.ERROR);
  }
  return success;
};

export const aprobarTercero = async (
  value: string,
  id: string,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
): Promise<boolean> => {
  const url = `${urlApi.APROBAR_CONTRA_TERCERO}${id}`;
  let success = false;
  try {
    const resp: any = await putRequest(url, {
      aprobarminuta: value
    });
    if (resp?.data?.code === 200) {
      success = true;

      notification(resp.data.message, mainTypeMsj.SUCCESS);
    } else {
      notification(resp.data.message, mainTypeMsj.ERROR);
    }
  } catch (error) {
    console.error(error);
    notification('Error inesperado', mainTypeMsj.ERROR);
  }
  return success;
};

export const aprobarAprobador = async (
  data: any,
  id: string,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
): Promise<boolean> => {
  const url = `${urlApi.APROBAR_CONTRA_APROBADOR}${id}`;
  let success = false;
  try {
    const resp: any = await putRequest(url, data);
    if (resp?.data?.code === 200) {
      success = true;

      notification(resp.data.message, mainTypeMsj.SUCCESS);
    } else {
      notification(resp.data.message, mainTypeMsj.ERROR);
    }
  } catch (error) {
    console.error(error);
    notification('Error inesperado', mainTypeMsj.ERROR);
  }
  return success;
};

/**
 * Permite obtener los registros en la bd
 */
export const obtenerComentario = async (params: any = undefined): Promise<ICommentForo[] | null> => {
  let data: ICommentForo[] | null = null;
  try {
    const url = urlApi.COMENT_CONTRACTUAL;
    const resp = await getRequest(url, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener el registro por id en la bd
 */
export const obtenerComentariosForo = async (params?: any): Promise<ICommentForo[]> => {
  let data: ICommentForo[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_COMMENTS_CONTRACTUAL, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear un comentario
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema
 */
export const guardarComentario = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = urlApi.CREATE_COMMENT_CONTRACTUAL;
  return await updateItem(url, values, notification);
};

/**
 * Permite crear un comentario
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema
 */
 export const consultarTRM = async (
  values: any,
) => {
  const url = urlApi.CONSULTAR_TRM;
  return await postRequestCustom(url, values);
};

// /**
//  * Permite crear y editar un registro
//  * @param values valores del formulario
//  * @param notification funcion que permite generar una notificacion en el sistema
//  */
// export const guardarContractual1 = async (
//   values: any,
//   notification: (
//     msj: string,
//     type: mainTypeMsj
//   ) => void,
//   file = false
// ): Promise<boolean> => {
//   const url = (file) ? (!values?.json?.id) ? urlApi.CREAR_CONTRACTUAL : `${urlApi.EDIT_CONTRACTUAL_STEP1}${values?.json?.id}` : (!values?.id) ? urlApi.CREAR_CONTRACTUAL : `${urlApi.EDIT_CONTRACTUAL_STEP1}${values?.id}`
//   return await updateItem(url, values, notification, file);
// };

export const guardarContractual2 = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = `${urlApi.EDIT_CONTRACTUAL_STEP2}${values.id}`
  return await updateItem(url, values, notification);
};

export const guardarContractual3 = async (
  values: IFormContra3,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = `${urlApi.EDIT_CONTRACTUAL_STEP3}${values.id}`
  return await updateItem(url, values, notification);
};

/**
 * Permite borrar un registro
 * @param id 
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const borrarProceso = async (
  id: number,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = `${urlApi.BORRAR_CONTRACTUAL}${id}`;
  return await deleteItem(url, notification);
};

/**
 * Permite obtener los registros en la bd
 */
export const obtenerComentarios = async (params: any = undefined): Promise<ICommentForo[]> => {
  let data: ICommentForo[] = [];
  try {
    const url = urlApi.COMENT_CONTRACTUAL;
    const resp = await getRequest(url, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear un comentario
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema
 */
export const crearComentario = async (
  values: ICommentForo,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = urlApi.CREATE_COMENT_CONTRACTUAL;
  return await updateItem(url, values, notification);
};
/*
 * crea usuarios para procesos contractuales
 */
export const crearUsuarioContractual = async (
  idcontractual: number,
  rol: number,
  idusuarios: number,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  id?: number
) => {
  const values: IFormContraUsuarios = {
    id,
    idcontractual,
    idusuarios,
    rol,
    activo: 1
  }
  await guardarContractualUsuarios(values, notification, false);
};

/**
 * Permite obtener los registros en la bd
 */
export const obtenerListaUsuariosAprob = async (id: string | number): Promise<any[]> => {
  let data: IContraUsuarios[] = [];
  try {
    const resp = await getRequest(`${urlApi.LIST_USUARIOS_APROBACIONES}${id}`);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener el registro por id en la bd
 */
 export const obtenerAprobaciones = async (params?: any): Promise<IAprobaciones[]> => {
  let data: IAprobaciones[] = [];
  try {
    const resp = await getRequest(urlApi.APROBAR_PROCESO_CONTRA_LIST, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

export const aprobarProceso = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  sendNotificacion = false,
): Promise<boolean> => {
  const url = (values?.id) ? `${urlApi.APROBAR_PROCESO_CONTRA_EDIT}${values?.id}` : `${urlApi.APROBAR_PROCESO_CONTRA}`;
  let success = false;
  try {
    if (await updateItem(url, values, notification, false, sendNotificacion)) {
      success = true;
    }
  } catch (error) {
    console.error(error);
    notification('Error inesperado', mainTypeMsj.ERROR);
  }
  return success;
};


/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
 export const guardarValidacionFinanciera = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = (!values?.id) ? urlApi.CREAR_VALID_FINANCIERA : `${urlApi.EDIT_VALID_FINANCIERA}${values.id}`
  return await updateItem(url, values, notification, false, false);
};

/**
 * Permite obtener los registros en la bd
 */
 export const obtenerValidacionFinanciera = async (params: any = undefined): Promise<any[]> => {
  let data: any[] = [];
  try {
    const url = urlApi.LIST_VALID_FINANCIERA;
    const resp = await getRequest(url, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener los registros en la bd
 */
export const obtenerTodosProcesosContractuales = async (): Promise<
  IContractual[]
> => {
  let data: IContractual[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_CONTRACTUAL_ALL);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};


/**
 * Permite crear y actualizar un cargo
 */
export const useObtenerContractualDinamicos = (
  handle = false,
  params?,
  body?
) => {
  const [guardarService] = useUpdateRequest();

  const handleGetContractual = async (
    body: any,
    requestData = true
  ): Promise<any> => {
    return guardarService({
      url: urlApi.DATATABLE_CONTRACTUAL,
      contentType: "application/json",
      sendNotification: false,
      body,
      requestData,
    });
  };
  return {
    handleGetContractual,
  };
};

/**
 * Permite crear y actualizar un cargo
 */
export const useObtenerProcesosDinamicosContractuales = (
  url?: string,
  handle = false,
  params?,
  body?
) => {
  const [guardarService] = useUpdateRequest();

  const handleGetContractual = async (
    body: any,
    requestData = true
  ): Promise<any> => {
    return guardarService({
      url: url ?? urlApi.DATATABLE_CONTRACTUAL,
      contentType: "application/json",
      sendNotification: false,
      body,
      requestData,
    });
  };

  return {
    handleGetContractual,
  };
};
