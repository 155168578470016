import * as Yup from 'yup';
import { FormValidation } from '../../../shared/utilities';
import moment from 'moment';
import { formatStringtoDateString } from '../../../shared/helpers/Fecha';
import { IBasePolizas } from "./baseModel";

export interface IPolizas {
  id?: number | null;
  base: IBasePolizas | number;
  estado?: string | null;
  tipo: string | null;
  requerimiento: number | string;
  aseguradora: string;
  numero: string | number;
  numeroendoso: string | number;
  certificado: string;
  inicio?: any;
  fin?: any;
  anexos?: any;
  documentos?: any;
  deletefiles?: any[];
};

export const formSchema = Yup.object().shape({
  id: Yup.string(),
  base: Yup.string(),
  estado: Yup.string().nullable(),
  tipo: Yup.string(),
  requerimiento: Yup.string().required(FormValidation.required),
  aseguradora: Yup.string().required(FormValidation.required),
  numero: Yup.string().required(FormValidation.required),
  numeroendoso: Yup.string().required(FormValidation.required),
  certificado: Yup.string().required(FormValidation.required),
  inicio: Yup.date().required(FormValidation.required).nullable(),
  fin: Yup.date().required(FormValidation.required).nullable(),
});

export const initialState = (data?: IPolizas): IPolizas => ({
  id: data?.id || null,
  base: data?.base || 0,
  estado: data?.estado || null,
  tipo: data?.tipo || '',
  requerimiento: data?.requerimiento || '',
  aseguradora: data?.aseguradora || '',
  numero: data?.numero || '',
  numeroendoso: data?.numeroendoso || '',
  certificado: data?.certificado || '',
  inicio: data?.inicio ? formatStringtoDateString(data?.inicio) : null,
  fin: data?.fin ? formatStringtoDateString(data?.fin) : null,
  anexos: {
    documento_poliza: data?.documentos?.documento_poliza,
    documento_pago: data?.documentos?.documento_pago,
  },
  deletefiles: [],
});
