import { Card, FormGroup } from '@material-ui/core';
import { Field, Formik, useFormik, Form as FormFK } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { Button, CardBody, CardHeader, Col, Form, FormFeedback, Input, Label, Row, UncontrolledCollapse } from 'reactstrap';
import * as Yup from 'yup';
import { listPlazos, listValor } from '../../../core/services/listasDesplegables';
import { obtenerAmparos, saveAmparos, obtenerAmparosToken } from '../../../core/services/polizas/polizasService';
import OldDateField from '../../../shared/components/OldDateField';
import OldNumberField from '../../../shared/components/OldNumberField';
import { FormValidation } from '../../../shared/utilities';
import moment from 'moment';
import { useMainContext } from '../../../shared/contexts/mainContext';
import { usePolizaContext } from '../../../shared/contexts/polizaContext';
import { useTranslation } from 'react-i18next';
import { formatStringtoDateString } from '../../../shared/helpers/Fecha';

const formSchema = Yup.object().shape({
  amparo: Yup.number().min(1, FormValidation.required),
});

const formSchemaAmparo = Yup.object().shape({
  id: Yup.string(),
  caracteristica: Yup.string(),
  poliza: Yup.string(),
  valor: Yup.string().required(),
  tipovalor: Yup.number().min(1),
  vigencia: Yup.string().required(),
  tipovigencia: Yup.number().min(1),
  estado: Yup.string(),
  inicio: Yup.string().required(),
  fin: Yup.date().nullable(),
  valorcalculado: Yup.string(),
  valordocumento: Yup.string().nullable(),
  iniciodocumento: Yup.string().nullable(),
  findocumento: Yup.string().nullable(),
  observacionesdocumento: Yup.string().nullable(),
});

const initialStateAmparo = (data?: any) => {
  return {
    id: data?.id || 0,
    caracteristica: data?.caracteristica.id || '',
    poliza: data?.poliza.id || null,
    valor: data?.valor || '',
    tipovalor: data?.tipovalor || 0,
    vigencia: data?.vigencia || '',
    tipovigencia: data?.tipovigencia || 0,
    estado: data?.estado || 0,
    inicio: (data?.inicio) ? new Date(data?.inicio) : null,
    fin: (data?.fin) ? new Date(data?.fin) : null,
    valorcalculado: data?.valorcalculado || '',
    valordocumento: data?.valordocumento || null,
    iniciodocumento: (data?.iniciodocumento) ? new Date(data?.iniciodocumento) : null,
    findocumento: (data?.findocumento) ? new Date(data?.findocumento) : null,
    observacionesdocumento: data?.observacionesdocumento || null,
  };
};

const FormAmparos: FC<IFormAmparos> = (
  {
    id,
    handlePoliza,
    disabled = false,
    caracteristicas = [],
    rolAbogado = false,
    tokenTercero = false,
    token = null
  }
  ) => {
  const [amparosAdded, setAmparosAdded] = useState<any[]>([]);
  const { handleNotification } = useMainContext();
  const { disableFieldsAbogado, disableFieldsTercero} = usePolizaContext();
  const { t } = useTranslation();

  const { handleChange, values, errors, touched, handleSubmit } = useFormik({
    initialValues: {
      amparo: 0,
    },
    validationSchema: formSchema,
    onSubmit: async values => {
      const data = {
        poliza: id,
        caracteristica: values.amparo,
      };

      if (await saveAmparos(data, handleNotification)) {
        listarAmparos();
      }
    },
  });

  const editAmparo = async (values: any) => {
    const data = {
      ...values,
      poliza: id
    };
    if (await saveAmparos(data, handleNotification)) {
      listarAmparos();
      handlePoliza();
    }
  };

  const listarAmparos = async () => {
    const params = {
      filter: 'poliza',
      valuefilter: id
    };
    setAmparosAdded(await obtenerAmparos(params));
  };

  const listarAmparosToken = async () => {
    const params = {
      filter: 'poliza',
      valuefilter: id,
      token: token
    };
    setAmparosAdded(await obtenerAmparosToken(params));
  };

  useEffect(() => {
    if(tokenTercero) {
      disabled = false;
    }
    listarAmparos();
  }, []);

  return (
    <>
      {
        (!tokenTercero && disabled) && (
          <Form onSubmit={handleSubmit}>
            <FormGroup row className="mb-4">
              <Label for="examplePassword" sm={2}>{t('Amparo')}: </Label>
              <Col sm={4}>
                <Input
                  type="select"
                  name="amparo"
                  value={values.amparo}
                  onChange={handleChange}
                  invalid={(errors.amparo && touched.amparo) || false}
                >
                  <option value="0" disabled>{t('Seleccione')}</option>
                  {
                    caracteristicas.map(function ({ id, nombre }) {
                      return (
                        <option key={id} value={id}>{nombre}</option>
                      );
                    })
                  }
                </Input>
                <FormFeedback>{errors.amparo && t(errors.amparo)}</FormFeedback>
              </Col>
              <Col sm={2}>
                <Button
                  color="success"
                  type="submit"
                >
                  <i className="uil-plus-circle"></i>
                </Button>
              </Col>
            </FormGroup>
          </Form>
        )
      }
      <div>
        {
          amparosAdded.map((amparo, index) => (
            <Card key={amparo?.id} style={{
              overflow: 'visible'
            }}>
              <CardHeader style={{
                background: '#f2f5fa'
              }}>
                <Button
                  color={amparo?.estado === 1 ? 'success' : (amparo?.estado === 2) ? 'danger' : (amparo?.estado === 3) ? 'warning' : 'primary'}
                  type="submit"
                  form={`form-${amparo?.id}`}
                  size="sm"
                >
                  {amparo.estado == 1 ? (
                    <i className="uil-check-circle"></i>
                  ) : (
                    <>
                      {amparo?.estado === 2 ? (
                        <i className="uil-times-circle"></i>
                      ) : (
                        <i className="uil-check-circle"></i>
                      )
                      }
                    </>
                  )}
                </Button>
                <Button type="button" color="link" id={`toggler${amparo?.id}`}>
                  <h5 className="mb-0">{amparo?.caracteristica?.nombre}</h5>
                </Button>
              </CardHeader>
              <UncontrolledCollapse toggler={`#toggler${amparo?.id}`}>
                <CardBody>
                  <Formik
                    enableReinitialize
                    initialValues={initialStateAmparo(amparo)}
                    validationSchema={formSchemaAmparo}
                    onSubmit={(values) => {
                      const dataSend = {
                        ...values,
                        option: 1
                      };
                      editAmparo(dataSend);
                    }}
                  >
                    {({ values, errors, touched }) => {
                      return (
                        <FormFK id={`form-${amparo?.id}`}>
                          {!tokenTercero && (
                          <>
                          <Row>
                            <Col sm={4} className="mb-2">
                              <Label style={{ fontSize: '0.8rem' }}>{t('Valor')}: </Label>
                              <Field
                                component={OldNumberField}
                                name='valor'
                                placeholder={t('Ingrese el valor')}
                                className={(errors.valor) ? 'is-invalid form-control form-control-sm' : 'form-control form-control-sm'}
                                disabled={disableFieldsAbogado}
                                invalid={errors.valor}
                                currency
                              />
                            </Col>
                            <Col sm={4} className="mb-2">
                              <Label style={{ fontSize: '0.8rem' }}>{t('Tipo de valor')}: </Label>
                              <Field
                                as={Input}
                                type="select"
                                name="tipovalor"
                                bsSize="sm"
                                disabled={disableFieldsAbogado}
                                invalid={errors.tipovalor && touched.tipovalor}
                              >
                                <option value="0">{t('Seleccione')}</option>
                                {
                                  listValor.map(({ id, nombre }) => (
                                    <option key={id} value={id}>{nombre}</option>
                                  ))
                                }
                              </Field>
                            </Col>
                            <Col sm={4} className="mb-2">
                              <Label style={{ fontSize: '0.8rem' }}>{t('Valor calculado')}: </Label>
                              <Field
                                component={OldNumberField}
                                name='valorcalculado'
                                placeholder={t('Valor calculado automaticamente')}
                                className="form-control form-control-sm"
                                disabled
                                currency
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6} className="mb-2">
                              <Label style={{ fontSize: '0.8rem' }}>{t('Fecha de inicio')}: </Label>
                              <Field
                                name='inicio'
                                dateFormat="yyyy-MM-dd"
                                selected={values?.inicio ? new Date(values?.inicio) : null}
                                component={OldDateField}
                                disabled={disableFieldsAbogado}
                                className={(errors.inicio && touched.inicio) ? 'is-invalid form-control form-control-sm' : 'form-control form-control-sm'}
                              />
                            </Col>
                            <Col sm={6} className="mb-2">
                              <Label style={{ fontSize: '0.8rem' }}>{t('Fecha fin')}: </Label>
                              <Field
                                name='fin'
                                dateFormat="yyyy-MM-dd"
                                selected={values?.fin ? new Date(values?.fin) : null}
                                component={OldDateField}
                                className="form-control form-control-sm"
                                disabled
                                bsSize="sm"
                              />
                            </Col>
                            <Col sm={6} className="mb-2">
                              <Label style={{ fontSize: '0.8rem' }}>{t('Vigencia')}: </Label>
                              <Field
                                component={OldNumberField}
                                name='vigencia'
                                placeholder={t('Ingrese tiempo')}
                                className={(errors.vigencia) ? 'is-invalid form-control form-control-sm' : 'form-control form-control-sm'}
                                disabled={disableFieldsAbogado}
                                invalid={errors.vigencia}
                              />
                            </Col>
                            <Col sm={6} className="mb-4">
                              <Label style={{ fontSize: '0.8rem' }}>{t('Tipo de vigencia')}: </Label>
                              <Field
                                as={Input}
                                type="select"
                                name="tipovigencia"
                                bsSize="sm"
                                disabled={disableFieldsAbogado}
                                invalid={errors.tipovigencia && touched.tipovigencia}
                              >
                                <option value="0">{t('Seleccione')}</option>
                                {
                                  listPlazos.map(({ id, nombre }) => (
                                    <option key={id} value={id}>{nombre}</option>
                                  ))
                                }
                              </Field>
                            </Col>
                          </Row>
                          </>
                          )}
                          {
                            values.fin && (
                              <>
                                <Row>
                                  <Col className="d-flex">
                                    <Button
                                      color="primary"
                                      size="sm"
                                      onClick={() => {
                                        const dataSend = {
                                          ...values,
                                          option: 2
                                        };
                                        editAmparo(dataSend);
                                      }}
                                    >
                                      <i className="mdi mdi-content-save-outline"></i>
                                    </Button>
                                    <h6 className="d-flex align-items-center ml-2 mb-0">{t('Ingrese valores del documento')}</h6>
                                  </Col>
                                </Row>
                                <hr />
                                <Row>
                                  <Col sm={4} className="mb-2">
                                    <Field
                                      component={OldNumberField}
                                      name='valordocumento'
                                      placeholder={t('Ingrese el valor')}
                                      className="form-control form-control-sm"
                                      disabled={disableFieldsTercero}
                                      currency
                                    />
                                  </Col>
                                  <Col sm={4} className="mb-2">
                                    <Field
                                      name='iniciodocumento'
                                      dateFormat="yyyy-MM-dd"
                                      selected={values?.iniciodocumento ? new Date(values?.iniciodocumento) : null}
                                      component={OldDateField}
                                      placeholderText={t('Fecha inicio')}
                                      disabled={disableFieldsTercero}
                                      bsSize="sm"
                                      className={(errors.iniciodocumento && touched.iniciodocumento) ? 'is-invalid form-control form-control-sm' : 'form-control form-control-sm'}
                                    />
                                  </Col>
                                  <Col sm={4} className="mb-2">
                                    <Field
                                      name='findocumento'
                                      dateFormat="yyyy-MM-dd"
                                      selected={values?.findocumento ? new Date(values?.findocumento) : null}
                                      component={OldDateField}
                                      bsSize="sm"
                                      disabled={disableFieldsTercero}
                                      placeholderText={t('Fecha fin')}
                                      className={(errors.findocumento && touched.findocumento) ? 'is-invalid form-control form-control-sm' : 'form-control form-control-sm'}
                                    />
                                  </Col>
                                  <Col sm={12}>
                                    <Field
                                      as={Input}
                                      name="observacionesdocumento"
                                      type="textarea"
                                      disabled={disableFieldsTercero}
                                      bsSize="sm"
                                      placeholder={t('Observaciones')}
                                    />
                                  </Col>
                                </Row>
                              </>
                            )
                          }
                        </FormFK>
                      )
                    }}
                  </Formik>
                </CardBody>
              </UncontrolledCollapse>
            </Card>
          ))
        }
      </div>
    </>
  );
};
interface IFormAmparos {
  caracteristicas: any[];
  id: any;
  disabled?: any;
  handlePoliza: () => void;
  rolAbogado: boolean | null | undefined;
  tokenTercero: boolean | null | undefined;
  token: string | null | undefined;
};
export default FormAmparos;
