import React, { FC } from 'react';
import { Field, FormikProvider, useFormik } from 'formik';
import { Col, Form, FormFeedback, FormGroup, Input, Label, ModalBody, Row } from 'reactstrap';
import * as Yup from 'yup';
import { FormValidation } from '../../../../shared/utilities';
import { useTranslation } from 'react-i18next';
import { IDocumentosRequeridos, IDocumentosRequeridosForm } from '../../../../core/models/precontractual/documentosRequeridosModel';
import UniqueSelectField from '../../../../shared/components/UniqueSelectField';
import { listDocsRequeridos, listSiNo, listTipoDocsRequeridos } from '../../../../core/services/listasDesplegables';
import ModalsFooter from '../../../../shared/components/ModalsFooter';
import { usePrecontractualContext } from '../../../../shared/contexts/precontractualContext';
import UniqueFileField from '../../../../shared/components/UniqueFileField';

const FormDocsRequeridos: FC<IFormDocsRequeridos> = ({ onSubmit, record }) => {
  const { proceso } = usePrecontractualContext();
  const { t } = useTranslation();

  const habilitar = true;

  const initialValues: IDocumentosRequeridosForm = {
    id: null,
    tipodocumento: 0,
    otrodocumento: '',
    extension: 0,
    requerido: 2,
    activo: 1,
    ...record,
    idprecontractual: proceso?.id || 0,
    idanexo: record?.idanexo?.id || 0,
  } as IDocumentosRequeridosForm;

  const validationSchema = Yup.object().shape({
    id: Yup.number().nullable(),
    tipodocumento: Yup.number().nullable(),
    otrodocumento: Yup.string().when('tipodocumento', {
      is: (tipodocumento) => !tipodocumento,
      then: Yup.string().required(FormValidation.required),
      otherwise: Yup.string().nullable(),
    }),
    extension: Yup.number().min(1, FormValidation.required),
    requerido: Yup.number().min(1, FormValidation.required),
    idanexo: Yup.number().when('requerido', {
      is: (requerido) => requerido === 1,
      then: Yup.number().min(1, FormValidation.required),
      otherwise: Yup.number().nullable(),
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { values, errors, touched, handleSubmit } = formik;

  return (
    <Form id="FormDocsRequeridos" onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <ModalBody>
          <Row>
            {values.tipodocumento ? (
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label>{t('Nombre del documento')}</Label>
                  <Field
                    as={UniqueSelectField}
                    name="tipodocumento"
                    invalid={errors.tipodocumento && touched.tipodocumento}
                    options={listDocsRequeridos}
                    disabled
                  />
                  <FormFeedback>{errors.tipodocumento && t(errors.tipodocumento)}</FormFeedback>
                </FormGroup>
              </Col>
            ) : (
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label>{t('Nombre del Documento')}</Label>
                  <Field
                    as={Input}
                    name="otrodocumento"
                    invalid={errors.otrodocumento && touched.otrodocumento}
                    disabled={!habilitar}
                  />
                  <FormFeedback>{errors.otrodocumento && t(errors.otrodocumento)}</FormFeedback>
                </FormGroup>
              </Col>
            )}
            <Col sm={12} md={6}>
              <FormGroup>
                <Label>{t('Tipo de Documento')}</Label>
                <Field
                  as={UniqueSelectField}
                  name="extension"
                  invalid={errors.extension && touched.extension}
                  options={listTipoDocsRequeridos}
                  disabled={!habilitar}
                />
                <FormFeedback>{errors.extension && t(errors.extension)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={6}>
              <FormGroup>
                <Label>{t('Obligatorio')}</Label>
                <Field
                  as={UniqueSelectField}
                  name="requerido"
                  invalid={errors.requerido && touched.requerido}
                  options={listSiNo}
                  disabled
                />
                <FormFeedback>{errors.requerido && t(errors.requerido)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={6}>
              <FormGroup>
                <Label>{t('Anexo')}</Label>
                <Field
                  as={UniqueFileField}
                  name="idanexo"
                  invalid={errors.idanexo && touched.idanexo}
                  disabled={!habilitar}
                  category={listDocsRequeridos.find(item => item.value === values.tipodocumento)?.codigo ?? 'docs_adicionales'}
                  modulo="PrePrecontractual"
                  idmodulo={proceso?.id}
                  record={record?.idanexo}
                />
                <FormFeedback>{errors.idanexo && t(errors.idanexo)}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalsFooter
          idForm="FormDocsRequeridos"
        />
      </FormikProvider>
    </Form>
  )
}

interface IFormDocsRequeridos {
  record: IDocumentosRequeridos | null;
  onSubmit: (values: IDocumentosRequeridosForm) => Promise<void>;
}

export default FormDocsRequeridos;
