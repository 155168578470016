import React, { FC, useEffect, useMemo, useState } from 'react';
import { Alert, Button, PopoverBody, Spinner, Table, UncontrolledPopover } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { borrarDocumentosOferta, guardarDocumentosOferta, listarDocumentosOferta } from '../../../../core/services/precontractual/documentosOfertaService';
import { usePrecontractualContext } from '../../../../shared/contexts/precontractualContext';
import { IDocumentosOferta, IDocumentosOfertaForm } from '../../../../core/models/precontractual/documentosOfertaModel';
import { PRE_ACTIVIDADES, nombreDocProveedores } from '../../../../shared/helpers/precontractual';
import { useMainContext } from '../../../../shared/contexts/mainContext';
import { changeLoading, closeModal, falseLoading, openModal, trueLoading } from '../../../../shared/contexts/actions/mainActions';
import { EEstadosPre, IBorrarRegistro } from '../../../../shared/utilities';
import FormDocsOferta from '../Forms/FormDocsOferta';
import ModalMsj from '../../../../shared/components/ModalMsj';
import { handleVerDocumento } from '../../../../shared/helpers/generales';
import { useUserContext } from '../../../../shared/contexts/userContext';

const TablaDocsOferta: FC<ITablaDocsOferta> = ({ habilitar, tipo, idpreproveedor }) => {
  const { t } = useTranslation();
  const { mainDispatch, handleNotification } = useMainContext();
  const { estado, proceso, roles } = usePrecontractualContext();
  const { userState } = useUserContext();

  const [loading, setLoading] = useState(true);
  const [docsOferta, setDocsOferta] = useState<IDocumentosOferta[]>([]);
  const [borrar, setBorrar] = useState<IBorrarRegistro>({
    show: false,
    id: 0
  });

  const eliminar = useMemo(() => {
    if (estado === EEstadosPre.CARGUE_PROPUESTAS && roles.solicitante) {
      return true;
    } else {
      return false;
    }
  }, [estado, roles]);

  const listarDocumentos = async () => {
    const params = idpreproveedor ? {
      filter: 'idprecontractual,idpreproveedor',
      valuefilter: `${proceso?.id},${idpreproveedor}`,
    } : {
      filter: 'idprecontractual',
      valuefilter: proceso?.id,
    };
    const documentosOferta = await listarDocumentosOferta(params);
    setDocsOferta(documentosOferta);
    setLoading(false);
  }


  const onSubmit = async (values: IDocumentosOfertaForm) => {
    mainDispatch(trueLoading());
    const guardar = await guardarDocumentosOferta(values, handleNotification);
    if (guardar) {
      await listarDocumentos();
      mainDispatch(closeModal());
    }
    mainDispatch(falseLoading());
  }

  const changeModal = (id?: number) => {
    setBorrar({
      show: !borrar.show,
      id: (id) ? id : 0
    });
  };

  /**
   * Permite eliminar un registro
   * @param id 
   */
  const handleDelete = async (id: number) => {
    mainDispatch(changeLoading());
    if (await borrarDocumentosOferta(id, handleNotification)) {
      await listarDocumentos();
    }
    mainDispatch(changeLoading());
    changeModal();
  };

  const handleAdd = () => {
    mainDispatch(openModal({
      modal: {
        show: true,
        title: t('Agregar Documento'),
        body: () => (
          <FormDocsOferta
            onSubmit={onSubmit}
            record={null}
          />
        )
      }
    }))
  };
  
  const handleEdit = (id: number) => {
    const record = docsOferta.find(row => row.id === id);
    mainDispatch(openModal({
      modal: {
        show: true,
        title: t('Editar documento'),
        body: () => (record ? (
          <FormDocsOferta
            onSubmit={onSubmit}
            record={record}
          />
         ) : null
        )
      }
    }))
  };

  useEffect(() => {
    listarDocumentos();
  }, [])

  return (
    <>
      {loading ? (
        <div className="d-flex flex-column justify-content-center text-center">
          <Spinner color="primary" className="m-auto" />
          <span className="mt-3">
            {t('Cargando documentos...')}
          </span>
        </div>
      ) : (
        <>
          {!!idpreproveedor && !!docsOferta.find(item => !item.idanexo) && (
            <Alert color="danger">{t('Debe cargar todos los documentos requeridos.')}</Alert>
          )}
          {!userState.isAutenticated && (
            <>
              <Alert color="info">
                {t('Señor Oferente, todo lo que sea cargado a la herramienta quedará guardado inmediatamente')}
                {t(' y será visualizado por el equipo de Compras Negociador')}
              </Alert>
              <Alert color="info">
                {t('Restricción para subir archivos máximo a 25MB.')}
              </Alert>
            </>
          )}
          <Table>
            <thead>
              <tr>
                <th align="center">{t('Proveedor')}</th>
                <th align="center">{t('Tipo de Documento')}</th>
                <th align="center">{t('Descripción')}</th>
                <th align="center">{t('Obligatorio')}</th>
                <th align="center">{t('Adjunto')}</th>
                {habilitar && (
                  <th align="center">{t('Acción')}</th>
                )}
              </tr>
            </thead>
            <tbody>
              {docsOferta.map(row => (
                <tr key={row.id}>
                  <td align="center">
                    {row.idpreproveedor?.idproveedorcliente.razonsocial}
                  </td>
                  <td align="center">
                    {!!row.idtipodocumento && nombreDocProveedores(row.idtipodocumento)}
                    {!row.idanexo && (
                      <>
                        <UncontrolledPopover
                          placement="top"
                          target={`doc-oferta-${row.id}`}
                          trigger="hover"
                        >
                          <PopoverBody>
                            {t('No se ha cargado el documento')}
                          </PopoverBody>
                        </UncontrolledPopover>
                        <span className="ml-1">
                          <i className="uil-bell text-danger cursor-pointer" id={`doc-oferta-${row.id}`}></i>
                        </span>
                      </>
                    )}
                  </td>
                  <td align="center">
                    {row.idtipodocumento?.idcategoria?.descripcion}
                  </td>
                  <td align="center">
                    {row.idtipodocumento?.requerido === 1 ? t('Si') : t('No')}
                  </td>
                  <td align="center">
                    {!!row.idanexo && (
                      <Button
                        color="primary"
                        className="mr-1"
                        onClick={() => handleVerDocumento(row.idanexo)}
                        size="sm"
                        outline
                        title={t('Ver anexo')}
                      >
                        <i className={tipo ? "uil-mr uil-eye" : "uil-eye"}></i>
                        {!!tipo && t('Ver anexo')}
                      </Button>
                    )}
                  </td>
                  {habilitar && (
                    <td align="center">
                      {!!idpreproveedor && (
                        <Button
                          color="primary"
                          className="mr-1"
                          onClick={() => handleEdit(row.id || 0)}
                          size="sm"
                          outline
                        >
                          <i className="uil-plus"></i> {t('Cargar Documentos')}
                        </Button>
                      )}
                      {(!idpreproveedor && eliminar) && (
                        <Button
                          color="danger"
                          className="mr-1"
                          onClick={() => changeModal(row.id || 0)}
                          size="sm"
                          outline
                          title={t('Eliminar')}
                        >
                          <i className="uil-trash-alt"></i>
                        </Button>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
          {docsOferta.length === 0 && (
            <Alert color="warning">{t('No se han agregado documentos de oferta.')}</Alert>
          )}
        </>
      )}
      <ModalMsj
        {...borrar}
        action={handleDelete}
        closeModal={changeModal}
      />
    </>
  );
};

interface ITablaDocsOferta {
  tipo?: number;
  idpreproveedor?: number;
  habilitar?: boolean;
}

export default TablaDocsOferta;
