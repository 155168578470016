import React, {createContext, useMemo, useState} from "react";
import {IBasePolizas} from '../../core/models/polizas/baseModel';
import {guardarPolizas, mostrarBasePoliza} from '../../core/services/polizas/polizasService';
import {useMainContext} from './mainContext';
import {changeLoading, falseLoading, trueLoading} from './actions/mainActions';
import {IFormCodigosPolizas} from '../../core/models/polizas/codigosPolizasModel';
import {guardarCodigosPolizas} from '../../core/services/polizas/codigosPolizasService';

export interface IPolizaContext {
  activeStep: number;
  setActiveStep: (step: number) => void;
  codigoPoliza: string;
  setCodigoPoliza: (codigoPoliza: string) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  base: IBasePolizas | null;
  setBase: (base: IBasePolizas) => void;
  obtenerPoliza: (token: string) => void;
  handleCrearCodigoPoliza: (values: IFormCodigosPolizas) => Promise<boolean>;
  disableFields: boolean;
  setDisableFields: (boolean) => void;
  disableFieldsAbogado: boolean;
  setDisableFieldsAbogado: (boolean) => void;
  disableFieldsTercero: boolean;
  setDisableFieldsTercero: (boolean) => void;
};

export const polizaContext = createContext<IPolizaContext | undefined>(undefined);

export const PolizaProvider = ({children}) => {
  const {mainDispatch, handleNotification} = useMainContext();

  const [activeStep, setActiveStep] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [base, setBase] = useState<IBasePolizas | null>(null);
  const [codigoPoliza, setCodigoPoliza] = useState<string>('');
  const [disableFields, setDisableFields] = useState<boolean>(false);
  const [disableFieldsAbogado, setDisableFieldsAbogado] = useState<boolean>(false);
  const [disableFieldsTercero, setDisableFieldsTercero] = useState<boolean>(false);

  const obtenerPoliza = async (token: string) => {
    setLoading(true);
    setBase(await mostrarBasePoliza(token));
    setLoading(false);
  }

  const handleCrearCodigoPoliza = async (values: IFormCodigosPolizas): Promise<boolean> => {
    mainDispatch(trueLoading());
    const response = await guardarCodigosPolizas(values, handleNotification)
    /*if (response) {
      await obtenerPoliza(values.token);
    }*/
    mainDispatch(falseLoading());
    return response;
  }

  const contextValue = {
    activeStep,
    setActiveStep,
    codigoPoliza,
    setCodigoPoliza,
    loading,
    setLoading,
    base,
    obtenerPoliza,
    handleCrearCodigoPoliza,
    setBase,
    disableFields,
    setDisableFields,
    disableFieldsAbogado,
    setDisableFieldsAbogado,
    disableFieldsTercero,
    setDisableFieldsTercero
  };

  return (
    <polizaContext.Provider
      value={contextValue}
    >
      {children}
    </polizaContext.Provider>
  );
}

export const usePolizaContext = () => {
  const context = React.useContext(polizaContext)
  if (context === undefined) {
    throw new Error('usePolizaContext must be used within a PolizaProvider')
  }
  return context
};
