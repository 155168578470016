import { ICalendario } from '../../core/models/precontractual/calendarioModel';
import { IDocumentosProveedores } from '../../core/models/precontractual/documentosProveedoresModel';
import { IDocumentosRequeridos } from '../../core/models/precontractual/documentosRequeridosModel'
import { IEvaluacion } from '../../core/models/precontractual/evaluacionModel';
import { IOferta } from '../../core/models/precontractual/ofertaModel';
import { ICriterios } from '../../core/models/precontractual/criteriosModel';
import { IProveedores } from '../../core/models/precontractual/proveedoresModel';
import { listDocsRequeridos, listTipoDocsRequeridos } from '../../core/services/listasDesplegables'
import { IProducto } from '../../core/models/precontractual/productoModel';

export const nombreDocRequerido = (doc: IDocumentosRequeridos) => {
  const obj = listDocsRequeridos.find(item => item.value === doc.tipodocumento);
  return obj?.label || doc.otrodocumento;
}

export const extensionDocumentoRequerido = (doc: IDocumentosRequeridos) => {
  const obj = listTipoDocsRequeridos.find(item => item.value === doc.extension);
  return obj?.label || '';
}

export const nombreDocProveedores = (doc: IDocumentosProveedores) => {
  const nombre = doc.otrodocumento || doc.idcategoria?.nombre;
  return nombre;
}

export const fechaInicioCalendario = (cal: ICalendario) => {
  return cal.tipoinicio === 1 ? cal.inicio?.substring(0, 10) : cal.inicio;
}

export const fechaFinCalendario = (cal: ICalendario) => {
  return cal.tipofin === 1 ? cal.fin?.substring(0, 10) : cal.fin;
}

export const obtenerEvaluacion = (
  criterio: ICriterios,
  proveedor: IProveedores,
  evaluaciones: IEvaluacion[],
) => {
  const record = evaluaciones.find(eva => eva.idcriterio.id === criterio.id && eva.idpreproveedor.id === proveedor.id);
  return record;
}

export const sumaCriterios = (criterio: ICriterios[]) => {
  const total = criterio.reduce((sum, item) => sum + item.porcentaje, 0);
  return total;
}

export const sumaCalificacion = (proveedor: IProveedores, evaluaciones: IEvaluacion[]) => {
  const evaluacionesProveedor = evaluaciones.filter(item => item.idpreproveedor.id === proveedor.id);
  const total = evaluacionesProveedor.reduce(
    (suma, item) => suma + Number(item.calificacion), 0
  );
  return total;
}

export const totalOferta = (ofertas: IOferta[]) => {
  const total = ofertas.reduce(
    (suma, item) => suma + (Number(item.valorunitario) * Number(item.idproducto.cantidad)), 0
  );
  return total;
}

export const puntajeEvaluacion = (evaluaciones: IEvaluacion[]) => {
  const total = evaluaciones.reduce(
    (suma, item) => suma + (Number(item.calificacion) * (item.idcriterio.porcentaje/100)), 0
  );
  const redondeo = parseFloat(total.toFixed(2));
  return redondeo;
}

export const mejorOferta = (proveedores: IProveedores[]) => {
  const data = [...proveedores];
  const mejor = data.sort((a, b) => puntajeEvaluacion(b.idevaluaciones) - puntajeEvaluacion(a.idevaluaciones));
  return mejor[0];
}

export const propuestaEconomica = (proveedores: IProveedores[], productos: IProducto[]) => {
  const data = [...proveedores.filter(
    item => item.idofertas.length === productos.length
  )];
  const mejor = data.sort((a, b) =>  sumaValorOferta(a, a.idofertas) - sumaValorOferta(b, b.idofertas));
  return mejor[0];
}

export const valorOferta = (proveedor: IProveedores, producto: IProducto, ofertas: IOferta[]) => {
  const ofertaproveedor = ofertas.find(
    item => item.idproducto.id === producto.id && item.idpreproveedor.id === proveedor.id
  );
  if (ofertaproveedor?.valorunitario) {
    return Number(ofertaproveedor.valorunitario) * Number(producto.cantidad);
  } else {
    return '';
  }
}

export const sumaValorOferta = (proveedor: IProveedores, ofertas: IOferta[]) => {
  const ofertasProveedor = ofertas.filter(item => item.idpreproveedor.id === proveedor.id);
  const total = ofertasProveedor.reduce(
    (suma, item) => suma + (Number(item.valorunitario) * Number(item.idproducto.cantidad)), 0
  );
  return total;
}

export const PRE_ACTIVIDADES = {
  CARGUE_PROPUESTAS: 2,
}
