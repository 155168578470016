import { mainTypeMsj } from "../../../shared/contexts/interfaces/mainInterfaces";
import { EModulos } from "../../../shared/utilities";
import urlApi from "../../api";
import { IDocumento } from "../../models/procesos/documentosModel";
import { getRequest, postRequest, postRequestJson, updateItem } from "../peticionesService";

/**
 * Permite obtener los registros en la bd
 */
export const obtenerDocumentos = async (modulo, params: any = undefined): Promise<IDocumento[]> => {
  let data: IDocumento[] = [];
  try {
    const url = (modulo === EModulos.CONTRACTUAL.label) ? urlApi.LIST_CONTRA_DOCUMENTOS : urlApi.LIST_POST_DOCUMENTOS;
    const resp = await getRequest(url, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener los registros en la bd
 */
export const obtenerDocumentosTareas = async (params: any = undefined): Promise<IDocumento[]> => {
  let data: IDocumento[] = [];
  try {
    const url = urlApi.LIST_TAREA_DOCUMENTOS;
    const resp = await getRequest(url, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const crearDocumento = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  modulo = EModulos.CONTRACTUAL.nombre
): Promise<boolean> => {
  const url = (modulo === EModulos.CONTRACTUAL.nombre) ? urlApi.CREAR_CONTRA_DOCUMENTO : urlApi.CREAR_POST_DOCUMENTO;
  return await updateItem(url, values, notification, true);
};

/**
 * Permite obtener los registros en la bd
 */
export const descargarDocumento = async (id: any): Promise<any> => {
  let data = null;
  try {
    const url = `${urlApi.DOWN_CONTRA_DOCUMENTO}${id}`;
    const resp = await getRequest(url);
    
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const crearDocumentoTarea = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = urlApi.CREAR_TAREA_DOCUMENTOS;
  return await updateItem(url, values, notification, true);
};

/**
 * Permite obtener los registros en la bd
 */
export const editZohoService = async (modulo, iddocumento?: number): Promise<any> => {
  let data = null;
  try {
    const url = `${urlApi.ZOHO_EDIT}${modulo}/${iddocumento}`;
    const resp = await postRequest(url, {});
    console.log('Esta es la respuesta', resp);

    data = resp.data;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
 export const cargarDocumento = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
): Promise<boolean> => {
  const url = (values?.json?.id) ? `${urlApi.EDIT_CONTRA_DOCUMENTO}${values?.json?.id}` : urlApi.CREAR_CONTRA_DOCUMENTO;
  return await updateItem(url, values, notification, true);
};
