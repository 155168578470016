import React, {useEffect, useState} from "react";
import {useMainContext} from "../../../shared/contexts/mainContext";
import {EModulos, ERolsProceso} from "../../../shared/utilities";
import {mainTypeMsj} from "../../../shared/contexts/interfaces/mainInterfaces";
import {
  guardarBasePolizas,
  obtenerBasePolizas,
  obtenerPolizas,
  obtenerRequerimientos,
  obtenerCaracteristicas, obtenerPolizasToken
} from "../../../core/services/polizas/polizasService";
import {MUIDataTableColumn} from "mui-datatables";
import {Button} from "reactstrap";
import {falseLoading, openModal, trueLoading} from "../../../shared/contexts/actions/mainActions";
import FormPoliza from "./FormPoliza";
import {usePolizaContext} from '../../../shared/contexts/polizaContext';
import {IEstado} from "../../../core/models/administracion/estadosModel";
import {useTranslation} from "react-i18next";
import {IRequerimientos} from "../../../core/models/polizas/requerimientosModel";
import {ICaracteristicas} from "../../../core/models/polizas/caracteristicasModel";
import {IContractual} from "../../../core/models/contractual/contractualModel";
import {IPostcontractual} from "../../../core/models/postcontractual/postcontractualModel";


export const useBasePoliza = (
  obtenerProcesoC,
  obtenerProcesoP,
  contractualState,
  postcontractualState,
  modulo,
  isToken = false,
  token = null
) => {
  // Contextos
  const {mainDispatch, handleNotification} = useMainContext();
  const {t} = useTranslation();
  const {base, setBase} = usePolizaContext();
  // Estado locales
  const [lista, setLista] = useState<any[]>([]);
  const [sendEjecucion, setSendEjecucion] = useState<boolean>(false);
  const [botonContinuar, setBotonContinuar] = useState<boolean>(false);
  const [sendTercero, setSendTercero] = useState<boolean>(false);
  const [rolAbogado, setRolAbogado] = useState<boolean>(false);
  const [habilitarForm, setHabilitaForm] = useState<boolean>(false);
  const [listaRequerimientos, setListaRequerimientos] = useState<IRequerimientos[]>([]);
  const [listaCaracteristicas, setListaCaracteristicas] = useState<ICaracteristicas[]>([]);
  const [enableResendToken, setEnableResendToken] = useState<boolean|null>();

  const [proceso, setProceso] = useState<IContractual | IPostcontractual>();
  const [usuarios, setUsuarios] = useState()

  useEffect(() => {
    if(modulo === EModulos.CONTRACTUAL.label){
      setProceso(contractualState?.proceso);
      setUsuarios(contractualState?.contraUsuario);
    } else {
      setProceso(postcontractualState?.proceso);
      setUsuarios(postcontractualState?.postUsuario);
    }
  }, [contractualState, postcontractualState]);

  /**
   * Obtiene las documentos guardadas en la bd
   */
  const handleGuardarBase = async (values: any) => {
    mainDispatch(trueLoading());
    const data = {
      ...values,
      base: base?.id,
    };

    if (await guardarBasePolizas(data, handleNotification)) {
      if (modulo === EModulos.CONTRACTUAL.label) {
        obtenerProcesoC();
      } else {
        obtenerProcesoP()
      }
    }
    mainDispatch(falseLoading());
  };

  const onSubmit = (values) => {
    if (lista.length === 0) {
      handleNotification(t('Debe agregar al menos una póliza'), mainTypeMsj.ERROR);
      return;
    }
    const data = {
      ...values,
    };
    handleGuardarBase(data);
  };

  const columns: MUIDataTableColumn[] = [
    {
      name: t('Requerimiento'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Requerimiento')}</h5>
        ),
      }
    },
    {
      name: t('Acciones'),
      options: {
        filter: false,
        sort: false,
        customHeadLabelRender: () => (
          <h5>{t('Acciones')}</h5>
        ),
        customBodyRender: (value, meta) => {
          return (
            <>
              {
                !value ? (
                  <>
                    <Button
                      color="danger"
                      size="sm"
                      title={t('Borrar requerimiento')}
                      onClick={() => {
                        setLista(lista.filter((item, index) => index !== meta.rowIndex));
                      }}
                    >
                      <i className="uil-trash-alt"></i>
                    </Button>
                  </>
                ) : (
                  <Button
                    color="primary"
                    size="sm"
                    title={t('Editar requerimiento')}
                    onClick={() => mainDispatch(openModal({
                      modal: {
                        show: true,
                        size: 'lg',
                        title: t('Editar requerimiento'),
                        body: () => (
                          <FormPoliza
                            data={lista.find((item, index) => index === meta.rowIndex)}
                            amparos={listaCaracteristicas || []}
                            listarPolizas={listarPolizas}
                            listarPolizasToken={listarPolizasToken}
                            disabled={habilitarForm}
                            rolAbogado={rolAbogado}
                            tokenTercero={isToken}
                            token={token}
                            modulo={modulo}
                          />
                        )
                      }
                    }))}
                  >
                    {(rolAbogado && habilitarForm) ? (
                      <i className="uil-pen"></i>
                    ) : (
                      <i className="uil-eye"></i>
                    )}
                  </Button>
                )
              }
            </>
          );
        }
      }
    },
  ];

  /**
   * Obtiene las documentos guardadas en la bd
   */
  const listarBasePoliza = async () => {
    const params = {
      filter: 'referencia',
      valuefilter: proceso?.id?.toString(),
      modulo
    };

    const resp = await obtenerBasePolizas(params);
    if (resp.length > 0) {
      setBase(resp[0]);
      if(isToken) {
        listarPolizasToken(resp[0]?.id);
      } else {
        listarPolizas(resp[0]?.id);
      }
    }
  };

  /**
   * Obtiene las documentos guardadas en la bd
   */
  const listarPolizas = async (id) => {
    const params = {
      filter: 'base',
      valuefilter: id
    };
    const resp = await obtenerPolizas(params);

    if(modulo === EModulos.CONTRACTUAL.label) {
      setSendTercero(((proceso?.idestados as IEstado).codigo === "8" && ((proceso as IContractual).subestado === 1) && resp.length > 0));
      setSendEjecucion(((proceso?.idestados as IEstado).codigo === "8.2"));
      setEnableResendToken(isToken === false && ((proceso?.idestados as IEstado).codigo === "8.1"));
    } else {
      setSendTercero(((proceso?.idestados as IEstado).codigo === "6.1") && resp.length > 0);
      setSendEjecucion(false);
      setBotonContinuar(((proceso?.idestados as IEstado).codigo === "6.3"))
      setEnableResendToken(isToken === false && ((proceso?.idestados as IEstado).codigo === "6.2"));
    }
    setLista(resp);
  };

  const listarPolizasToken = async (id) => {
    const resp = await obtenerPolizasToken(token);
    setLista(resp);
    setSendEjecucion(false);
    setSendTercero(false);
  };

  const listarRequerimientos = async () => {
    const resp = await obtenerRequerimientos();

    setListaRequerimientos(resp);
  }

  const listarCaracteristicas = async () => {
    const resp = await obtenerCaracteristicas();

    setListaCaracteristicas(resp);
  }

  useEffect(() => {
    if (!isToken && proceso?.id) {
      listarBasePoliza();
      listarRequerimientos();
      listarCaracteristicas();
      setRolAbogado(!!usuarios?.find(user => user.rol === ERolsProceso.ABOGADO));
      if (modulo === EModulos.CONTRACTUAL.label) {
        setHabilitaForm(
          (
            (proceso?.idestados as IEstado)?.codigo === '8'
          )
        );
      } else {
        setHabilitaForm(
          (
            (proceso?.idestados as IEstado)?.codigo === '6.1'
          )
        );
      }
    } else if (isToken) {
      listarCaracteristicas();
      listarPolizasToken(token);
      setRolAbogado(false);
      setHabilitaForm(true)
    }
  }, [proceso, usuarios]);

  return {
    columns,
    rolAbogado,
    habilitarForm,
    base,
    lista,
    setLista,
    sendEjecucion,
    listarBasePoliza,
    onSubmit,
    listaRequerimientos,
    listaCaracteristicas,
    sendTercero,
    listarPolizasToken,
    botonContinuar,
    enableResendToken
  }
}
