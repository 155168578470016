import React, {FC, useEffect} from 'react';
import {Field, Form as FormFK, Formik, useFormik} from 'formik';
import MUIDataTable from 'mui-datatables';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {Button, Card, CardBody, CardFooter, Col, Form, FormFeedback, FormGroup, Input, Label, Row} from 'reactstrap';
import {useBasePoliza} from './components/useBasePoliza';
import * as Yup from 'yup';
import {EModulos, FormValidation} from '../../shared/utilities';
import {obtenerPoliToken, resendToken, savePoliza} from '../../core/services/polizas/polizasService';
import {useMainContext} from '../../shared/contexts/mainContext';
import OldDateField from '../../shared/components/OldDateField';
import {formatStringtoDateString} from '../../shared/helpers/Fecha';
import ButtonCustom from '../../shared/components/ButtonCustom';
import {useOptionsTables} from '../../shared/hooks/useOptionsTables';
import {mainTypeMsj} from "../../shared/contexts/interfaces/mainInterfaces";
import {guardarContractual} from '../../core/services/contractual/contractualService';
import {editarProcesoPoscontractual} from '../../core/services/postcontractual/postcontractualService';
import {usePolizaContext} from "../../shared/contexts/polizaContext";
import {IEstado} from "../../core/models/administracion/estadosModel";
import {usePostcontractualContext} from "../../shared/contexts/postcontractualContext";
import {useContractualContext} from "../../shared/contexts/contractualContext";

const formSchema = Yup.object().shape({
  requerimiento: Yup.number().min(1, FormValidation.required),
  base: Yup.number().min(1,FormValidation.required),
});

const BasePoliza: FC<any> = ({modulo, isToken = false, token = null}) => {

  const { contractualState, handleChangeState, obtenerProceso } = useContractualContext();
  const { postcontractualState, cambiarEstado, obtenerProcesoPostcontractual } = usePostcontractualContext();

  const { mainState, handleNotification } = useMainContext();
  const
    {
      activeStep,
      setActiveStep,
      base,
      setBase,
      disableFieldsAbogado,
      setDisableFieldsAbogado,
      disableFieldsTercero,
      setDisableFieldsTercero,
    } = usePolizaContext();
  const history = useHistory();
  const { optionsDataTable } = useOptionsTables();
  const { t } = useTranslation();

  const {
    columns,
    rolAbogado,
    habilitarForm,
    sendTercero,
    sendEjecucion,
    lista,
    listarBasePoliza,
    setLista,
    onSubmit,
    listaRequerimientos,
    listarPolizasToken,
    botonContinuar,
    enableResendToken
  } = useBasePoliza(obtenerProceso, obtenerProcesoPostcontractual, contractualState, postcontractualState, modulo, isToken, token);

  const handleTokenChangeState = async (only = false, history?: any) => {
    if (only) {
      //mainDispatch(trueLoading());
    }

    const resp = modulo === EModulos.CONTRACTUAL.label ?
      await guardarContractual({
      guardar: 2,
      id: base?.referencia,
      tokenpolizas: true
    }, handleNotification, false, true, true)
    :
      await editarProcesoPoscontractual({
        guardar: 2,
        id: base?.referencia,
        tokenpolizas: true
      }, handleNotification, false, true, true);

    obtenerInfoPolizas()

    if (only) {
      //obtenerProceso();
      //mainDispatch(falseLoading());
    }
    return true;
  };

  useEffect(() => {
    if(isToken){
      setDisableFieldsAbogado(true);
      setDisableFieldsTercero(false);
    } else if(
      (
        ((contractualState?.proceso?.idestados as IEstado)?.codigo == "8" && contractualState?.proceso?.subestado == 1 && rolAbogado) ||
        (
          ((postcontractualState?.proceso?.idestados as IEstado)?.codigo == "6.1" && postcontractualState?.proceso?.etapa == 1 && rolAbogado) ||
          ((postcontractualState?.proceso?.idestados as IEstado)?.codigo == "6.1" && rolAbogado)
        )
      )
    ) {
      setDisableFieldsTercero(true);
      setDisableFieldsAbogado(false);
    } else {
      setDisableFieldsTercero(true);
      setDisableFieldsAbogado(true);
    }
  }, [contractualState?.proceso, postcontractualState?.proceso, rolAbogado]);

  const obtenerInfoPolizas = async () => {
    const poliToken = await obtenerPoliToken(token);
    if(poliToken){
      let base = poliToken.base;

      setBase(base);
    }
  }

  const resendTokenButton = async () => {
    const nuevoToken = await resendToken(base?.id);
    console.log("Respuesta: ", nuevoToken);
    handleNotification(nuevoToken?.message,nuevoToken.status);
  }

  const handleResendToken = ()  => {
    if(enableResendToken){
      resendTokenButton();
    } else {
      handleNotification("El proceso no está en el estado correcto para reenviar el token ahora", mainTypeMsj.ERROR);
    }
  };

  const { handleChange, values, errors, touched, handleSubmit } = useFormik({
    initialValues: {
      requerimiento: 0,
    },
    validationSchema: formSchema,
    onSubmit: async (values, { setFieldValue, resetForm }) => {
      const data = {
        id: null,
        idestado: null,
        aseguradora: null,
        requerimiento: values.requerimiento,
        base: base?.id,
        tipo: '1',
        creado: null,
        numero: null,
        numeroendoso: null,
        certificado: null,
        fechainicio: null,
        fechafin: null,
      };

      if (await savePoliza(data, handleNotification)) {
        resetForm();
        setFieldValue('poliza', 0);
        if(!isToken) {
          listarBasePoliza();
        } else {
          listarPolizasToken(token);
        }
      }
    },
  });

  return (
    <Card>
      <CardBody>
        {
          (rolAbogado && habilitarForm) && (
            <Form onSubmit={handleSubmit}>
              <FormGroup row className="mb-4">
                <Label for="examplePassword" sm={2}>{t('Requerimiento')}: </Label>
                <Col sm={4}>
                  <Input
                    type="select"
                    name="requerimiento"
                    value={values.requerimiento}
                    onChange={handleChange}
                    invalid={(errors.requerimiento && touched.requerimiento) || false}
                  >
                    <option value="0" disabled>{t('Seleccione')}</option>
                    {
                      listaRequerimientos && listaRequerimientos?.map(function ({ id, nombre }) {
                        return (
                          <option key={id} value={id}>{nombre}</option>
                        );
                      })
                    }
                  </Input>
                  <FormFeedback>{errors.requerimiento && t(errors.requerimiento)}</FormFeedback>
                </Col>
                <Col sm={2}>
                  <Button
                    color="success"
                    type="submit"
                  >
                    <i className="uil-plus-circle"></i>
                  </Button>
                </Col>
              </FormGroup>
            </Form>
          )
        }
        {
          (rolAbogado && enableResendToken) && (
            <Row>
              <Col sm={4}>
                <Button
                  color={"primary"}
                  type={"button"}
                  onClick={handleResendToken}
                >
                  <i className="uil-refresh"></i> Reenviar Token
                </Button>
              </Col>
            </Row>
          )
        }
        <div className="mt-2 mb-4">
          <MUIDataTable
            title=""
            data={lista?.map(item => ([
              item?.requerimiento?.nombre,
              item?.id
            ]))}
            columns={columns}
            options={optionsDataTable({ elevation: 2 })}
          />
        </div>
        <Formik
          enableReinitialize
          initialValues={{
            id: base?.id,
            fechalimite: (base?.fechalimite) ? formatStringtoDateString(base?.fechalimite) : null
          }}
          validationSchema={Yup.object().shape({
            fechalimite: Yup.date().required(FormValidation.required).nullable(),
          })}
          onSubmit={onSubmit}
        >
          {({ values, errors, touched }) => {
            return (
              <>
                <FormFK id="formPolizaGarantia">
                  <Row>
                    <Col sm={12} md={6}>
                      <FormGroup>
                        <Label>{t('Fecha límite envio de tercero')}</Label>
                        <Field
                          name='fechalimite'
                          dateFormat="yyyy-MM-dd"
                          selected={values?.fechalimite ? new Date(values?.fechalimite) : null}
                          component={OldDateField}
                          className={(errors.fechalimite && touched.fechalimite) ? 'is-invalid form-control' : 'form-control'}
                          disabled={disableFieldsAbogado}
                        />
                        <FormFeedback style={{
                          display: (errors.fechalimite && touched.fechalimite) ? 'block' : 'none'
                        }}>{errors.fechalimite && t(errors.fechalimite)}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </FormFK>
              </>
            )
          }}
        </Formik>
      </CardBody>
      {
        (rolAbogado) && (
          <CardFooter>
            <div className="d-flex justify-content-end">
              {!disableFieldsAbogado && (
                <>
              <ButtonCustom
                form="formPolizaGarantia"
                className={(sendEjecucion || sendTercero) ? 'd-flex align-items-center mr-2' : 'd-flex align-items-center'}
                type="submit"
                color="primary"
                loading={mainState.loading}
                disabled={mainState.loading}
              >
                {t('Guardar')}
              </ButtonCustom>
                </>
              )}
              {
                sendEjecucion && (
                  <ButtonCustom
                    form="formPolizaGarantia"
                    className={(sendEjecucion || sendTercero) ? 'd-flex align-items-center mr-2' : 'd-flex align-items-center'}
                    color="primary"
                    loading={mainState.loading}
                    disabled={mainState.loading}
                    onClick={() => modulo === EModulos.CONTRACTUAL.label ? handleChangeState(true, history) : cambiarEstado(values, true)}
                  >
                    {t('Enviar a Ejecución')}
                  </ButtonCustom>
                )
              }
              {
                botonContinuar && (
                  <ButtonCustom
                    form="formPolizaGarantia"
                    className="d-flex align-items-center"
                    color="primary"
                    loading={mainState.loading}
                    disabled={mainState.loading}
                    onClick={() => modulo === EModulos.CONTRACTUAL.label ? handleChangeState(true, history) : cambiarEstado(values, true)}
                  >
                    {t('Continuar')}
                  </ButtonCustom>
                )
              }
              {
                sendTercero && (
                  <ButtonCustom
                    form="formPolizaGarantia"
                    className="d-flex align-items-center"
                    color="primary"
                    loading={mainState.loading}
                    disabled={mainState.loading}
                    onClick={() => modulo === EModulos.CONTRACTUAL.label ? handleChangeState(true, history) : cambiarEstado(values, true)}
                  >
                    {t('Enviar a Tercero')}
                  </ButtonCustom>
                )
              }
            </div>
          </CardFooter>
        )
      }
      {
        isToken && (
          <CardFooter>
            <div className="d-flex justify-content-end">
              <ButtonCustom
                form="formPolizaGarantia"
                className="d-flex align-items-center"
                color="primary"
                loading={mainState.loading}
                disabled={mainState.loading}
                onClick={() => handleTokenChangeState(true, history)}
              >
                {t('Finalizar')}
              </ButtonCustom>
            </div>
          </CardFooter>
        )
      }
    </Card>
  );
};

export default BasePoliza;
