import {FormValidation} from '../../../shared/utilities';
import * as Yup from 'yup';

// Model form

export interface IFormManual {
  id?: number | null;
  nombre: string;
  archivo: any;
  activo: number | boolean;
  nombrereal: any;
  file: File | null;
}

// Model base

export interface IManual extends IFormManual {
  creado: any;
  actualizado: any;
}

// Esquema de formulario
export const formSchema = Yup.object().shape({
  id: Yup.number().nullable(),
  nombre: Yup.string().required(FormValidation.required),
  activo: Yup.bool(),
  file: Yup.mixed().when("id", {
    is: (id) => {
      if (id) {
        return false;
      }
      return true;
    },
    then: Yup.mixed().required(FormValidation.required),
  }),
});

// Estado inicial
export const initialState = (data?: IManual): IFormManual => ({
  id: data?.id || null,
  nombre: data?.nombre || '',
  archivo: data?.archivo || null,
  activo: (data?.activo && data.activo === 1) ? true : false,
  nombrereal: data?.nombrereal || null,
  file: null
});

