import { getRequest, updateItem, deleteItem } from '../peticionesService';
import urlApi from '../../api';
import { IManual } from '../../models/administracion/manualesModel';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';

/**
 * Permite obtener los registros en la bd
 */
export const obtenerManuales = async (): Promise<IManual[]> => {
    let data: IManual[] = [];
    try {
      const resp = await getRequest(urlApi.LIST_MANUALES);
      data = resp.data.records;
    } catch (error) {
      console.error(error);
    }
    return data;
  };

  
  /**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const guardarManual = async (
    values: any,
    notification: (
      msj: string,
      type: mainTypeMsj
    ) => void
  ): Promise<boolean> => {
    const url = (!values.id) ? urlApi.CREAR_MANUAL : `${urlApi.EDIT_MANUAL}${values.id}`
    return await updateItem(url, values, notification, true);
  };
  
  /**
   * Permite borrar un registro
   * @param id 
   * @param notification funcion que permite generar una notificacion en el sistema 
   */
  export const borrarManual = async (
    id: number,
    notification: (
      msj: string,
      type: mainTypeMsj
    ) => void
  ): Promise<boolean> => {
    const url = `${urlApi.BORRAR_MANUAL}${id}`;
    return await deleteItem(url, notification);
    
  };
