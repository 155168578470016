import React, { createContext, useReducer, useEffect, useContext, useState } from 'react';
import { IPostcontractualContext } from './interfaces/postcontractualInterface';
import postcontractualReducer, { postcontractualInitialState } from './reducers/postcontractualReducer';
import { useGetGeneric } from '../hooks/useGet';
import { updateAprobaciones, updateDocumentos, updatePostUsuario, updateProceso, updateRequerimientos, updateRoles, updateState, updateTareas, updateUsuarios } from './actions/postcontractualActions';
import moment from 'moment';
import { ILiquidacion } from '../../core/models/postcontractual/liquidacionModel';
import { ISuspension } from '../../core/models/postcontractual/suspensionModel';
import { IUsuarios } from '../../core/models/usuarios/usuariosModel';
import { mainContext } from './mainContext';
import { mainTypeMsj } from './interfaces/mainInterfaces';
import { userContext } from './userContext';
import urlApi from '../../core/api';
import { aprobarProceso, editarProcesoPoscontractual, obtenerListaUsuariosAprob, obtenerPostcontractualUsuarios } from '../../core/services/postcontractual/postcontractualService';
import { obtenerPolizaGarantia, obtenerRequerimientos } from '../../core/services/procesos/polizaGarantiasService';
import { crearDocumento, obtenerDocumentos } from '../../core/services/procesos/documentoService';
import { EModulos } from '../utilities';
import { obtenerTareas } from '../../core/services/procesos/tareasService';
import { changeLoading, falseLoading, trueLoading } from './actions/mainActions';
import { formatStringtoDateString } from '../helpers/Fecha';

export const postcontractualContext = createContext<IPostcontractualContext | undefined>(undefined);

export const PostcontractualProvider = ({ children }) => {
  // Contexts
  const { handleNotification, mainDispatch } = useContext(mainContext);
  const { userState } = useContext(userContext);

  // States
  const [state, dispatch] = useReducer(postcontractualReducer, postcontractualInitialState);
  const [requestProceso, errProceso, obtenerProceso] = useGetGeneric({
    url: (state?.id) ? `${urlApi.SHOW_POSTCONTRACTUAL}${state?.id}` : null
  });

  const obtenerProcesoPostcontractual = () => {
    obtenerProceso();
  }

  const obtenerDataProceso = () => {
    obtenerUsuarioPostcontractual();
    listarUsuarios();
    listarAprobaciones();
    listarDocumentos();
    listarTareas();
  };

  const listarAprobaciones = async () => {
    const usuarios = await obtenerListaUsuariosAprob((state.id as string));
    dispatch(updateRoles(usuarios));
    dispatch(updateAprobaciones(usuarios.reduce((prev: any[], current) => {
      let exist: any = null;

      if (prev?.length > 0) {
        exist = prev?.find(usuario => usuario?.id === current?.usuario?.idusuarios?.id && usuario?.tipo === 1);
        if (!exist) {
          exist = prev?.find(usuario => usuario?.id === current?.proveedor?.id && usuario?.tipo === 2);
        }
      }

      if (exist) {
        return prev.map(usuario => {
          if (usuario?.id === current?.usuario?.idusuarios?.id) {
            return {
              ...usuario,
              roles: (usuario?.roles?.find(rol => rol === current?.usuario?.rol)) ? exist?.roles : [
                ...usuario.roles,
                {
                  id: current?.usuario?.id,
                  rol: current?.usuario?.rol,
                  activo: current?.usuario?.activo
                }
              ],
            };
          } else {
            return usuario;
          }
        })
      } else {
        return [
          ...prev,
          (current?.usuario) ? {
            id: current?.usuario?.idusuarios?.id,
            tipo: 1,
            roles: [{
              id: current?.usuario?.id,
              rol: current?.usuario?.rol,
              activo: current?.usuario?.activo
            }],
            nombre: `${current?.usuario?.idusuarios?.nombres} ${current?.usuario?.idusuarios?.apellidos}`,
            creado: current?.usuario?.creado,
            aprobaciones: current?.aprobaciones
          } : {
            id: current?.proveedor?.id,
            tipo: 2,
            roles: [{
              id: current?.proveedor?.id,
              rol: 'Tercero',
              activo: current?.proveedor?.activo
            }],
            nombre: `${current?.proveedor?.razonsocial}`,
            creado: current?.proveedor?.creado,
            aprobaciones: current?.aprobaciones
          }
        ];
      }
    }, [])));
  }

  /**
   * Obtiene las documentos guardadas en la bd
   */
  const listarDocumentos = async () => {
    const params = {
      filter: 'idpostcontractual',
      valuefilter: state.id
    };
    dispatch(updateDocumentos(await obtenerDocumentos(EModulos.POSTCONTRACTUAL.nombre, params)));
  };

  /**
   * Obtiene las documentos guardadas en la bd
   */
  const listarTareas = async () => {
    const params = {
      filter: 'idpostcontractual',
      valuefilter: state.id
    };
    dispatch(updateTareas(await obtenerTareas(params)));
  };

  /**
   * Obtiene los datos del usuario logueado (roles)
   */
  const obtenerUsuarioPostcontractual = async () => {
    dispatch(updatePostUsuario(await obtenerPostcontractualUsuarios('idpostcontractual,idusuarios', `${state?.id},${userState?.data?.sub}`)));
  };

  /**
     * Obtiene las usuarios contractuales registrados en la bd
     */
  const listarUsuarios = async () => {
    dispatch(updateUsuarios(await obtenerPostcontractualUsuarios('idpostcontractual', (state?.id as string))));
  };

  /**
   * Permite aprobar en el proceso
   * @param values 
   * @param tipo 
   */
  const handleAprueba = async (values: any, tipo: number) => {
    mainDispatch(changeLoading());
    const dataSend = {
      ...values,
      idpostcontractual: state?.proceso?.id,
      activo: 1,
    }
    if (await aprobarProceso(dataSend, handleNotification)) {
      const dataSend = {
        id: state?.proceso?.id,
        usuarioid: userState.data?.sub,
        tipo,
        crear: false,
      };
      if (await editarProcesoPoscontractual(dataSend, handleNotification)) {
        obtenerProceso();
      }
    }
    mainDispatch(changeLoading());
  };

  const cargarDocumento = async (e: any, categoria: string | number, verify = true) => {
    mainDispatch(trueLoading());

    const exist = (verify) ? state?.documentos?.find(file => file.categoria === e.target.name) : undefined;
    const json = {
      id: exist ? exist?.id : undefined,
      idpostcontractual: state?.proceso?.id,
      idcargadopor: userState.data?.sub,
    };
    const dataSend = {
      ...json,
      anexos: {
        [categoria]: [e.target.files[0]]
      }
    };

    if (await crearDocumento(dataSend, handleNotification, EModulos.POSTCONTRACTUAL?.nombre)) {
      listarDocumentos();
    }
    mainDispatch(changeLoading());
  };

  const guardarProcesoPostcontractual = async (values: any, file = false, changeState = true) => {
    mainDispatch(trueLoading());
    const dataSend = {
      ...values,
      crear: true,
      guardar: 1,
    };
    await handleGuardarProceso(dataSend, file, changeState);
    mainDispatch(falseLoading());
  };

  const handleGuardarProceso = async (data: any, file: boolean, changeState = false) => {
    if (await editarProcesoPoscontractual(data, handleNotification, file, !changeState)) {
      if (changeState) {
        await cambiarEstado({}, false);
      }
      obtenerProceso();
    }
  };

  const cambiarEstado = async (values: any, only = false) => {
    if (only) {
      mainDispatch(trueLoading());
    }
    const resp = await editarProcesoPoscontractual({
      ...values,
      crear: false,
      guardar: 2,
      id: state?.id
    }, handleNotification, false, true, false);

    if (only) {
      await obtenerProceso();
      mainDispatch(falseLoading());
    }
    return resp;
  };

  const aprobarProcesoPost = async (valuesApro: any, valuesProceso: any, changeState = true) => {
    mainDispatch(trueLoading());
    const dataSend = {
      ...valuesApro,
      idpostcontractual: state?.proceso?.id,
      activo: 1,
      id: null
    }
    if (await aprobarProceso(dataSend, handleNotification) && changeState) {
      await cambiarEstado(valuesProceso);
      await obtenerProceso();
    }
    mainDispatch(falseLoading());
  };

  useEffect(() => {
    if (errProceso) {
      handleNotification(errProceso?.data?.message, mainTypeMsj.ERROR);
    } else if (requestProceso) {
      dispatch(updateProceso({
        ...requestProceso?.data?.record,
        liquidaciones: requestProceso?.data?.liquidacion?.map((liquidacion: ILiquidacion) => ({
          ...liquidacion,
          terminacionanticipada: (liquidacion.terminacionanticipada && liquidacion.terminacionanticipada === 1),
          fecha: formatStringtoDateString(liquidacion?.fecha),
        })),
        adendas: requestProceso.data?.adendas,
        suspensiones: requestProceso.data?.suspension?.map((suspension: ISuspension): ISuspension => ({
          ...suspension,
          idsolicitante: (suspension?.idsolicitante) ? `${(suspension.idsolicitante as IUsuarios)?.nombres} ${(suspension.idsolicitante as IUsuarios)?.apellidos}` : '',
          inicio: formatStringtoDateString(suspension?.inicio).toString(),
          final: formatStringtoDateString(suspension?.final).toString(),
          solicitud: formatStringtoDateString(suspension?.solicitud).toString(),
        })),
        renovaciones: requestProceso.data?.renovacion,
        reinicios: requestProceso?.data?.reinicio || [],
      }));
      obtenerDataProceso();
    }
  }, [errProceso, requestProceso]);

  useEffect(() => {
    if (!userState?.isAutenticated) {
      dispatch(updateState(undefined));
    }
  }, [userState])

  return (
    <postcontractualContext.Provider
      value={{
        postcontractualState: state,
        postcontractualDispatch: dispatch,
        cargarDocumento,
        cambiarEstado,
        aprobarProcesoPost,
        obtenerProceso,
        listarDocumentos,
        listarTareas,
        listarUsuarios,
        obtenerUsuarioPostcontractual,
        listarAprobaciones,
        handleAprueba,
        guardarProcesoPostcontractual,
        obtenerProcesoPostcontractual
      }}
    >
      {children}
    </postcontractualContext.Provider>
  );
};

export const usePostcontractualContext = () => {
  const context = React.useContext(postcontractualContext)
  if (context === undefined) {
    throw new Error('usePostcontractualContext must be used within a PostcontractualProvider')
  }
  return context
};
