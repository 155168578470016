import React, {useEffect, useState} from 'react';
import {MUIDataTableColumn} from 'mui-datatables';
import urlApi from '../../../../core/api';
import {useTranslation} from 'react-i18next';
import {useObtenerProcesosDinamicosJuridicos} from "../../../../core/services/juridico/juridicoService";
import {Link} from "react-router-dom";
import {EModulos} from "../../../../shared/utilities";

const initialState = {
  module: EModulos.JURIDICO.nombre,
  loading: false,
  length: 5,
  columns: [],
  order: [],
  start: 0,
  count: 0,
  page: 0,
  searchText: '',
}

let timeSearch: any;

const url = urlApi.PENDIENTES_USUARIO;

export const useListPendientesJuridico = () => {
  const {t} = useTranslation();
  const [datatable, setDatatable] = useState<any>(initialState);
  const [juridicos, setJuridicos] = useState<any[]>([]);
  const {handleGetJuridicos} = useObtenerProcesosDinamicosJuridicos(url);

  const columns: MUIDataTableColumn[] = [
    {
      name: t('Consecutivo'),
      options: {
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Consecutivo')}</h5>
        ),
        customBodyRender: ([consecutivo, id]) => {
          return (
            <Link to={`/juridico/edit/${id}`}>{consecutivo}</Link>
          );
        }
      }
    },
    {
      name: t('Número de contrato'),
      options: {
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Número de contrato')}</h5>
        ),
      }
    },
    {
      name: t('Estado'),
      options: {
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Estado')}</h5>
        ),
      }
    }
  ];

  /**
   * Obtiene los procesos guardados en la bd
   */
  const listarJuridicos = async () => {
    setDatatable({
      ...initialState,
      loading: true,
    });
    const {data = {}} = await handleGetJuridicos({
      ...initialState,
      order: undefined,
      count: undefined,
      loading: undefined,
      page: undefined,
    });
    const {data: records = [], recordsTotal = 0, page} = data;
    setDatatable({
      ...initialState,
      page: page - 1,
      count: recordsTotal,
      loading: false,
    });
    setJuridicos(records);
  };

  const changePage = async (pag) => {

    setDatatable({
      ...datatable,
      loading: true
    });
    const {data = {}} = await handleGetJuridicos({
      ...datatable,
      order:
        datatable?.order.length > 0 ? datatable?.order.length : undefined,
      count: undefined,
      loading: undefined,
      page: undefined,
      start: datatable?.length * pag,
    });
    const {data: records = [], recordsTotal = 0, page = 0} = data;
    setDatatable({
      ...datatable,
      count: recordsTotal,
      page: page - 1,
      loading: false,
    });
    setJuridicos(records);

  };

  const handleSearch = async (inputValue: string) => {

    if (inputValue?.trim().length < 3 || inputValue?.trim().length === undefined) {
      if (inputValue?.trim().length === 0) {
        listarJuridicos();
      }
      return inputValue;
    } else {
      (timeSearch) && clearTimeout(timeSearch);
      timeSearch = setTimeout(async () => {
        setDatatable({
          ...initialState,
          searchText: inputValue,
          loading: true
        })
        const { data = {} } = await handleGetJuridicos({
          ...initialState,
          searchText: inputValue,
          order: undefined,
          count: undefined,
          loading: undefined,
          page: undefined,
        });
        const { data: records = [], recordsTotal = 0, page = 0 } = data;
        setDatatable({
          ...datatable,
          searchText: inputValue,
          page: page - 1,
          count: recordsTotal,
          loading: false
        })
        setJuridicos(records);
      }, 1000);
    }
  };


  useEffect(() => {
    listarJuridicos();
  }, []);

  return {
    juridicos,
    columns,
    datatable,
    changePage,
    handleSearch
  }
};
