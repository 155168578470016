import React, {useContext, useEffect, useMemo, useState} from "react";
import {FC} from "react";
import {Card, CardBody, CardHeader, Col, Label, Row} from "reactstrap";
import {closeModal, openModal} from "../../../shared/contexts/actions/mainActions";
import {useTranslation} from "react-i18next";
import {obtenerDashboardService} from "../../../core/services/usuarios/usuariosService";
import {mainContext} from "../../../shared/contexts";
import {useHistory} from "react-router-dom";
import {ResponsiveBar} from "@nivo/bar";
import {ResponsivePie} from "@nivo/pie";
import ContratosVencer from "../contratosVencer";
import ContratosVencidos from "../contratosVencidos";
import {obtenerTiposContrato} from "../../../core/services/administracion/TiposContratoService";
import {ITiposContrato} from "../../../core/models/administracion/tiposContratoModel";
import Select, {StylesConfig} from "react-select";
import GraficaSkeleton from "../../../shared/components/GraficaSkeleton";
import {IEstado, IModulos} from "../../../core/models/administracion/estadosModel";
import {EModulos} from "../../../shared/utilities";
import {obtenerEstados} from "../../../core/services/administracion/estadosService";
import {abreviarPrecio} from "../../../shared/helpers/Money";
import BoxLegends from "./graficas/BoxLegends";
import ContratosPorArea from "./graficas/contratosPorArea";
import ContratosPorProcesos from "./graficas/contratosPorProcesos";
import PieLegends from "./graficas/PieLegends";

const colorBarScheme = [
  "#878ff7",
  "#f8738e",
  "#4bd4a5",
  "#fdc442",
  "#8bd7cc",
  "#bec1fc",
  "#e3b8d9",
  "#acb0fb",
  "#aad8e0",
  "#eaa1c1",
  "#e9e092",
  "#ecce97",
  "#878ff7",
  "#f8738e",
  "#4bd4a5",
  "#fdc442",
  "#8bd7cc",
  "#bec1fc",
  "#e3b8d9",
  "#acb0fb",
  "#aad8e0",
  "#eaa1c1",
  "#e9e092",
  "#ecce97",
];

const Graficas: FC<any> = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const {mainDispatch} = useContext(mainContext);

  // Estados Locales
  const [dashboard, setDashboard] = useState<any>(undefined);
  const [tipoContrato, setTipoContrato] = useState<ITiposContrato[]>([]);
  const [selectTipoContrato, setSelectTipoContrato] = useState();
  const [skeleton, setSkeleton] = useState<boolean>(true);
  const [estados, setEstados] = useState<IEstado[]>();
  const [estadosPre, setEstadosPre] = useState<IEstado[]>();
  const [estadosContra, setEstadosContra] = useState<IEstado[]>();
  const [estadosPost, setEstadosPost] = useState<IEstado[]>();
  const [selectEstadosPre, setSelectEstadosPre] = useState();
  const [selectEstadosContra, setSelectEstadosContra] = useState();
  const [selectEstadosPost, setSelectEstadosPost] = useState();
  const [render, setRender] = useState(0);
  const [listaProcesos, setListaProcesos] = useState([
    'Precontractual',
    'Contractual',
    'Postcontractual'
  ]);
  const [listaAreaContrato, setListaTipoContrato] = useState([
    'Financiera',
    'Juridica',
    'Operaciones',
    'RRHH'
  ]);

  const [listaAreasTotal, setListaAreasTotal] = useState([
    "RRHH",
    "Operaciones",
    "Juridica",
    "Financiera",
    "Total"
  ]);

  const [listaPie, setListaPie] = useState([
    "1 a 5 días", "6 a 15 días", "16 a 30 días", "31 a 60 días", "Más de 60 días"
  ]);
  const [vencidos, setVencidos] = useState<any[]>([]);
  const [porvencer, setPorVencer] = useState<any[]>([]);
  const [totalContratacion, setTotalContratacion] = useState<any[]>([]);

  const obtenerDashboard = async () => {
    const resp = await obtenerDashboardService();
    setDashboard(resp);
    setPorVencer(resp?.conporvencer);
    setVencidos(resp?.convencidos);

    let totalesContratacion: any[] = [];
    resp?.contratacionporarea?.map(item => {
      let area = item?.area;
      totalesContratacion[area] = item?.Total;
    });
    totalesContratacion["label"] = "Valores";
    setTotalContratacion([totalesContratacion]);
  }

  const obtenerTipoContrato = async () => {
    //const resp = await obtenerTiposContrato();
    let tipos: any[] = [];
    if(dashboard?.contratacionportipocontrato) {
      dashboard?.contratacionportipocontrato?.map(item => {
        tipos.push({
          nombre: item.tipo_contrato,
          id: item.index,
        });
      });

      const filterTipoContrato = tipos?.slice(0, 8).map(item => {
        return {
          value: item.id,
          label: item.nombre
        }
      });
      setSelectTipoContrato(filterTipoContrato);
      setTipoContrato(tipos);
    }
  }

  const obtenerEstadosGraficas = async () => {
    const resp = await obtenerEstados();
    setEstados(resp);
    setEstadosPre(resp.filter(item => (item.idmodulos as IModulos)?.id == EModulos.PRECONTRACTUAL.id));
    setEstadosContra(resp.filter(item => (item.idmodulos as IModulos)?.id == EModulos.CONTRACTUAL.id));
    setEstadosPost(resp.filter(item => (item.idmodulos as IModulos)?.id >= EModulos.POSTCONTRACTUAL.id && (item.idmodulos as IModulos)?.id <= 7));
  }

  const colourStyles: StylesConfig = {
    control: (styles) => ({...styles, backgroundColor: 'white'}),
    option: (styles) => {
      return {
        ...styles,
      }
    },
    multiValue: (styles, props) => {
      return {
        ...styles,
        backgroundColor: colorBarScheme[props?.options?.findIndex(item => item?.value == props?.data?.value)],
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: '#ffffff',
    }),
    multiValueRemove: (styles, {data}) => ({
      ...styles,
      color: '#ffffff',
      ':hover': {
        backgroundColor: data.color,
        color: 'gray',
      },
    }),
  };

  const formatNumber = (value) => {
    return new Intl.NumberFormat('es-CO',
      {
        style: 'currency',
        currency: 'COP',
      }
    ).format(value);
  }

  const getTotales = (data, columns) => {
    let total = 0;
    columns.map((item) => {
      let sub = data[0][item];
      total += sub ? sub : 0;
    });
    return total;
  }

  const handleEdit = (id: number) => {
    history.push(`/postcontractual/edit/${id}`);
    mainDispatch(closeModal());
  }

  const handleLink = (id: number, modulo: string) => {
    history.push(`/${modulo}/edit/${id}`);
    mainDispatch(closeModal());
  }

  const HayContratosVencidos = useMemo(() => {
    return dashboard?.convencidos?.some(({items}) => items.length > 0)
  }, [dashboard])

  useEffect(() => {
    obtenerDashboard();
    obtenerEstadosGraficas();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    obtenerTipoContrato();
  }, [dashboard]);

  useEffect(() => {
    if (dashboard && tipoContrato && estados) {
      const filterEstadosPre = estadosPre?.slice(0, 10).map(item => {
        return {
          value: item.id,
          label: item.nombre
        }
      });
      const filterEstadosContra = estadosContra?.slice(0, 10).map(item => {
        return {
          value: item.id,
          label: item.nombre
        }
      });
      const filterEstadosPost = estadosPost?.slice(0, 10).map(item => {
        return {
          value: item.id,
          label: item.nombre
        }
      });
      setSelectEstadosPre(filterEstadosPre);
      setSelectEstadosContra(filterEstadosContra);
      setSelectEstadosPost(filterEstadosPost);
      setSkeleton(false);
    }
  }, [dashboard])

  useEffect(() => {}, [render]);

  return (
    <>
      {
        skeleton ? <GraficaSkeleton/> : (
          <Card>
            <Row>
              <Col md={HayContratosVencidos ? 6 : 12}>
                <CardHeader>
                  <h4 className="header-title2">{t('Contratos por vencer')}</h4>
                </CardHeader>
                <CardBody>
                  <div style={{height: 400}}>
                    {dashboard?.conporvencer &&
                      <ResponsivePie
                        data={porvencer}
                        margin={{top: 40, right: 120, bottom: 80, left: 120}}
                        sortByValue={true}
                        innerRadius={0.5}
                        padAngle={0.7}
                        colors={colorBarScheme}
                        borderWidth={1}
                        borderColor={{from: 'color', modifiers: [['brighter', 10]]}}
                        radialLabelsSkipAngle={10}
                        radialLabelsTextColor="#333333"
                        radialLabelsLinkColor={{from: 'color'}}
                        sliceLabelsSkipAngle={10}
                        sliceLabelsTextColor="#333333"
                        onClick={(node, event) => mainDispatch(openModal({
                          modal: {
                            show: true,
                            title: t('Contratos por vencer'),
                            body: () => (
                              <ContratosVencer data={node?.data} timestamp={dashboard.timestamp}
                                               handleEdit={handleEdit}/>
                            )
                          }
                        }))}
                      />
                    }
                  </div>
                  <PieLegends
                    colors={colorBarScheme}
                    legends={listaPie}
                    data={porvencer}
                    setData={setPorVencer}
                    setRender={setRender}
                  />
                </CardBody>
              </Col>
              {HayContratosVencidos &&
                <Col md={6}>
                  <CardHeader><h4 className="header-title2">{t('Contratos vencidos')}</h4></CardHeader>
                  <CardBody>
                    <div style={{height: 400}}>
                      <ResponsivePie
                        data={vencidos}
                        margin={{top: 40, right: 90, bottom: 80, left: 120}}
                        sortByValue={true}
                        innerRadius={0.5}
                        padAngle={0.7}
                        colors={colorBarScheme}
                        borderWidth={1}
                        borderColor={{from: 'color', modifiers: [['brighter', 10]]}}
                        radialLabelsSkipAngle={10}
                        radialLabelsTextColor="#333333"
                        radialLabelsLinkColor={{from: 'color'}}
                        sliceLabelsSkipAngle={10}
                        sliceLabelsTextColor="#333333"
                        onClick={(node, event) => mainDispatch(openModal({
                          modal: {
                            show: true,
                            title: t('Contratos vencidos'),
                            body: () => (
                              <ContratosVencidos data={node?.data} timestamp={dashboard.timestamp}
                                                 handleEdit={handleEdit}/>
                            )
                          }
                        }))}
                        isInteractive={true}
                      />
                    </div>
                    <PieLegends
                      colors={colorBarScheme}
                      legends={listaPie}
                      data={vencidos}
                      setData={setVencidos}
                      setRender={setRender}
                    />
                  </CardBody>
                </Col>
              }
              <Col md={6}>
                <CardHeader><h4 className="header-title2">{t('Procesos por módulos')}</h4></CardHeader>
                <CardBody>
                  <div style={{display: 'flex', justifyContent: 'right'}}>
                    <strong>Total: {getTotales(dashboard?.procesospormodulos, listaProcesos)}</strong>
                  </div>
                  <div style={{height: 400}}>
                    {dashboard?.procesospormodulos && <ResponsiveBar
                      data={dashboard?.procesospormodulos}
                      keys={listaProcesos}
                      indexBy={"label"}
                      margin={{top: 10, right: 10, bottom: 0, left: 10}}
                      groupMode="grouped"
                      valueScale={{type: 'linear'}}
                      colors={colorBarScheme}
                      axisTop={null}
                      innerPadding={10}
                      axisRight={null}
                      axisBottom={null}
                      axisLeft={null}
                      labelTextColor="#ffffff"
                      isInteractive={true}
                      role="application"
                      onClick={(node, event) => mainDispatch(openModal({
                        modal: {
                          show: true,
                          title: t('Procesos por módulos'),
                          body: () => (
                            <ContratosPorArea
                              modulo={node?.id?.toString()}
                              area={"All"}
                              estado={"All"}
                              handleLink={handleLink}
                            />
                          )
                        }
                      }))}
                    />
                    }
                  </div>
                  <BoxLegends
                    colors={colorBarScheme}
                    legends={listaProcesos}
                    data={dashboard?.procesospormodulos}
                    setRender={setRender}
                  />
                </CardBody>
              </Col>
              <Col md={6}>
                <CardHeader><h4 className="header-title2">{t('Total Procesos por áreas')}</h4></CardHeader>
                <CardBody>
                  <div style={{display: 'flex', justifyContent: 'right'}}>
                    <strong>Total: {getTotales(dashboard?.totalprocesosporareas, listaAreaContrato)}</strong>
                  </div>
                  <div style={{height: 400}}>
                    {dashboard?.totalprocesosporareas && <ResponsiveBar
                      data={dashboard?.totalprocesosporareas}
                      keys={listaAreaContrato}
                      indexBy={"label"}
                      margin={{top: 10, right: 10, bottom: 0, left: 10}}
                      groupMode="grouped"
                      valueScale={{type: 'linear'}}
                      colors={colorBarScheme}
                      innerPadding={10}
                      axisTop={null}
                      axisRight={null}
                      axisBottom={null}
                      axisLeft={null}
                      labelTextColor="#ffffff"
                      isInteractive={true}
                      role="application"
                      onClick={(node, event) => mainDispatch(openModal({
                        modal: {
                          show: true,
                          title: t('Total Procesos por áreas'),
                          body: () => (
                            <ContratosPorProcesos
                              area={node?.id?.toString()}
                              modulo={"All"}
                              estado={"All"}
                              handleLink={handleLink}
                            />
                          )
                        }
                      }))}
                    />
                    }
                  </div>
                  <BoxLegends
                    colors={colorBarScheme}
                    legends={listaAreaContrato}
                    data={dashboard?.totalprocesosporareas}
                    setRender={setRender}
                  />
                </CardBody>
              </Col>
              <Col md={12}>
                <CardHeader><h4 className="header-title2">{t('Contratación por área y tipo de contrato')}</h4>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={9}>
                      <div style={{height: 400}}>
                        {dashboard?.contratacionporarea && tipoContrato.length > 0 && <ResponsiveBar
                          data={dashboard?.contratacionporarea?.filter(item => item.area != "Total")}
                          keys={selectTipoContrato?.map(tipo => tipo.label)}
                          indexBy="area"
                          margin={{top: 10, right: 10, bottom: 30, left: 50}}
                          valueScale={{type: 'linear'}}
                          indexScale={{type: 'band', round: true}}
                          colors={colorBarScheme}
                          tooltipFormat={(value) => {
                            return formatNumber(value)
                          }}
                          axisTop={null}
                          axisRight={null}
                          axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: '',
                            legendPosition: 'middle',
                            legendOffset: 0
                          }}
                          axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: '',
                            legendPosition: 'middle',
                            legendOffset: -40,
                            format: (value) => {
                              return abreviarPrecio(Number(value))
                            },
                          }}
                          labelTextColor="#ffffff"
                          label={({value}) =>
                            abreviarPrecio(Number(value))
                          }
                          role="application"
                        />
                        }
                      </div>
                    </Col>
                    <Col md={3}>
                      <Label>{t('Tipo de Contrato')}</Label>
                      <Select
                        isMulti={true}
                        type="select"
                        placeholder={t("Seleccione tipo de contrato")}
                        options={tipoContrato.map((item) => (
                          {
                            label: item.nombre,
                            value: item.id,
                          }
                        ))}
                        value={selectTipoContrato}
                        onChange={(e) => {
                          setSelectTipoContrato(e);
                        }}
                        styles={colourStyles}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Col>
              <Col md={12}>
                <CardHeader><h4 className="header-title2">{t('Total Valor Contratación')}</h4></CardHeader>
                <CardBody>
                  <div style={{height: 400}}>
                    {totalContratacion && <ResponsiveBar
                      data={totalContratacion}
                      keys={listaAreasTotal}
                      indexBy={"label"}
                      groupMode="grouped"
                      margin={{top: 10, right: 10, bottom: 10, left: 10}}
                      valueScale={{type: 'linear'}}
                      colors={colorBarScheme}
                      tooltipFormat={(value) => {
                        return formatNumber(value)
                      }}
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: -40
                      }}
                      axisLeft={null}
                      innerPadding={10}
                      labelTextColor="#ffffff"
                      legends={[]}
                      label={({value}) =>
                        abreviarPrecio(Number(value))
                      }
                      isInteractive={true}
                      role="application"
                    />
                    }
                  </div>
                  <BoxLegends
                    colors={colorBarScheme}
                    legends={listaAreasTotal}
                    data={totalContratacion}
                    setRender={setRender}
                  />
                </CardBody>
              </Col>
              <Col md={12}>
                <CardHeader><h4 className="header-title2">{t('Contratación por Tipo de contrato')}</h4></CardHeader>
                <CardBody>
                  <Row>
                    <Col md={9}>
                      <div style={{height: 400}}>
                        {dashboard?.contratacionportipocontrato && <ResponsiveBar
                          data={dashboard?.contratacionportipocontrato.filter(item => selectTipoContrato?.some(filter => filter.label == item.tipo_contrato))}
                          keys={['Total']}
                          indexBy={"tipo_contrato"}
                          margin={{top: 10, right: 10, bottom: 100, left: 10}}
                          valueScale={{type: 'linear'}}
                          colors={({id, data}) => {
                            return colorBarScheme[data.index || 0]
                          }}
                          tooltipFormat={(value) => {
                            return formatNumber(value)
                          }}
                          axisTop={null}
                          axisRight={null}
                          axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: '',
                            legendPosition: 'middle',
                            legendOffset: -40
                          }}
                          axisLeft={null}
                          labelTextColor="#ffffff"
                          label={({value}) =>
                            abreviarPrecio(Number(value))
                          }
                          role="application"
                        />
                        }
                      </div>
                    </Col>
                    <Col md={3}>
                      <Label>{t('Tipo de Contrato')}</Label>
                      <Select
                        isMulti={true}
                        type="select"
                        placeholder={t("Seleccione tipo de contrato")}
                        options={tipoContrato.map((item) => (
                          {
                            label: item.nombre,
                            value: item.id,
                          }
                        ))}
                        value={selectTipoContrato}
                        onChange={(e) => {
                          setSelectTipoContrato(e);
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Col>
              <Col md={6}>
                <CardHeader><h4 className="header-title2">{t('Procesos por módulo/área')}</h4></CardHeader>
                <CardBody>
                  <div style={{height: 400}}>
                    {dashboard?.procesospormodulosarea && <ResponsiveBar
                      data={dashboard?.procesospormodulosarea}
                      keys={listaAreaContrato}
                      indexBy="modulo"
                      layout="horizontal"
                      margin={{top: 10, right: 10, bottom: 30, left: 90}}
                      valueScale={{type: 'linear'}}
                      indexScale={{type: 'band', round: true}}
                      colors={colorBarScheme}
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 100
                      }}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: -40
                      }}
                      labelTextColor="#ffffff"
                      role="application"
                      onClick={(node, event) => mainDispatch(openModal({
                        modal: {
                          show: true,
                          title: t('Procesos por módulo/área'),
                          body: () => (
                            <ContratosPorArea
                              area={node?.id?.toString()}
                              modulo={node?.indexValue?.toString()}
                              estado={"All"}
                              handleLink={handleLink}
                            />
                          )
                        }
                      }))}
                    />
                    }
                  </div>
                  <BoxLegends
                    colors={colorBarScheme}
                    legends={listaAreaContrato}
                    data={dashboard?.procesospormodulosarea}
                    setRender={setRender}
                  />
                </CardBody>
              </Col>
              <Col md={6}>
                <CardHeader><h4 className="header-title2">{t('Procesos por área/módulo')}</h4></CardHeader>
                <CardBody>
                  <div style={{height: 400}}>
                    {dashboard?.procesosporareamodulos && <ResponsiveBar
                      data={dashboard?.procesosporareamodulos}
                      keys={listaProcesos}
                      indexBy="area"
                      layout="horizontal"
                      margin={{top: 10, right: 10, bottom: 30, left: 90}}
                      valueScale={{type: 'linear'}}
                      indexScale={{type: 'band', round: true}}
                      colors={colorBarScheme}
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 100
                      }}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: -40
                      }}
                      labelTextColor="#ffffff"
                      role="application"
                      onClick={(node, event) => mainDispatch(openModal({
                        modal: {
                          show: true,
                          title: t('Procesos por módulo/área'),
                          body: () => (
                            <ContratosPorArea
                              modulo={node?.id?.toString()}
                              area={node?.indexValue?.toString()}
                              estado={"All"}
                              handleLink={handleLink}
                            />
                          )
                        }
                      }))}
                    />
                    }
                  </div>
                  <BoxLegends
                    colors={colorBarScheme}
                    legends={listaProcesos}
                    data={dashboard?.procesosporareamodulos}
                    setRender={setRender}
                  />
                </CardBody>
              </Col>


              <Col md={12}>
                <CardHeader><h4 className="header-title2">{t('Precontractual')}</h4></CardHeader>
                <CardBody>
                  <Row>
                    <Col md={9}>
                      <div style={{height: 200}}>
                        {dashboard?.precontractualarea && <ResponsiveBar
                          data={dashboard?.precontractualarea}
                          keys={selectEstadosPre?.map(item => item.label)}
                          indexBy="area"
                          layout="horizontal"
                          margin={{top: 0, right: 0, bottom: 20, left: 90}}
                          valueScale={{type: 'linear'}}
                          indexScale={{type: 'band', round: true}}
                          colors={colorBarScheme}
                          axisTop={null}
                          axisRight={null}
                          axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: '',
                            legendPosition: 'middle',
                            legendOffset: 100
                          }}
                          axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: '',
                            legendPosition: 'middle',
                            legendOffset: -40
                          }}
                          labelTextColor="#ffffff"
                          role="application"
                          onClick={(node, event) => mainDispatch(openModal({
                            modal: {
                              show: true,
                              title: t('Precontractual'),
                              body: () => (
                                <ContratosPorArea
                                  area={node?.indexValue?.toString()}
                                  modulo={"Precontractual"}
                                  estado={node?.id?.toString()}
                                  handleLink={handleLink}
                                />
                              )
                            }
                          }))}
                        />
                        }
                      </div>
                    </Col>
                    <Col md={3}>
                      <Label>{t('Estados')}</Label>
                      <Select
                        isMulti={true}
                        type="select"
                        placeholder={"Seleccione los estados"}
                        options={estadosPre?.map((item) => (
                          {
                            label: item.nombre,
                            value: item.id,
                          }
                        ))}
                        value={selectEstadosPre}
                        onChange={(e) => {
                          setSelectEstadosPre(e);
                        }}
                        styles={colourStyles}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Col>
              <Col md={12}>
                <CardHeader><h4 className="header-title2">{t('Contractual')}</h4></CardHeader>
                <CardBody>
                  <Row>
                    <Col md={9}>
                      <div style={{height: 200}}>
                        {dashboard?.contractualarea && <ResponsiveBar
                          data={dashboard?.contractualarea}
                          keys={selectEstadosContra?.map(item => item.label)}
                          indexBy="area"
                          layout="horizontal"
                          margin={{top: 0, right: 0, bottom: 20, left: 90}}
                          valueScale={{type: 'linear'}}
                          indexScale={{type: 'band', round: true}}
                          colors={colorBarScheme}
                          axisTop={null}
                          axisRight={null}
                          axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: '',
                            legendPosition: 'middle',
                            legendOffset: 100
                          }}
                          axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: '',
                            legendPosition: 'middle',
                            legendOffset: -40
                          }}
                          labelTextColor="#ffffff"
                          role="application"
                          onClick={(node, event) => mainDispatch(openModal({
                            modal: {
                              show: true,
                              title: t('Contractual'),
                              body: () => (
                                <ContratosPorArea
                                  area={node?.indexValue?.toString()}
                                  modulo={"Contractual"}
                                  estado={node?.id?.toString()}
                                  handleLink={handleLink}
                                />
                              )
                            }
                          }))}
                        />
                        }
                      </div>
                    </Col>
                    <Col md={3}>
                      <Label>{t('Estados')}</Label>
                      <Select
                        isMulti={true}
                        type="select"
                        placeholder={"Seleccione los estados"}
                        options={estadosContra?.map((item) => (
                          {
                            label: item.nombre,
                            value: item.id,
                          }
                        ))}
                        value={selectEstadosContra}
                        onChange={(e) => {
                          setSelectEstadosContra(e);
                        }}
                        styles={colourStyles}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Col>
              <Col md={12}>
                <CardHeader><h4 className="header-title2">{t('Postcontractual')}</h4></CardHeader>
                <CardBody>
                  <Row>
                    <Col md={9}>
                      <div style={{height: 200}}>
                        {dashboard?.postcontractualarea && <ResponsiveBar
                          data={dashboard?.postcontractualarea}
                          keys={selectEstadosPost?.map(item => item.label)}
                          indexBy="area"
                          layout="horizontal"
                          margin={{top: 0, right: 0, bottom: 20, left: 90}}
                          valueScale={{type: 'linear'}}
                          indexScale={{type: 'band', round: true}}
                          colors={colorBarScheme}
                          axisTop={null}
                          axisRight={null}
                          axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: '',
                            legendPosition: 'middle',
                            legendOffset: 100
                          }}
                          axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: '',
                            legendPosition: 'middle',
                            legendOffset: -40
                          }}
                          labelTextColor="#ffffff"
                          role="application"
                          onClick={(node, event) => mainDispatch(openModal({
                            modal: {
                              show: true,
                              title: t('Postcontractual'),
                              body: () => (
                                <ContratosPorArea
                                  area={node?.indexValue?.toString()}
                                  modulo={"Postcontractual"}
                                  estado={node?.id?.toString()}
                                  handleLink={handleLink}
                                />
                              )
                            }
                          }))}
                        />
                        }
                      </div>
                    </Col>
                    <Col md={3}>
                      <Label>{t('Estados')}</Label>
                      <Select
                        isMulti={true}
                        type="select"
                        placeholder={"Seleccione los estados"}
                        options={estadosPost?.map((item) => (
                          {
                            label: item.nombre,
                            value: item.id,
                          }
                        ))}
                        value={selectEstadosPost}
                        onChange={(e) => {
                          setSelectEstadosPost(e);
                        }}
                        styles={colourStyles}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Col>
            </Row>
          </Card>
        )
      }
    </>
  );
}

export default Graficas;
