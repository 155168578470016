import React, {FC, useEffect, useState} from 'react';
import MUIDataTable, {MUIDataTableColumn} from 'mui-datatables';
import {optionsDataTable} from '../../../../../shared/utilities';
import {useHistory} from "react-router-dom";
import {IEstado} from "../../../../../core/models/administracion/estadosModel";
import {useUserContext} from '../../../../../shared/contexts/userContext';
import {useTranslation} from 'react-i18next';
import {listAreasContratos} from "../../../../../core/services/listasDesplegables";

const ListContratos: FC<IListProcesos> = ({
                                             columns,
                                             procesos,
                                             datatable,
                                             changePage
                                           }) => {

  const {count, length, page } = datatable;

  const options = {
    ...optionsDataTable,
    search: false,
    count,
    rowsPerPage: length,
    page,
    rowsPerPageOptions: [],
    viewColumns: false,
    serverSide: true,
    sort: false,
    filter: false,
    caseSensitive: false,
    onChangePage(currentPage) {
      changePage(currentPage);
    },
  };

  return (
    <MUIDataTable
      title={""}
      data={procesos.map(proceso => ([
        [proceso.consecutivo, proceso.id],
        listAreasContratos.find(item => item.value == proceso.area)?.label,
        proceso.nombre,
      ]))}
      columns={columns}
      options={options}
    />
  );
}

interface IListProcesos {
  columns: MUIDataTableColumn[],
  procesos: any,
  datatable: any,
  changePage: (pag: number) => void
}

export default ListContratos;
