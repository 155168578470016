import React, { FC, useEffect, useState } from 'react';
import { Alert, Button, Spinner, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { borrarOferta, cargaMasivaOferta, guardarOferta, listarOferta } from '../../../../core/services/precontractual/ofertaService';
import { usePrecontractualContext } from '../../../../shared/contexts/precontractualContext';
import { IOferta, IOfertaForm } from '../../../../core/models/precontractual/ofertaModel';
import ButtonCustom from '../../../../shared/components/ButtonCustom';
import { useMainContext } from '../../../../shared/contexts/mainContext';
import { changeLoading, closeModal, falseLoading, openModal, trueLoading } from '../../../../shared/contexts/actions/mainActions';
import { IBorrarRegistro } from '../../../../shared/utilities';
import FormOferta from '../Forms/FormOferta';
import ModalMsj from '../../../../shared/components/ModalMsj';
import CurrencyFormat from 'react-currency-format';
import { listMoneda } from '../../../../core/services/listasDesplegables';
import { descargarProducto } from '../../../../core/services/precontractual/productoService';
import { downloadFile } from '../../../../shared/helpers/generales';
import FormCargaMasiva from '../../../documentos/cargaMasiva/FormCargaMasiva';
import { ICargaMasiva } from '../../../../core/models/documentos/cargaMasivaModel';
import ErroresCargaMasiva from '../../../documentos/cargaMasiva/ErroresCargaMasiva';

const TablaOferta: FC<ITablaOferta> = ({ habilitar, idpreproveedor }) => {
  const { t } = useTranslation();
  const { mainState, mainDispatch, handleNotification } = useMainContext();
  const { proceso } = usePrecontractualContext();

  const [loading, setLoading] = useState(true);
  const [oferta, setOferta] = useState<IOferta[]>([]);
  const [borrar, setBorrar] = useState<IBorrarRegistro>({
    show: false,
    id: 0
  });

  const listarDatos = async () => {
    const params = idpreproveedor ? {
      filter: 'idprecontractual,idpreproveedor',
      valuefilter: `${proceso?.id},${idpreproveedor}`,
    } : {
      filter: 'idprecontractual',
      valuefilter: proceso?.id,
    };
    const oferta = await listarOferta(params);
    setOferta(oferta);
    setLoading(false);
  }

  const onSubmit = async (values: IOfertaForm) => {
    mainDispatch(trueLoading());
    const guardar = await guardarOferta(values, handleNotification);
    if (guardar) {
      await listarDatos();
      mainDispatch(closeModal());
    }
    mainDispatch(falseLoading());
  }

  const changeModal = (id?: number) => {
    setBorrar({
      show: !borrar.show,
      id: (id) ? id : 0
    });
  };

  /**
   * Permite eliminar un registro
   * @param id 
   */
  const handleDelete = async (id: number) => {
    mainDispatch(changeLoading());
    if (await borrarOferta(id, handleNotification)) {
      await listarDatos();
    }
    mainDispatch(changeLoading());
    changeModal();
  };

  const handleAdd = () => {
    mainDispatch(openModal({
      modal: {
        show: true,
        title: t('Crear Propuesta'),
        body: () => (
          <FormOferta
            onSubmit={onSubmit}
            record={null}
            idpreproveedor={idpreproveedor}
          />
        )
      }
    }))
  };
  
  const handleEdit = (id: number) => {
    const record = oferta.find(row => row.id === id);
    mainDispatch(openModal({
      modal: {
        show: true,
        title: t('Editar oferta'),
        body: () => (record ? (
          <FormOferta
            onSubmit={onSubmit}
            record={record}
            idpreproveedor={idpreproveedor}
          />
         ) : null
        )
      }
    }))
  };

   /**
   * Permite descargar un archivo
   * @param id 
   */
   const handleDownload = async () => {
    mainDispatch(changeLoading());
    const values = {
      id: proceso?.id ?? 0
    }
    const url = await descargarProducto(values, handleNotification);
    if (url) {
      downloadFile(url, `Formato-Cargue-Masivo-Productos.xlsx`);
    }
    mainDispatch(changeLoading());
  };

  const onSubmitCargaMasiva = async (carga: ICargaMasiva) => {
    mainDispatch(trueLoading());
    const values = {
      idcargamasiva: carga.id,
    }
    const guardar = await cargaMasivaOferta(values, handleNotification);
    if (guardar) {
      mainDispatch(closeModal());
      if (guardar.errors) {
        mainDispatch(openModal({
          modal: {
            show: true,
            title: t('Errores encontrados en la Carga Masiva'),
            body: () => (
              <ErroresCargaMasiva response={guardar} />
            )
          }
        }))
      } else {
        await listarDatos();
      }
    }
    mainDispatch(falseLoading());
  }

  const handleCargaMasiva = () => {
    mainDispatch(openModal({
      modal: {
        show: true,
        title: t('Carga Masiva'),
        body: () => (
          <FormCargaMasiva
            callback={onSubmitCargaMasiva}
            entidad="PreProveedor"
            identidad={idpreproveedor ?? 0}
            nombrecarga="ofertas_precontractual"
            record={null}
          />
        )
      }
    }))
  };

  useEffect(() => {
    listarDatos();
  }, [])

  return (
    <>
      {loading ? (
        <div className="d-flex flex-column justify-content-center text-center">
          <Spinner color="primary" className="m-auto" />
          <span className="mt-3">
            {t('Cargando ofertas...')}
          </span>
        </div>
      ) : (
        <>
          {habilitar && (
            <ButtonCustom
              color="info"
              onClick={handleDownload}
              loading={mainState.loading}
              disabled={mainState.loading}
              outline
              className="mb-2"
              size="sm"
            >
              <i className="uil-mr uil-file"></i>{t('Descargar Excel de Productos y Servicios')}
            </ButtonCustom>
          )}
          <Table>
            <thead>
              <tr>
                <th align="center">{t('Proveedor')}</th>
                <th align="center">{t('Nombre Producto y/o Servicio')}</th>
                <th align="center">{t('Cantidad')}</th>
                <th align="center">{t('Unidad de Medida')}</th>
                <th align="center">{t('Valor Unitario')}</th>
                <th align="center">{t('Valor Total')}</th>
                <th align="center">{t('Tipo de Moneda')}</th>
                <th align="center">{t('Valor Unitario (oferta)')}</th>
                <th align="center">{t('Valor Total (oferta)')}</th>
                {habilitar && (
                  <th align="center">{t('Acción')}</th>
                )}
              </tr>
            </thead>
            <tbody>
              {oferta.map(row => (
                <tr key={row.id}>
                  <td align="center">{row.idpreproveedor.idproveedorcliente.razonsocial}</td>
                  <td align="center">{row.idproducto.producto}</td>
                  <td align="center">{row.idproducto.cantidad}</td>
                  <td align="center">
                    {row.idproducto.idunidadmedida.alias} ({row.idproducto.idunidadmedida.unidad})
                  </td>
                  <td align="center">
                    <CurrencyFormat
                      value={row.idproducto.valorunitario}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      suffix={',00'}
                    />
                  </td>
                  <td align="center">
                    {!!(row.idproducto.valorunitario && row.idproducto.cantidad) && (
                      <CurrencyFormat
                        value={Number(row.idproducto.valorunitario) * row.idproducto.cantidad}
                        displayType={'text'}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        suffix={',00'}
                      />
                    )}
                  </td>
                  <td align="center">
                    {listMoneda.find(item => item.id === row.idproducto.tipomoneda)?.nombre}
                  </td>
                  <td align="center">
                    <CurrencyFormat
                      value={row.valorunitario}
                      displayType={'text'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      suffix={',00'}
                    />
                  </td>
                  <td align="center">
                    {!!(row.valorunitario && row.idproducto.cantidad) && (
                      <CurrencyFormat
                        value={Number(row.valorunitario) * row.idproducto.cantidad}
                        displayType={'text'}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        suffix={',00'}
                      />
                    )}
                  </td>
                  {habilitar && (
                    <td align="center">
                      <Button
                        color="primary"
                        className="mr-1"
                        onClick={() => handleEdit(row.id || 0)}
                        size="sm"
                        outline
                        title={t('Editar')}
                      >
                        <i className="uil-edit"></i>
                      </Button>
                      <Button
                        color="danger"
                        className="mr-1"
                        onClick={() => changeModal(row.id || 0)}
                        size="sm"
                        outline
                        title={t('Eliminar')}
                      >
                        <i className="uil-trash-alt"></i>
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
          {oferta.length === 0 && (
            <Alert color="warning">{t('No se han cargado propuestas.')}</Alert>
          )}
          {habilitar && (
            <>
              <ButtonCustom
                color="info"
                onClick={handleAdd}
                loading={mainState.loading}
                disabled={mainState.loading}
                outline
                className="mb-2"
                size="sm"
              >
                <i className="uil-mr uil-plus"></i>{t('Crear Propuesta')}
              </ButtonCustom>
              <ButtonCustom
                color="success"
                onClick={handleCargaMasiva}
                loading={mainState.loading}
                disabled={mainState.loading}
                outline
                className="mb-2 ml-2"
                size="sm"
              >
                <i className="uil-mr uil-plus"></i>{t('Carga Masiva')}
              </ButtonCustom>
            </>
          )}
        </>
      )}
      <ModalMsj
        {...borrar}
        action={handleDelete}
        closeModal={changeModal}
      />
    </>
  );
};

interface ITablaOferta {
  habilitar?: boolean;
  idpreproveedor?: number;
}

export default TablaOferta;
