// GraphSkeleton.jsx
import React, {FC} from 'react';
import ContentLoader from 'react-content-loader';

const GraphSkeleton : FC<any> = props => (
  <ContentLoader
    speed={0.5}
    width="100%"
    height={400}
    viewBox="0 0 1500 600"
    backgroundColor="#bccde6"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="50" y="0" rx="4" ry="4" width="700" height="30"/>
    <rect x="850" y="0" rx="4" ry="4" width="700" height="30"/>
    <rect x="50" y="400" rx="4" ry="4" width="700" height="40"/>
    <rect x="850" y="400" rx="4" ry="4" width="700" height="40"/>

    <rect x="50" y="50" rx="4" ry="4" width="700" height="300"/>
    <rect x="850" y="50" rx="4" ry="4" width="700" height="300"/>
    <rect x="50" y="450" rx="4" ry="4" width="700" height="300"/>
    <rect x="850" y="450" rx="4" ry="4" width="700" height="300"/>


  </ContentLoader>
);

export default GraphSkeleton;
