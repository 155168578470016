import { getRequest, updateItem, deleteItem } from '../peticionesService';
import urlApi from '../../api';
import { IFormUsuarios, IPerfiles, IUsuarios } from '../../models/usuarios/usuariosModel';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';
import { EGroupsNames } from '../../../shared/utilities';
import {useUpdateRequest} from "../../../shared/hooks/useUpdate";

/**
 * Permite obtener los registros en la bd
 */
export const obtenerUsuarios = async (params?: any): Promise<IUsuarios[]> => {
  let data: IUsuarios[] = [];
  const url = urlApi.LIST_USUARIOS;
  try {
    const resp = await getRequest(url, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener los registros en la bd
 */
export const obtenerPerfiles = async (): Promise<IPerfiles[]> => {
  let data: IPerfiles[] = [];
  const url = urlApi.LIST_PERFILES;
  try {
    const resp = await getRequest(url);
    
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener los registros en la bd
 */
 export const obtenerPerfilesByCode = async (codigo: EGroupsNames): Promise<IUsuarios[]> => {
  let data: IUsuarios[] = [];
  const url = urlApi.LIST_PERFILES_BY_CODE;
  try {
    const resp = await getRequest(url, { codigo });
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener el registro por criterio en la bd
 */
export const obtenerUsuarioByCriterio = async (id:number,criteria?:string,valuecriteria?:string): Promise<IUsuarios> => {
  let data: IUsuarios | any = {};
  try {
    const resp = await getRequest(`${urlApi.SHOW_USUARIO}${id}`,criteria? {criteria:criteria,valuecriteria:valuecriteria} : {});
    data = resp.data.record;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const guardarUsuario = async (
  values: IFormUsuarios,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = (!values.id) ? urlApi.CREAR_USUARIO : `${urlApi.EDIT_USUARIO}${values.id}`
  return await updateItem(url, values, notification);
};

/**
 * Permite borrar un registro
 * @param id 
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const borrarUsuario = async (
  id: number,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = `${urlApi.BORRAR_USUARIO}${id}`;
  return await deleteItem(url, notification);
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const recoveryContrasena = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = urlApi.RECOVERY_PASSWORD;
  return await updateItem(url, values, notification);
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const cambiarContrasena = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = `${urlApi.CHANGE_PASSWORD}${values?.id}`;
  return await updateItem(url, values, notification);
};

/**
 * Permite obtener los registros en la bd
 */
 export const obtenerDashboardService = async () => {
  const url = urlApi.DASHBOARD_USUARIO;
  try {
    const resp = await getRequest(url);
    const data = resp.data;
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

/**
 * Permite obtener los registros en la bd
 */
 export const listarContratosPorEstadoService = async (
    idestado: number,
    idmodulo: number,
    notification: (
      msj: string,
      type: mainTypeMsj
    ) => void
  ) => {
  const url = `${urlApi.CONTRATOS_POR_ESTADO}${idestado}/${idmodulo}`;
  try {
    const resp = await getRequest(url, null, true, notification);
    const data = resp.data.records;
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const useObtenerProcesosDinamicos = (
  handle = false,
  params?,
  body?
) => {
  const [guardarService] = useUpdateRequest();

  const handleGetContratos = async (
    body: any,
    requestData = true
  ): Promise<any> => {
    return guardarService({
      url: urlApi.DASHBOARD_DATATABLE,
      contentType: "application/json",
      sendNotification: false,
      body,
      requestData,
    });
  };

  return {
    handleGetContratos,
  };
};
