import React, {FC, useState, useEffect, useMemo, useContext} from 'react';
import {EGroupsNames, IBorrarRegistro} from '../../../../shared/utilities';
// Contextos
import {openModal, changeLoading, closeModal, changeHeader} from '../../../../shared/contexts/actions/mainActions';
// Models
import {IManual, IFormManual} from '../../../../core/models/administracion/manualesModel';
import {MUIDataTableColumn} from 'mui-datatables';
import {Button} from 'reactstrap';
import {guardarManual, obtenerManuales, borrarManual} from '../../../../core/services/administracion/manualService';
import FormManual from './components/FormManual';
import ModalMsj from '../../../../shared/components/ModalMsj';
import {mainTypeHeader} from '../../../../shared/contexts/interfaces/mainInterfaces';
import DataTableSkeleton from '../../../../shared/components/DataTableSkeleton';
import {useMainContext} from '../../../../shared/contexts/mainContext';
import {useOptionsTables} from '../../../../shared/hooks/useOptionsTables';
import {useTranslation} from 'react-i18next';
import ListManuales from "./components/ListManuales";
import {userContext} from "../../../../shared/contexts";
import {downloadFile} from "../../../../shared/helpers/generales";
import dev from "../../../../core/enviroments";

const Manuales: FC = () => {
  // estados locales
  const [skeleton, setSkeleton] = useState<boolean>(true);
  const [manuales, setManuals] = useState<IManual[]>([]);
  const [borrar, setBorrar] = useState<IBorrarRegistro>({
    show: false,
    id: 0
  });

  // contextos
  const {mainDispatch, handleNotification} = useMainContext();
  const {userState} = useContext(userContext);
  const {estadoOption} = useOptionsTables();
  const {t, i18n} = useTranslation();
  const {language} = i18n;

  const isAdmin = useMemo(() => !!(userState?.data?.roles?.find(rol => rol === EGroupsNames.ADMINISTRADOR || rol === EGroupsNames.SUPERADMIN))
    , [userState]);

  const changeModal = (id?: number) => {
    setBorrar({
      show: !borrar.show,
      id: (id) ? id : 0
    });
  };

  /**
   * Registro ó actualización
   * @param values
   */
  const onSubmit = async (values: IFormManual) => {

    const data = {
      archivo: values.file,
      nombre: values.nombre,
      activo: values.activo ? 1 : 0,
      id: values.id
    }

    mainDispatch(changeLoading());
    if (await guardarManual(data, handleNotification)) {
      await listarManuales();
      mainDispatch(closeModal());
    } else {
      mainDispatch(changeLoading());
    }
  };

  /**
   * Permite eliminar un registro
   * @param id
   */
  const handleDelete = async (id: number) => {
    mainDispatch(changeLoading());
    if (await borrarManual(id, handleNotification)) {
      await listarManuales();
    }
    mainDispatch(changeLoading());
    changeModal();
  };

  const listarManuales = async () => {
    setManuals(await obtenerManuales());
    setSkeleton(false);
  };

  /**
   * Permite editar un registro
   * @param id
   */
  const handleEdit = (id: number) => {
    const data = manuales.find(tipo => tipo.id === id);
    mainDispatch(openModal({
      modal: {
        show: true,
        title: t('Editar manual'),
        body: () => (
          <>
            <FormManual
              onSubmit={onSubmit}
              data={data}
            />
          </>
        )
      }
    }))
  };

  const handleView = (id) => {
    const data = manuales.find(tipo => tipo.id === id);
    let nombreDoc = data?.archivo;
    let nombre = data?.nombre ?? '';
    downloadFile(`${dev.URL_SERVER}/manuales/${nombreDoc}`, nombre);
  }

  useEffect(() => {
    listarManuales();
    // eslint-disable-next-line
  }, [language]);

  const columns: MUIDataTableColumn[] = [
    {
      name: t('Nombre'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Nombre')}</h5>
        ),
      }
    },
    {
      name: t('Archivo'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Archivo')}</h5>
        ),
      }
    },
    {
      name: t('Fecha de Actualización'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Fecha de Actualización')}</h5>
        ),
      }
    },
    estadoOption,
    {
      name: t('Acciones'),
      options: {
        filter: false,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Acciones')}</h5>
        ),
        customBodyRender: (id) => {
          return (
            <>
              {isAdmin &&
                <>
                  <Button
                    color="primary"
                    className="mr-1 mb-1 btn-sm"
                    onClick={() => handleEdit(id)}
                  >
                    <i className="uil-pen"></i>
                  </Button>
                  <Button
                    color="danger"
                    className="mr-1 mb-1 btn-sm"
                    onClick={() => changeModal(id)}
                  >
                    <i className="uil-trash-alt"></i>
                  </Button>
                </>
              }
              <Button
                color={"success"}
                className="mr-1 mb-1 btn-sm"
                onClick={() => handleView(id)}
              >
                <i className={"uil-download-alt"}></i>
              </Button>
            </>
          );
        }
      }
    },
  ];

  return (
    <>
      {
        skeleton ? <DataTableSkeleton/> : (
          <ListManuales
            columns={columns}
            manuales={manuales}
            onSubmit={onSubmit}
            isAdmin={isAdmin}
          />
        )
      }
      <ModalMsj
        {...borrar}
        action={handleDelete}
        closeModal={changeModal}
      />
    </>
  );
};
export default Manuales;
