import dev from '../../core/enviroments';
import { IAnexos } from '../../core/models/documentos/anexosModel';
import { IFirmas } from '../../core/models/documentos/firmasModel';

export const downloadFile = (url: string, name: string) => {
  fetch(url, {
    method: 'GET',
  }).then(function(resp) {
    // Creamos el archivo en un BLOB
    return resp.blob();
  }).then(function(blob) {
    // Generamos un url temporal para el archivo
    const urlBlob = window.URL.createObjectURL(blob);
    // Creamos el tag a
    const elementA = document.createElement('a');
    elementA.href = urlBlob;
    elementA.download = name;
    elementA.click();
    elementA.remove();
  });
};

export const obtenerPDFFirma = (firma: IFirmas) => {
  if (firma.idfirmapadre && firma.firmado === null) {
    const firmapadre = firma.idfirmapadre;
    return {
      path: `${dev.URL_SERVER}/uploads/${firmapadre.directorio}/${firmapadre.nombrearchivo}.pdf`,
      nombre: firmapadre.nombrearchivo,
    };
  } else if (firma.firmado === 1) {
    return {
      path: `${dev.URL_SERVER}/uploads/${firma.directorio}/${firma.nombrearchivo}.pdf`,
      nombre: firma.nombrearchivo,
    }
  } else {
    const version = firma.iddocumento;
    return {
      path: `${dev.URL_SERVER}/uploads/${version.directorio}/${version.nombrearchivo}.pdf`,
      nombre: version.nombrearchivo,
    }
  }
}

export const obtenerFirmasFirmadas = (firmas: IFirmas[]) => {
  const firmado = firmas.filter(item => item.firmado === 1);
  const ordenado = firmado.sort((a, b) => {
    const dateA = new Date(String(a.fechafirma));
    const dateB = new Date(String(b.fechafirma));
    return (dateB.getTime() - dateA.getTime());
  })
  return ordenado;
}

export const handleVerDocumento = (anexo: IAnexos | null) => {
  window.open(
    `${dev.URL_SERVER}/uploads/${anexo?.directorio}/${anexo?.nombrearchivo}.${anexo?.extension}`,
    '_blank'
  )
}
