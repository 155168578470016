import React, { FC, useEffect, useState } from 'react';
import { IProveedores } from '../../../../../core/models/precontractual/proveedoresModel';
import { usePrecontractualContext } from '../../../../../shared/contexts/precontractualContext';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import FormTerceros from '../../../../administracion/terceros/components/FormTercero';
import { IFormTerceros, ITerceros } from '../../../../../core/models/administracion/tercerosModel';
import { closeModal, falseLoading, openModal, trueLoading } from '../../../../../shared/contexts/actions/mainActions';
import { useMainContext } from '../../../../../shared/contexts/mainContext';
import { guardarTercero, obtenerTerceros } from '../../../../../core/services/administracion/tercerosService';
import { obtenerPaises } from '../../../../../core/services/administracion/paisesService';
import { IPaises } from '../../../../../core/models/administracion/paisesModel';

const FormInfoBasicaPre: FC<IFormInfoBasicaPre> = ({ proveedor, botonActualizar }) => {
  const { t } = useTranslation();
  const { proceso } = usePrecontractualContext();
  const { mainDispatch, handleNotification } = useMainContext();

  const [paises, setPaises] = useState<IPaises[]>([]);
  const [tercero, setTercero] = useState<ITerceros>(proveedor.idproveedorcliente);

  const listarPaises = async () => {
    const params = {
      filter: 'activo',
      valuefilter: 1
    };
    setPaises(await obtenerPaises(params));
  };

  const onSaveTercero = async (values: IFormTerceros, tipo?: number | string) => {
    mainDispatch(trueLoading());
    await guardarTercero(values, handleNotification);
    await obtenerTercero();
    mainDispatch(closeModal())
    mainDispatch(falseLoading());
  };

  const obtenerTercero = async () => {
    const terceros = await obtenerTerceros('id', String(proveedor.idproveedorcliente.id));
    if (terceros.length) {
      setTercero(terceros[0]);
    }
  }

  const handleEdit = () => {
    mainDispatch(openModal({
      modal: {
        show: true,
        title: t('Editar Datos Tercero'),
        body: () => (
          <FormTerceros
            onSubmit={(data: IFormTerceros) => onSaveTercero(data, 1)}
            onCancel={() => mainDispatch(closeModal())}
            empresas={[]}
            paises={paises}
            showactivar={false}
            tipo={1}
            data={tercero}
          />
        )
      }
    }))
  };

  useEffect(() => {
    listarPaises();
  }, []);
  
  return (
    <>
      <Row className="mb-4">
        {botonActualizar && (
          <Col sm={12}>
            <Button onClick={handleEdit}>{t('Actualizar Datos Tercero')}</Button>
          </Col>
        )}
        <Col sm={4}>
          <Label className="mt-2">{t('Proponente')}: </Label>
          <Input type="text" disabled value={proveedor.idproveedorcliente.razonsocial} />
        </Col>
        <Col sm={4}>
          <Label className="mt-2">{t('Fecha actual')}: </Label>
          <Input type="text" disabled value={moment().format('YYYY-MM-DD')} />
        </Col>
        <Col sm={4}>
          <Label className="mt-2">{t('Empresa')}: </Label>
          <Input type="text" disabled value={proceso?.idempresa.nombre} />
        </Col>
        <Col sm={4}>
          <Label className="mt-2">{t('Número Proceso')}: </Label>
          <Input type="text" disabled value={proceso?.consecutivo || ''} />
        </Col>
        <Col sm={12}>
          <Label className="mt-2">{t('Nombre de la Solicitud')}: </Label>
          <Input type="textarea" disabled value={proceso?.nombresolicitud || ''} />
        </Col>
        <Col sm={12}>
          <Label className="mt-2">{t('Descripción de la Solicitud')}: </Label>
          <Input type="textarea" disabled value={proceso?.descripcionsolicitud || ''} />
        </Col>
      </Row>
    </>
  )
}

interface IFormInfoBasicaPre {
  proveedor: IProveedores;
  botonActualizar?: boolean;
}

export default FormInfoBasicaPre;
