import { MUIDataTableOptions } from 'mui-datatables';
import { IPaises } from '../core/models/administracion/paisesModel';
import token from "../pages/usuarios/token";

// Enums generales
export enum FormValidation {
  required = 'Este campo es requerido',
  email = 'Ingrese un correo electrónico valido',
  emailDup = 'Este correo electrónico ya esta en uso',
  nitDup = 'Este nit ya esta en uso',
  documentoDup = 'Este documento ya esta en uso',
  minLength = 'Minimo de caracteres permitidos ',
  passwordMatch = 'Las contraseñas deben coincidir',
  passwordValid = 'La contraseña debe tener minimo 10 caracteres, máximo 19 caracteres, Al menos 1 mayúscula y 1 carácter especial',
  number = 'Este campo debe contener solo números',
  campoRepetido = 'Este campo esta repetido',
  maximumFiles = 'Ha cargado más archivos de los permitidos',
  maxPercentage = 'El porcentaje máximo permitido es de 100',
  maxPercentageCriterion = 'El porcentaje de la calificación no puede ser mayor al porcentaje del criterio',
  max5evaluation = 'La calificación máxima es de 5',
  min2Records = 'Debe seleccionar al menos dos',
  min50Characters = 'Debe ingresar al menos 50 caracteres',
  max3000Characters = 'Debe ingresar máximo 3.000 caracteres',
  max1option = 'Debe seleccionar máximo una opción',
  lettersMatch = 'Este campo debe tener únicamente letras',
}

export const EModulos = {
  CONTRACTUAL: {
    id: 1,
    nombre: 'contractual',
    label: 'Contractual'
  },
  POSTCONTRACTUAL: {
    id: 2,
    nombre: 'postcontractual',
    label: 'Postcontractual'
  },
  PRECONTRACTUAL: {
    id: 12,
    nombre: 'precontractual',
    label: 'Precontractual'
  },
  ASESORIAS: {
    id: 10,
    nombre: 'asesorias',
    label: 'Asesorías'
  },
  JURIDICO: {
    id: 11,
    nombre: 'juridico'
  },
};

export enum EGroupsNames {
  SUPERADMIN = 'ROLE_SUPER_ADMINISTRADOR',
  ADMINISTRADOR = 'ROLE_ADMINISTRADOR',
  SOLICITANTE = 'ROLE_SOLICITANTE',
  COORDINADOR = 'ROLE_COORDINADOR',
  ABOGADO = 'ROLE_ABOGADO',
  FIRMANTE = 'ROLE_FIRMANTE',
  APROBADOR = 'ROLE_APROBADOR',
  TERCERO = 'ROLE_TERCERO',
  APROBADOR_NIV1 = 'ROLE_APROBADOR_NIV_1',
  APROBADOR_NIV2 = 'ROLE_APROBADOR_NIV_2',
  APROBADOR_NIV3 = 'ROLE_APROBADOR_NIV_3',
  EVALUADOR = 'ROLE_EVALUADOR',
  REGISTRO_TERCEROS = 'ROLE_REGISTRO_TERCEROS',
  ABOGADO_EXTERNO = 'ROLE_ABOGADO_EXTERNO',
  JEFE_AREA = 'ROLE_JEFE_AREA'
}

export enum EGroups {
  SUPERADMIN = 1,
  ADMINISTRADOR = 2,
  SOLICITANTE = 3,
  COORDINADOR = 4,
  ABOGADO = 5,
  FIRMANTE = 6,
  APROBADOR = 7,
  TERCERO = 8,
  ABOGADO_EXTERNO = 15,
}

export enum ERolsProceso {
  TERCERO = 0,
  SOLICITANTE = 1,
  COORDINADOR = 2,
  ABOGADO = 3,
  APROBADOR = 4,
  FIRMANTE = 5,
  JEFEAREA = 6,
  REGISTRO_TERCEROS = 7,
  EVALUADOR = 8,
  ABOGADO_EXTERNO = 15,
  APROBADOR_NIV_1 = 101,
  APROBADOR_NIV_2 = 102,
  APROBADOR_NIV_3 = 103,
}

export enum EEstadosPre {
  CREACION_SOLICITUD = 0,
  PARAMETROS_INICIALES = 1,
  DOCUMENTOS_SOLICITUD = 2,
  CRONOGRAMA_SOLICITUD = 3,
  CRITERIOS_EVALUACION = 4,
  SELECCION_APROBADORES_ESCALAMIENTO = 5,
  APROBACIONES_ESCALAMIENTO = 6,
  SELECCION_PROPONENTES = 7,
  VERIFICACION_ADENDAS = 8,
  PREGUNTAS = 9,
  RESPUESTAS = 10,
  CARGUE_PROPUESTAS = 11,
  VERIFICACION_CONDICIONES = 12,
  VALIDACION_NEGOCIACION = 13,
  NEGOCIACION = 14,
  CARGUE_RENEGOCIACIONES = 15,
  SELECCION_EVALUADORES = 16,
  EVALUACION_PROPUESTAS = 17,
  ADJUDICACION = 18,
  ACEPTACION_ADJUDICACION = 19,
  ADJUDICADO = 20,
  FINALIZADO = 21,
  DESIERTO = 22,
  CANCELADO = 23,
  REQUIERE_LICITACION = 24,
}

export enum ETipoSolicitud {
  LICITACION = 1,
  COTIZACION = 2,
  RFI = 3,
}

// interfaces generales
export interface ILayout {
  className?: string;
  children: any;
}
export interface IFormComponent {
  data?: IPaises;
  onSubmit: any;
}

export interface IBorrarRegistro {
  show: boolean,
  id: number
}

export interface IAccionRegistro {
  show: boolean,
  accion: string,
}

// data
export const optionsDataTable: MUIDataTableOptions = {
  filter: true,
  viewColumns: true,
  filterType: "dropdown",
  responsive: "standard",
  selectableRows: "none",
  download: false,
  elevation: 0,
  sort: true,
  print: false,
  textLabels: {
    body: {
      noMatch: "Lo sentimos, no se han encontrado datos.",
      toolTip: "Organizar",
    },
    pagination: {
      next: "Página siguiente",
      previous: "Página anterior",
      rowsPerPage: "Columnas por página:",
      displayRows: "de",
    },
    toolbar: {
      search: "Buscar",
      print: "Imprimir",
      viewColumns: "Ver columnas",
      filterTable: "Filtrar tabla",
    },
    filter: {
      all: "Todos",
      title: "FILTROS",
      reset: "LIMPIAR",
    },
    viewColumns: {
      title: "Mostar columnas",
      titleAria: "Mostrar/Ocultar columnas de la tabla",
    },
    selectedRows: {
      text: "fila(s) seleccionadas",
      delete: "Borrar",
      deleteAria: "Borrar filas seleccionadas",
    },
  },
  customSearch: (searchQuery, currentRow) => {
    const procesaTexto = (text) => {
      return text?.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    }
    let isFound = false;
    currentRow.map(col => {
      if (procesaTexto(col)?.includes(procesaTexto(searchQuery))) {
        isFound = true;
      }
    });
    return isFound;
  },
};

export const optionEstadoSearch = (indexSearch: number): MUIDataTableOptions => {
  return {
    ...optionsDataTable,
    customSearch: (searchQuery, currentRow) => {
      const rexHab = /^(h)(a|$)(b|$)(i|$)(l|$)(i|$)(t|$)(a|$)(d|$)(o|$)/;
      const rexDes = /^(d)(e|$)(s|$)(h|$)(a|$)(b|$)(i|$)(l|$)(i|$)(t|$)(a|$)(d|$)(o|$)/;
      let isFound = false;
      const procesaTexto = (text) => {
        return text?.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      }
      currentRow.forEach(col => {
        if (col.toString().indexOf(searchQuery) >= 0) {
          isFound = true;
        }
  
        if (rexHab.test(searchQuery.toLowerCase()) && (currentRow[indexSearch] === 1)) {
          isFound = true;
        }
  
        if (rexDes.test(searchQuery.toLowerCase()) && (currentRow[indexSearch] !== 1)) {
          isFound = true;
        }

        if (procesaTexto(col)?.includes(procesaTexto(searchQuery))) {
          isFound = true;
        }
      });
      return isFound;
    },
  }
};

export const getTokenUrl = () => {
  const tokenBasic = window.location.pathname.match(/token\/([0-9]+)\/([a-zA-Z0-9-_.]+)/);
  const tokenDocs = window.location.pathname.match(/documentos\/([a-zA-Z0-9_]+)\/([a-zA-Z0-9-_.]+)/);
  const tokenPoli = window.location.pathname.match(/tercero\/([a-zA-Z0-9-_.]+)/);

  if (tokenBasic && tokenBasic[2]) return tokenBasic[2];
  else if (tokenDocs && tokenDocs[2]) return tokenDocs[2];
  else if (tokenPoli && tokenPoli[1]) return tokenPoli[1];
  else return null;
}

export enum ECatDocumentos {
  MINUTA = 'Minuta',
}

export const crearCodigo = (str: string) => {
  const mapaCaracteres = {
    'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u', 'ü': 'u',
    'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U', 'Ü': 'U',
    'ñ': 'n', 'Ñ': 'N'
  };

  return str
    .toUpperCase() // Convertir a mayúsculas
    .replace(/[áéíóúüñÁÉÍÓÚÜÑ]/g, match => mapaCaracteres[match]) // Reemplazar tildes y ñ
    .replace(/\s+/g, '_'); // Reemplazar espacios con guiones bajos
}
