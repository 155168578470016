import React, {createContext, useContext, useEffect, useState} from 'react';
import {EGroups, EGroupsNames} from '../utilities';
import {
  asignarUsuario,
  cambiarEstado,
  guardarJuridico,
  guardarNovedad,
  obtenerDocumentos,
  obtenerNovedad,
  obtenerNovedades,
  obtenerProceso,
  obtenerTareas,
  obtenerUsuario,
  obtenerUsuariosJuridico
} from '../../core/services/juridico/juridicoService';
import {mainContext} from './mainContext';
import {falseLoading, trueLoading} from './actions/mainActions';
import {IUsuarios} from '../../core/models/usuarios/usuariosModel';
import {obtenerPerfiles, obtenerPerfilesByCode} from '../../core/services/usuarios/usuariosService';
import {obtenerAreas} from '../../core/services/administracion/areasService';
import {userContext} from './userContext';

export const juridicoContext = createContext<any>({});

export const JuridicoProvider = ({ children }) => {

  // Context
  const { mainDispatch, handleNotification } = useContext(mainContext);
  const { userDispatch } = useContext(userContext);

  // State
  const [id, setId] = useState<number>(0);
  const [proceso, setProceso] = useState<any>(undefined);
  const [loadedJuridico, setLoadedJuridico] = useState<boolean>(false);
  const [usuarios, setUsuarios] = useState<any>(null);
  const [perfiles, setPerfiles] = useState<any>(undefined);
  const [documentos, setDocumentos] = useState<any>(null);
  const [tareas, setTareas] = useState<any>(null);
  const [usuariosAbogados, setUsuariosAbogados] = useState<IUsuarios[]>([]);
  const [usuariosAbogadosExternos, setUsuariosAbogadosExternos] = useState<IUsuarios[]>([]);
  const [idNovedad, setIdNovedad] = useState<number>(0);
  const [novedad, setNovedad] = useState<any>(null);
  const [documentosNovedad, setDocumentosNovedad] = useState<any>(null);
  const [novedades, setNovedades] = useState<any[]>([]);
  const [idArea, setIdArea] = useState<number>(0);
  const [area, setArea] = useState<any>(null);
  const [areas, setAreas] = useState<any>(null);

  // GET RECORD
  const getJuridico = async (id: number = 0) => {

    //
    setId(id);

    // Proceso
    const proceso = await obtenerProceso(id);
    setProceso(proceso);

    // Usuarios
    const usuarios = await obtenerUsuariosJuridico('idjuridico', id)
    setUsuarios(usuarios);

    // Documentos
    const documentos = await obtenerDocumentos({ filter: 'idjuridico', valuefilter: id });
    setDocumentos(documentos);

    // Documentos
    const tareas = await obtenerTareas({ filter: 'idjuridico', valuefilter: id });
    setTareas(tareas);

    setLoadedJuridico(true);
  }

  // RESET RECORD
  const resetJuridico = () => {
    setId(0);
    setProceso(undefined);
    setUsuarios(null);
    setIdNovedad(0);
    setNovedad(null);
    setDocumentos(null);
    setTareas(null);
    setDocumentosNovedad(null);
    setLoadedJuridico(true);
  }

  // GET RECORD
  const getNovedad = async () => {

    // Proceso
    const novedad = await obtenerNovedad(idNovedad);
    setNovedad(novedad);

    // Documentos
    const documentos = await obtenerDocumentos({ filter: 'idnovedad', valuefilter: idNovedad });
    setDocumentosNovedad(documentos);

    setLoadedJuridico(true);
  }

  // GET RECORD
  const getArea = async () => {

    // Proceso
    const area = await obtenerUsuario(idArea);
    setArea(area);

    setLoadedJuridico(true);
  }

  // RESET RECORD
  const resetNovedad = () => {
    setNovedad(0);
    setDocumentosNovedad(null);
  }

  // GET LIST
  const listarUsuarios = async () => {
    const usuarios = await obtenerUsuariosJuridico('idjuridico', id)
    setUsuarios(usuarios);
  }

  // GET LIST
  const listarNovedades = async () => {
    setNovedades(await obtenerNovedades({ filter: 'idjuridico', valuefilter: id }));
  };

  // GET LIST
  const listarAreas = async () => {
    if (areas == null) {
      setAreas(await obtenerAreas(userDispatch, handleNotification));
    }
  };

  // UPDATE STATE
  const changeState = async (action: 'prev' | 'next' = 'next') => {
    mainDispatch(trueLoading());
    const data = {
      action,
      id
    };
    const response = await cambiarEstado(data, handleNotification, true, true);
    if (response.data?.status == "success") {
      getJuridico(id);
    }
    mainDispatch(falseLoading());
  };

  // UPDATE/CREATE RECORD
  const guardarProcesoJuridico = async (values: any, file = false, resetForm) => {
    mainDispatch(trueLoading());
    let response;
    if (response = await guardarJuridico(values, handleNotification, file, true, true)) {
      if (id) {
        if (response?.data?.status == 'success') {
          await getJuridico(id);
          await resetForm();
        }
      }
    }
    mainDispatch(falseLoading());
    return response;
  };

  // UPDATE/CREATE RECORD
  const guardarProcesoNovedad = async (values: any, file = false, resetForm) => {
    mainDispatch(trueLoading());
    let response;
    if (response = await guardarNovedad(values, handleNotification, file, true, true)) {
      if (response?.data?.status == 'success') {
        if (idNovedad) {
          await getNovedad();
        }
        await resetForm();
      }
    }
    mainDispatch(falseLoading());
    return response;
  };

  // UPDATE/CREATE RECORD
  const guardarUsuario = async (values: any, file = false, resetForm) => {
    mainDispatch(trueLoading());
    let response;
    if (response = await asignarUsuario(values, handleNotification, file, true, true)) {
      if (response?.data?.status == 'success') {
        await getJuridico(id);
        await resetForm();
      }
    }
    mainDispatch(falseLoading());
    return response;
  };

  const listarPerfilesUsuarios = async () => {
    const perfiles = await obtenerPerfiles();
    setPerfiles(perfiles);
    const abogados = await obtenerPerfilesByCode(EGroupsNames.ABOGADO);
    if (abogados.length > 0) {
      setUsuariosAbogados(abogados);
    }
    let abogadosExternos = await obtenerPerfilesByCode(EGroupsNames.ABOGADO_EXTERNO);
    if (abogadosExternos.length > 0) {
      setUsuariosAbogadosExternos(abogadosExternos);
    }
  };

  useEffect(() => {
    if (proceso !== undefined) {
      if (idNovedad) {
        getNovedad();
      } else {
        resetNovedad();
      }
    }
  }, [idNovedad]);

  useEffect(() => {
    if (proceso !== undefined) {
      if (idArea) {
        getArea();
      } else {
        setArea(null);
      }
    }
  }, [idArea]);

  return (
    <juridicoContext.Provider
      value={{
        setId,
        setProceso,
        proceso,
        documentos,
        tareas,
        usuarios,
        perfiles,
        loadedJuridico,
        getJuridico,
        changeState,
        guardarProcesoJuridico,
        guardarProcesoNovedad,
        idNovedad,
        setIdNovedad,
        novedad,
        setNovedad,
        novedades,
        listarNovedades,
        documentosNovedad,
        usuariosAbogados,
        usuariosAbogadosExternos,
        listarPerfilesUsuarios,
        setDocumentos,
        setTareas,
        setDocumentosNovedad,
        resetJuridico,
        setLoadedJuridico,
        guardarUsuario,
        idArea,
        setIdArea,
        area,
        setArea,
        areas,
        listarAreas,
        listarUsuarios
      }}
    >
      {children}
    </juridicoContext.Provider>
  );
};

export const useJuridicoContext = () => {
  const context = React.useContext(juridicoContext)
  if (context === undefined) {
    throw new Error('useJuridicoState must be used within a JuridicoProvider');
  }
  return context
};
