import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { ButtonGroup, Button } from 'reactstrap';
import Dropzone from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useMainContext } from '../contexts/mainContext';
import { mainTypeMsj } from '../contexts/interfaces/mainInterfaces';
import dev from '../../core/enviroments';

const FilesUpload: FC<any> = ({ name, disabled = false, accept = 'application/pdf,.doc,.docx', categoria, cant, ...props }) => {
  const { handleNotification } = useMainContext();
  const { values, setFieldValue, getFieldProps } = useFormikContext<any>();
  const { t } = useTranslation();

  const handleDelete = (id) => {
    setFieldValue('deletefiles', [
      ...values.deletefiles,
      id
    ]);
    const fieldName = categoria ? `${name}[${categoria}]` : name;
    setFieldValue(fieldName, valueIteration?.filter(item => item?.id != id))
  }

  const valueForm = getFieldProps(name)?.value;
  const valueIteration = (categoria) ? valueForm[categoria] : valueForm;
  return (
    <div>
      {valueIteration?.map((file, i) => {
        if (file?.id) {
          if (values.deletefiles?.includes(file?.id)) {
            return;
          }
          return (
            <ButtonGroup style={{ marginRight: 5, marginBottom: 5 }} key={uuidv4()}>
              <Button type="button">{file['nombrereal']}</Button>
              <Button type="button" onClick={() => {
                const version = file?.version ? `v-${file['version']}-` : '';
                const extension = file?.extension == 2 ? `pdf` : file['extension'];
                const path = file['path'].startsWith('/') ? file['path'] : "/" + file['path'];
                window.open(
                  `${dev.URL_SERVER}/uploads${path}/${version}${file['nombre']}.${extension}`,
                  '_blank'
                )
              }}>
                <i className="uil-eye"></i>
              </Button>
              {!disabled &&
                <Button type="button" onClick={() => handleDelete(file?.id)}>
                  <i className="uil-trash-alt"></i>
                </Button>
              }
            </ButtonGroup>
          );
        }
        return (
          <ButtonGroup style={{ marginRight: 5, marginBottom: 5 }} key={uuidv4()}>
            <Button color="primary" type="button">{file?.name}</Button>
            {!disabled &&
              <Button color="primary" type="button" onClick={() => {
                const fieldName = categoria ? `${name}[${categoria}]` : name;
                setFieldValue(fieldName, valueIteration?.filter((item, index) => index != i));
              }}>
                <i className="uil-trash-alt"></i>
              </Button>
            }
          </ButtonGroup>
        )
      })}
      <Dropzone accept={accept} disabled={disabled} onDrop={(acceptedFiles) => {
        if (acceptedFiles.length === 0) { return; }
        if (cant && acceptedFiles.length > cant) {
          handleNotification(`${t('Se permite solo')} ${cant} ${t('archivo(s) en este campo')}`, mainTypeMsj.ERROR);
          return;
        }
        let files: any[] = [];

        // Varificamos si existe una categoria
        if (categoria) {
          // Tomamos los archivos de la categoria
          const filesCat = valueForm[categoria] || [];
          if (cant && filesCat.length + acceptedFiles.length > cant) {
            handleNotification(`${t('Se permite solo')} ${cant} ${t('archivo(s) en este campo')}`, mainTypeMsj.ERROR);
            return;
          }

          // Creamos el nuevo valor del estado
          files = {
            ...valueForm,
            [categoria]: [
              ...filesCat,
              ...acceptedFiles
            ]
          }
        } else {
          if (cant && valueForm.length + acceptedFiles.length > cant) {
            handleNotification(`${t('Se permite solo')} ${cant} ${t('archivo(s) en este campo')}`, mainTypeMsj.ERROR);
            return;
          }
          // Creamos el nuevo valor del estado
          files = valueForm ? [
            ...valueForm,
            ...acceptedFiles
          ] : acceptedFiles;
        }
        setFieldValue(name, files);
      }}>
        {({ getRootProps, getInputProps }) => (
          <section style={{ border: disabled ? '1px dashed #ced4da' : '1px dashed #acb3f8', color: disabled ? '#ced4da' : '#acb3f8', padding: 10, borderRadius: 5, cursor: disabled ? 'auto' : 'pointer' }}>
            <div {...getRootProps()}>
              <input {...getInputProps()} id={props?.id} />
              {t('Arrastre y suelte archivos')}
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  )
}

export default FilesUpload;
