import React from "react";
import {FC} from "react";
import MUIDataTable from "mui-datatables";
import {useTranslation} from "react-i18next";
import {useListPendientesPostContractual} from "./useListPendientesPostcontractual";
import {ITerceros} from "../../../../core/models/administracion/tercerosModel";
import {IEstado} from "../../../../core/models/administracion/estadosModel";
import {formatStringtoDateString} from "../../../../shared/helpers/Fecha";
import {optionsDataTable} from "../../../../shared/utilities";
import {ITiposContrato} from "../../../../core/models/administracion/tiposContratoModel";

const PendientePostContractual: FC<any> = () => {

  const {t} = useTranslation();

  const {
    postcontractuales,
    columns,
    datatable,
    changePage,
    handleSearch
  } = useListPendientesPostContractual();

  const {count, length, page, searchText, filterList} = datatable;

  const options = {
    ...optionsDataTable,
    filter: false,
    count,
    rowsPerPage: length,
    page,
    rowsPerPageOptions: [],
    viewColumns: false,
    serverSide: true,
    sort: false,
    searchText,
    caseSensitive: false,
    onSearchChange(searchText) {
      handleSearch(searchText);
    },
    onChangePage(currentPage) {
      changePage(currentPage);
    },
  };

  return (
    <>
      <div style={{marginBottom: 45}}>
        <MUIDataTable
          title={(<h5>{t('Postcontractuales')}</h5>)}
          data={postcontractuales.map(proceso => ([
            [proceso.consecutivo, proceso.id],
            (proceso.idproveedorcliente as ITerceros).razonsocial,
            (proceso.idtipocontrato as ITiposContrato)?.nombre,
            (proceso.idestados as IEstado).nombre,
            formatStringtoDateString(proceso?.inicio),
            proceso?.final ? formatStringtoDateString(proceso?.final) : '',
          ]))}
          columns={columns}
          options={options}
        />
      </div>
    </>
  );
}

export default PendientePostContractual;
