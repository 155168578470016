import React, { FC, useEffect, useMemo, useState } from 'react';
import { IProveedores } from '../../../../../core/models/precontractual/proveedoresModel';
import { usePrecontractualContext } from '../../../../../shared/contexts/precontractualContext';
import TablaCalendario from '../../../../precontractual/components/Tables/TablaCalendario';
import Desplegable from '../../../../../shared/components/Desplegable';
import { useTranslation } from 'react-i18next';
import TablaDocsRequeridos from '../../../../precontractual/components/Tables/TablaDocsRequeridos';
import { useMainContext } from '../../../../../shared/contexts/mainContext';
import { closeModal } from '../../../../../shared/contexts/actions/mainActions';
import TablaProducto from '../../../../precontractual/components/Tables/TablaProducto';
import TablaPreguntas from '../../../../precontractual/components/Tables/TablaPreguntas';
import { EEstadosPre } from '../../../../../shared/utilities';
import TablaOferta from '../../../../precontractual/components/Tables/TablaOferta';
import TablaDocsOferta from '../../../../precontractual/components/Tables/TablaDocsOferta';
import FormProveedor from '../../../../precontractual/components/Forms/FormProveedor';
import { PRE_ACTIVIDADES } from '../../../../../shared/helpers/precontractual';
import TablaDocsRenegociacion from '../../../../precontractual/components/Tables/TablaDocsRenegociacion';
import FormInfoBasicaPre from '../forms/FormInfoBasicaPre';

const ViewPreProveedores001: FC<IViewPreProveedores001> = ({ record }) => {
  const { t } = useTranslation();
  const { mainDispatch } = useMainContext();
  const { estado, calendario } = usePrecontractualContext();

  const [habilitarFechas, setHabilitarFechas] = useState(false);

  const habilitarOfertas = useMemo(() => {
    if (estado === EEstadosPre.CARGUE_PROPUESTAS) {
      return true;
    }
    return false;
  }, [estado]);

  useEffect(() => {
    const actOfertas = calendario.find(item => item.tipoactividad === PRE_ACTIVIDADES.CARGUE_PROPUESTAS);

    if (actOfertas?.inicio && actOfertas?.fin) {
      const inicio = new Date(actOfertas.inicio);
      const fin = new Date(actOfertas.fin);
  
      const timerID = setInterval(() => {
        const ahora = new Date();
        if (ahora >= inicio && ahora <= fin) {
          setHabilitarFechas(true);
        } else {
          setHabilitarFechas(false);
          if (estado === EEstadosPre.CARGUE_PROPUESTAS) {
            mainDispatch(closeModal());
          }
        }
  
        if (ahora.getTime() >= fin.getTime()) {
          clearInterval(timerID);
        }
      }, 1000);
  
      return () => {
        clearInterval(timerID);
      };
    }
  }, [calendario, estado]);

  return (
    <>
      <FormInfoBasicaPre proveedor={record} botonActualizar />
      <Desplegable
        title={t('Cronograma de Trabajo')}
        open={estado === EEstadosPre.CARGUE_RENEGOCIACIONES}
      >
        <TablaCalendario />
      </Desplegable>
      {Number(estado) >= EEstadosPre.CARGUE_RENEGOCIACIONES && Number(estado) <= EEstadosPre.SELECCION_EVALUADORES && (
        <>
          <Desplegable
            title={t('Documentos de la Renegociación')}
            open={estado === EEstadosPre.CARGUE_RENEGOCIACIONES}
          >
            <TablaDocsRenegociacion record={record} />
          </Desplegable>
          <Desplegable
            title={t('Renegocicación')}
            open={estado === EEstadosPre.CARGUE_RENEGOCIACIONES}
          >
            <FormProveedor record={record} negociaciones />
          </Desplegable>
        </>
      )}
      <Desplegable title={t('Documentos Anexos')} open={false}>
        <TablaDocsRequeridos tipo={1} />
      </Desplegable>
      <Desplegable
        title={t('Preguntas y Respuestas')}
        open={estado === EEstadosPre.PREGUNTAS || estado === EEstadosPre.RESPUESTAS}
      >
        <TablaPreguntas tipo={1} idpreproveedor={record?.id ?? 0} />
      </Desplegable>
      {!!calendario.length && (habilitarOfertas || Number(estado) >= EEstadosPre.CARGUE_PROPUESTAS) && (
        <>
          <Desplegable title={t('Listado Ítems')} open={habilitarOfertas}>
            <TablaProducto />
          </Desplegable>
          <Desplegable title={t('Valor Oferta')} open>
            <TablaOferta
              idpreproveedor={record?.id ?? 0}
              habilitar={habilitarOfertas && habilitarFechas}
            />
          </Desplegable>
          <Desplegable title={t('Documentos Anexos')}>
            <TablaDocsOferta
              tipo={1}
              idpreproveedor={record?.id ?? 0}
              habilitar={habilitarOfertas && habilitarFechas}
            />
          </Desplegable>
        </>
      )}
    </>
  )
}

interface IViewPreProveedores001 {
  record: IProveedores;
}

export default ViewPreProveedores001;
