import React, {FC, useMemo} from 'react';
import moment from 'moment';
import MUIDataTable, {MUIDataTableColumn} from 'mui-datatables';
import {Button} from 'reactstrap';
import {openModal} from '../../../../../shared/contexts/actions/mainActions';
import {IManual, IFormManual} from '../../../../../core/models/administracion/manualesModel';
import FormManual from './FormManual';
import {useMainContext} from '../../../../../shared/contexts/mainContext';
import {useOptionsTables} from '../../../../../shared/hooks/useOptionsTables';
import {useTranslation} from 'react-i18next';
import {formatStringtoDateString} from '../../../../../shared/helpers/Fecha';
import {EGroupsNames} from "../../../../../shared/utilities";

const ListManuales: FC<IListManuales> = ({columns, manuales, onSubmit, isAdmin = false}) => {
  // contextos
  const {mainDispatch} = useMainContext();
  const {optionEstadoSearch} = useOptionsTables();
  const {t} = useTranslation();

  return (
    <MUIDataTable
      title={
        isAdmin && <Button
          color="success"
          className="mr-1 mb-1"
          onClick={() => mainDispatch(openModal({
            modal: {
              show: true,
              title: t('Subir nuevo manual'),
              body: () => (
                <FormManual
                  onSubmit={onSubmit}
                />
              )
            }
          }))}
        >
          <i className="uil-plus-circle"></i> {t('Crear')}
        </Button>
      }
      data={manuales.map(tipo => ([
        tipo.nombre,
        tipo.nombrereal,
        formatStringtoDateString(tipo?.creado),
        tipo.activo,
        tipo.id,
      ]))}
      columns={columns}
      options={optionEstadoSearch(3)}
    />
  );
}

interface IListManuales {
  columns: MUIDataTableColumn[],
  manuales: IManual[],
  onSubmit: (values: IFormManual) => void,
  isAdmin?: boolean
};
export default ListManuales;
