import React from 'react';
import CurrencyFormat from 'react-currency-format';

const Money = ({value}) => {
  return (
    <CurrencyFormat value={value} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} />
  );
}

export default Money;

export const abreviarPrecio = (value: number): string => {
  const suffixes = ['k', 'M', 'B', 'T'];
  let newValue = value;
  let suffixIndex = -1;

  while (newValue >= 1000 && suffixIndex < suffixes.length - 1) {
    newValue /= 1000;
    suffixIndex++;
  }

  // Limita la cantidad de decimales a 1 para mantener el formato compacto
  newValue = Math.round(newValue * 10) / 10;

  // Añade el sufijo adecuado o devuelve el valor original si es menor que 1000
  const formattedValue = suffixIndex === -1 ? value.toString() : `${newValue}${suffixes[suffixIndex]}`;
  return `$${formattedValue}`;
}
