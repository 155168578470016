import React, {FC, useState} from "react";
import ListContratos from "./ListContratos";
import {Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import {useTranslation} from "react-i18next";

const ContratosPorProcesos: FC<IContratosPorAreas> = ({modulo, area, estado, handleLink}) => {
  const {t} = useTranslation();

  const [tab, setTab] = useState<string>('');
  const [tabModule, setTabModule] = useState<string>('Precontractual');

  const toggleTabModule = (tabSelected: string) => {
    if (tab !== tabSelected) {
      setTabModule(tabSelected);
    }
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <div className="tab">
            <Nav tabs style={{ background: 'aliceblue' }}>
              <NavItem>
                <NavLink className={`cursor-pointer ${classnames({active: tabModule === 'Precontractual'})}`}
                         onClick={() => toggleTabModule('Precontractual')}>
                  {t('Precontractual')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={`cursor-pointer ${classnames({active: tabModule === 'Contractual'})}`}
                         onClick={() => toggleTabModule('Contractual')}>
                  {t('Contractual')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={`cursor-pointer ${classnames({active: tabModule === 'Postcontractual'})}`}
                         onClick={() => toggleTabModule('Postcontractual')}>
                  {t('Postcontractual')}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={tabModule}>
              <TabPane tabId="Precontractual">
                <hr/>
                <Row>
                  <Col md={12}>
                    <ListContratos
                      modulo={"Precontractual"}
                      area={area}
                      handleLink={handleLink}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="Contractual">
                <hr/>
                <Row>
                  <Col md={12}>
                    <ListContratos
                      modulo={"Contractual"}
                      area={area}
                      handleLink={handleLink}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="Postcontractual">
                <hr/>
                <Row>
                  <Col md={12}>
                    <ListContratos
                      modulo={"Postcontractual"}
                      area={area}
                      handleLink={handleLink}
                    />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>
        </Col>
      </Row>
    </>
  );
}

interface IContratosPorAreas {
  modulo: string,
  area: string,
  estado: string,
  handleLink: (id: number, modulo: string) => void
}

export default ContratosPorProcesos;
