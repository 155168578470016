import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import React from 'react'
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useOptionsTables = () => {
  const { t, i18n } = useTranslation();

  const lang = useMemo(() => i18n.language, [i18n]);

  // data
  const optionsDataTable = (values?: any): MUIDataTableOptions => {
    return {
      filter: true,
      viewColumns: true,
      filterType: "dropdown",
      responsive: "standard",
      selectableRows: "none",
      download: false,
      elevation: 0,
      sort: true,
      print: false,
      textLabels: {
        body: {
          noMatch: t('Lo sentimos, no se han encontrado datos.'),
          toolTip: t('Organizar'),
        },
        pagination: {
          next: t('Página siguiente'),
          previous: t('Página anterior'),
          rowsPerPage: t('Columnas por página:'),
          displayRows: t('de'),
        },
        toolbar: {
          search: t('Buscar'),
          print: t('Imprimir'),
          viewColumns: t('Ver columnas'),
          filterTable: t('Filtrar tabla'),
        },
        filter: {
          all: t('Todos'),
          title: t('FILTROS'),
          reset: t('LIMPIAR'),
        },
        viewColumns: {
          title: t('Mostar columnas'),
          titleAria: t('Mostrar/Ocultar columnas de la tabla'),
        },
        selectedRows: {
          text: t('fila(s) seleccionadas'),
          delete: t('Borrar'),
          deleteAria: t('Borrar filas seleccionadas'),
        },
      },
      customSearch: (searchQuery, currentRow) => {
        const procesaTexto = (text) => {
          return text?.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        }
        let isFound = false;
        currentRow.map(col => {
          if (procesaTexto(col)?.includes(procesaTexto(searchQuery))) {
            isFound = true;
          }
        });
        return isFound;
      },
      ...values,
    }
  };

  const optionEstadoSearch = (indexSearch: number): MUIDataTableOptions => {
    return {
      ...optionsDataTable(),
      customSearch: (searchQuery, currentRow) => {
        const rexHab = lang === 'es' ? /^(h)(a|$)(b|$)(i|$)(l|$)(i|$)(t|$)(a|$)(d|$)(o|$)/ : /^(e)(n|$)(a|$)(b|$)(l|$)(e|$)(d|$)/;
        const rexDes = lang === 'es' ? /^(d)(e|$)(s|$)(h|$)(a|$)(b|$)(i|$)(l|$)(i|$)(t|$)(a|$)(d|$)(o|$)/ : /^(d)(i|$)(s|$)(a|$)(b|$)(l|$)(e|$)(d|$)/;
        let isFound = false;
        const procesaTexto = (text) => {
          return text?.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        }
        currentRow.forEach(col => {
          if (col?.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }

          if (rexHab.test(searchQuery.toLowerCase()) && (currentRow[indexSearch] === 1)) {
            isFound = true;
          }

          if (rexDes.test(searchQuery.toLowerCase()) && (currentRow[indexSearch] !== 1)) {
            isFound = true;
          }

          if (procesaTexto(col)?.includes(procesaTexto(searchQuery))) {
            isFound = true;
          }
        });
        return isFound;
      },
    }
  };

  const estadoOption: MUIDataTableColumn = {
    name: t('Estado'),
    options: {
      filter: true,
      filterOptions: {
        names: [t('Habilitado'), t('Deshabilitado')],
        logic(activo, filters) {
          if (filters[0] == t('Deshabilitado')) {
            return parseInt(activo) != 0;
          } else if (filters[0] = t('Habilitado')) {
            return parseInt(activo) != 1;
          }
          return false;
        },
      },
      sort: true,
      customHeadLabelRender: () => (
        <h5>Estado</h5>
      ),
      customBodyRender: (activo) => {
        return (
          <span className={activo ? 'mr-1 mb-1 badge badge-success' : 'mr-1 mb-1 badge badge-danger'}>
            {activo ? t('Habilitado') : t('Deshabilitado')}
          </span>
        );
      }
    }
  };

  return {
    optionEstadoSearch,
    optionsDataTable,
    estadoOption
  };
};
