import React, { FC, useState } from 'react';
import OTPInput from 'otp-input-react';
import { FormGroup, FormFeedback, Label, Alert, Form } from 'reactstrap';
import ButtonCustom from '../../../shared/components/ButtonCustom';
import { FormValidation } from '../../../shared/utilities';
import { usePolizaContext } from '../../../shared/contexts/polizaContext';
import { useTranslation } from 'react-i18next';
import dev from '../../../core/enviroments';
import { useMainContext } from '../../../shared/contexts/mainContext';
import { mostrarCodigosPolizas } from '../../../core/services/polizas/codigosPolizasService';
import { useParams } from 'react-router-dom';
import { falseLoading, trueLoading } from '../../../shared/contexts/actions/mainActions';

const OTPForm: FC = () => {
  const { token } = useParams<{ token: string }>();
  const { mainState, mainDispatch, handleNotification } = useMainContext();
  const { base, activeStep, setActiveStep, setCodigoPoliza } = usePolizaContext();
  const { t } = useTranslation();

  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    if (otp.length < 5) {
      setError(FormValidation.required);
    } else {
      mainDispatch(trueLoading());
      const consultaCodigo = await mostrarCodigosPolizas(otp, token, handleNotification);
      if (consultaCodigo) {
        setCodigoPoliza(otp);
        setActiveStep(activeStep + 1);
      }
      mainDispatch(falseLoading());
    }
  }

  return (
    <>
      <h4>{t("Código de confirmación")}</h4>
      <Alert color="info" className="mb-0">
        {t("Por favor ingrese el código de confirmación de 5 dígitos que hemos enviado a su correo electrónico.")}
      </Alert>
      <hr />
      <Form id="OTPForm" onSubmit={handleSubmit}>
        <div className="firma-form">
          <FormGroup style={{ textAlign: 'center' }}>
            <Label>{t("Código")}</Label>
            <OTPInput
              value={otp}
              onChange={setOtp}
              autoFocus
              OTPLength={5}
              otpType="number"
              disabled={false}
              inputClassName="form-control"
              className={error ? "otp-field is-invalid" : "otp-field"}
            />
            <FormFeedback>{t(error)}</FormFeedback>
          </FormGroup>
        </div>
        <hr />
        <ButtonCustom
          form="OTPForm"
          className="d-flex align-items-center"
          type="submit"
          color="primary"
          loading={mainState.loading}
          disabled={mainState.loading}
          style={{ float: "right" }}
        >
          {t("Continuar")}
        </ButtonCustom>
      </Form>
    </>
  );
}

export default OTPForm;
