import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {Alert, Card, CardBody, Col, PopoverBody, Row, Spinner, UncontrolledPopover} from 'reactstrap';
import { useMainContext } from '../../shared/contexts/mainContext';
import { changeHeader } from '../../shared/contexts/actions/mainActions';
import { mainTypeHeader } from '../../shared/contexts/interfaces/mainInterfaces';
import { useParams } from 'react-router-dom';
import DatosPolizasForm from "../polizas/components/DatosPolizasForm";
import OTPPolizasForm from "../polizas/components/OTPPolizasForm";
import FormPoliza from "../../pages/polizas/components/FormPoliza";
import PrevisualizarForm from "../documentos/components/PrevisualizarForm";
import Steplineal from "./Steplienal";
import {usePolizaContext} from "../../shared/contexts/polizaContext"
import {obtenerPoliToken, obtenerPolizas} from "../../core/services/polizas/polizasService";
import {obtenerRequerimientos} from "../../core/services/procesos/polizaGarantiasService";
import BasePoliza from "./index";

const TokenPolizasTercero: FC = () => {
  const { token } = useParams<{ token: string }>();
  const { t, i18n: { language } } = useTranslation();
  const { mainDispatch } = useMainContext();
  const { codigoPoliza, base, setBase, setCodigoPoliza, loading,setLoading, obtenerPoliza } = usePolizaContext();

  const obtenerInfoPolizas = async () => {
    const poliToken = await obtenerPoliToken(token);
    if(poliToken){
      let base = poliToken.base;
      console.log("PoliToken: ", poliToken);
      console.log("Base: ", base);
      setBase(base);
      setLoading(false);
    }
  }

  const listarPolizas = async (id) => {
    const params = {
      filter: 'base',
      valuefilter: id
    };
    const resp = await obtenerPolizas(params);
  };

  const steps = [
    {
      id: 1,
      label: t('Validar datos'),
      enabled: !codigoPoliza && base?.estado === 1,
      show: true
    },
    {
      id: 2,
      label: t('Código de acceso'),
      enabled: !codigoPoliza,
      show: true
    },
    {
      id: 3,
      label: t('Carga de pólizas'),
      enabled: !!codigoPoliza,
      show: base?.estado === 1
    },
  ];

  useEffect(() => {
    mainDispatch(changeHeader({
      header: {
        title: t('Carga de pólizas'),
        type: mainTypeHeader.SUBTITLE
      }
    }));
  }, [language]);

  useEffect(() => {
    obtenerInfoPolizas();
  }, [token]);

  const getStepContent = (step: number) => {
    switch (step) {
      case 1:
        return <DatosPolizasForm />
      case 2:
        return <OTPPolizasForm />
      case 3:
        return <BasePoliza modulo={base?.modulo?.nombre} isToken={true} token={token} />
    }
  }

  return (
    <Card>
      <CardBody>
        {loading ? (
          <div className="d-flex flex-column justify-content-center text-center">
            <Spinner color="primary" className="m-auto" />
            <span className="mt-3">
              {t('Cargando pólizas...')}
            </span>
          </div>
        ) : null}
        {(base ? (
          <>
            {base.estado === 2 && (
              <>
                <Alert color="success" className="mt-4">
                  {t('Las pólizas fueron cargadas correctamente. Puede cerrar esta ventana.')}
                </Alert>
              </>
            )}
            {base.estado === 1 && (
              <Steplineal
                getStepContent={getStepContent}
                steps={steps}
                defaultStep={1}
              />
            )}
          </>
        ) : (!loading && (
          <Alert color="info" className="mt-4">
            {t('Este enlace ya no se encuentra disponible o el token proporcionado es inválido.')}
          </Alert>
        )))}
      </CardBody>
    </Card>
  )
}

export default TokenPolizasTercero;
