import React, {useContext, useEffect, useState} from 'react';
import {MUIDataTableColumn} from 'mui-datatables';
import {useTranslation} from 'react-i18next';
import {
  useObtenerProcesosDinamicos
} from "../../../../../core/services/usuarios/usuariosService";
import {closeModal} from "../../../../../shared/contexts/actions/mainActions";
import {useHistory} from "react-router-dom";
import {mainContext} from "../../../../../shared/contexts";
import {Button} from "reactstrap";

const initialState = {
  loading: false,
  length: 10,
  columns: [],
  order: [],
  start: 0,
  count: 0,
  page: 0,
}

let timeSearch: any;

export const useListContratos = ({modulo, estado, area, handleLink}) => {

  const {t} = useTranslation();

  const [datatable, setDatatable] = useState<any>(initialState);
  const [procesos, setProcesos] = useState<any[]>([]);
  const {handleGetContratos} = useObtenerProcesosDinamicos();
  /**
   * Obtiene los procesos guardados en la bd
   */
  const listarContratos = async () => {
    setDatatable({
      ...initialState,
      loading: true,
    });
    const {data = {}} = await handleGetContratos({
      ...initialState,
      order: undefined,
      count: undefined,
      loading: undefined,
      page: undefined,
      modulo: modulo,
      area: area,
      estado: estado,
    });
    const {data: records = [], recordsTotal = 0, page} = data;
    setDatatable({
      ...initialState,
      page: page - 1,
      count: recordsTotal,
      loading: false,
    });
    setProcesos(records);
  };

  const changePage = async (pag) => {

    setDatatable({
      ...datatable,
      loading: true
    });

    const {data = {}} = await handleGetContratos({
      ...datatable,
      order:
        datatable?.order.length > 0 ? datatable?.order.length : undefined,
      count: undefined,
      loading: undefined,
      page: undefined,
      start: datatable?.length * pag,
      modulo: modulo,
      area: area,
      estado: estado,
    });
    const {data: records = [], recordsTotal = 0, page = 0} = data;
    setDatatable({
      ...datatable,
      count: recordsTotal,
      page: page - 1,
      loading: false,
    });
    setProcesos(records);

  };

  useEffect(() => {
    listarContratos();
  }, []);

  const columns: MUIDataTableColumn[] = [
    {
      name: t('Consecutivo'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Consecutivo')}</h5>
        ),
        customBodyRender: ([consecutivo, id]) => {
          return (
            <Button color="default" onClick={() => handleLink(id, modulo)}>{consecutivo}</Button>
          )
        }
      }
    },
    {
      name: t('Área'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Área')}</h5>
        ),
      }
    },
    {
      name: t('Estado'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Estado')}</h5>
        ),
      }
    },
  ];

  return {
    procesos,
    columns,
    datatable,
    changePage
  }
};
