import React, { FC, useContext, useState } from 'react';
import { Field, useFormik, FormikProvider } from 'formik';
import { FormGroup, FormFeedback, Row, Col, Label, Input, Alert, Form } from 'reactstrap';
import { mainContext } from '../../../shared/contexts';
import ButtonCustom from '../../../shared/components/ButtonCustom';
import * as Yup from 'yup';
import { useParams } from 'react-router';
import { FormValidation } from '../../../shared/utilities';
import { usePolizaContext } from '../../../shared/contexts/polizaContext';
import { useTranslation } from 'react-i18next';
import dev from '../../../core/enviroments';
import { IFormCodigosPolizas } from '../../../core/models/polizas/codigosPolizasModel';
import { useMainContext } from '../../../shared/contexts/mainContext';

const DatosForm: FC = () => {
  const { token } = useParams<{ token: string }>();
  const { mainState } = useMainContext();
  const { activeStep, setActiveStep, handleCrearCodigoPoliza } = usePolizaContext();
  const { t } = useTranslation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: null,
      email: '',
      nombres: '',
      apellidos: '',
      documento: '',
      token,
      activo: 1,
    },
    validationSchema: Yup.lazy((values: IFormCodigosPolizas): Yup.Lazy => Yup.object().shape({
      nombres: Yup.string().required(t(FormValidation.required)),
      apellidos: Yup.string().nullable().required(t(FormValidation.required)),
      documento: Yup.string().required(t(FormValidation.required)),
      email: Yup.string().email(t(FormValidation.email)).required(t(FormValidation.required)),
      token: Yup.string().required(t(FormValidation.required)),
    })),
    onSubmit: async (values: IFormCodigosPolizas, { resetForm }) => {
      const crear = await handleCrearCodigoPoliza(values);
      if (crear) {
        //resetForm();
        setActiveStep(activeStep + 1);
      }
    }
  });

  const { errors, touched, handleSubmit } = formik;

  return (
    <>
      <h4>{t('Validación de datos')}</h4>
      <Alert color="info" className="mb-0">
        {t('Por favor ingrese los siguientes datos para realizar el proceso de validación de identidad.')}
      </Alert>
      <hr />
      <Form id="DatosForm" onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <div className="firma-form">
            <Row>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label for="nombres">{t('Nombres')}</Label>
                  <Field
                    as={Input}
                    name="nombres"
                    placeholder={`${t('Ej.')} María José`}
                    invalid={errors.nombres && touched.nombres}
                  />
                  <FormFeedback>{errors.nombres}</FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label>{t('Apellidos')}</Label>
                  <Field
                    as={Input}
                    name="apellidos"
                    placeholder={`${t('Ej.')} Rodríguez Pérez`}
                    invalid={errors.apellidos && touched.apellidos}
                  />
                  <FormFeedback>{errors.apellidos}</FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label>{t('Número de documento')}</Label>
                  <Field
                    as={Input}
                    name="documento"
                    placeholder={`${t('Ej.')} 123456789`}
                    invalid={errors.documento && touched.documento}
                  />
                  <FormFeedback>{errors.documento}</FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label>{t('Correo electrónico')}</Label>
                  <Field
                    as={Input}
                    name="email"
                    placeholder={`${t('Ej.')} correo@example.com`}
                    invalid={errors.email && touched.email}
                  />
                  <FormFeedback>{errors.email}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <hr />
          <ButtonCustom
            className="d-flex align-items-center"
            type="button"
            color="link"
            loading={mainState.loading}
            disabled={mainState.loading}
            style={{ float: 'left' }}
            onClick={() => { setActiveStep(activeStep + 1)}}
          >
            {t('Ingresar un código de acceso')}
          </ButtonCustom>
          <ButtonCustom
            form="DatosForm"
            className="d-flex align-items-center"
            type="submit"
            color="primary"
            loading={mainState.loading}
            disabled={mainState.loading}
            style={{ float: 'right' }}
          >
            {t('Validar datos')}
          </ButtonCustom>
        </FormikProvider>
      </Form>
    </>
  )
}

export default DatosForm;
