import React, {FC, useEffect, useState} from "react";

const BoxLegends: FC<IBoxLegends> = ({legends, data, colors, setRender}) => {

  const [elementos, setElementos] = useState();

  const eventClickLegend = (label, index) => {
    if (elementos[index].estado == "activo") {
      elementos[index] = {
        ...elementos[index],
        "color": 'gray',
        "decoration": 'line-through',
        "estado": "borrado"
      }
      setElementos(elementos)
      for (let item = 0; item < data.length; item++) {
        renameKeyInArray(data, item, label, label + "_delete");
      }
    } else {
      elementos[index] = {
        ...elementos[index],
        "color": '#000000',
        "decoration": 'none',
        "estado": "activo"
      }
      setElementos(elementos)
      for (let item = 0; item < data.length; item++) {
        renameKeyInArray(data, item, label + "_delete", label);
      }
    }
    setRender(prevState => prevState + 1);
  }

  const renameKeyInArray = (
    arr: any[],
    index: number,
    oldKey: string,
    newKey: string
  ): any[] => {
    if (index < 0 || index >= arr.length) {
      console.error(`Index "${index}" is out of bounds.`);
      return arr;
    }

    const obj = arr[index];

    if (!(oldKey in obj)) {
      console.warn(`Key "${oldKey}" does not exist in the object at index ${index}.`);
      return arr;
    }

    obj[newKey] = obj[oldKey];
    delete obj[oldKey];

    return arr;
  }

  useEffect(() => {
    const elemento = legends.map(item => {
      return {
        "label": item,
        "color": '#000000',
        "decoration": 'none',
        "estado": "activo"
      }
    });
    setElementos(elemento)
  }, [legends]);

  useEffect(() => {
  }, [data]);

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
        {elementos?.map((key, index) => (
          <div key={key} style={{marginRight: '20px', display: 'flex', alignItems: 'center', cursor: "pointer"}}>
            <div style={{
              width: '10px',
              height: '10px',
              backgroundColor: colors[index],
              marginRight: '5px'
            }}></div>
            <span
              onClick={() => eventClickLegend(key.label, index)}
              style={{color: key.color, textDecoration: key.decoration}}
            >{key.label}</span>
          </div>
        ))}
      </div>
    </>
  );
}

interface IBoxLegends {
  data: any[],
  colors: string[],
  legends: string[],
  setRender: React.Dispatch<any>
}

export default BoxLegends;
