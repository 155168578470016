import { EGroups, EGroupsNames, ETipoSolicitud } from "../../shared/utilities";
export const listAreasContratos = [
  {
    value: 1,
    label: 'RRHH'
  },
  {
    value: 2,
    label: 'Operaciones'
  },
  {
    value: 3,
    label: 'Juridica'
  },
  {
    value: 4,
    label: 'Financiera'
  }
];

export const listGenero = [
  {
    id: 1,
    nombre: 'Femenino'
  },
  {
    id: 2,
    nombre: 'Masculino'
  }
];

export const listTipoTercero = [
  {
    id: 1,
    nombre: 'Proveedor'
  },
  {
    id: 2,
    nombre: 'Cliente'
  }
];

export const listRazonSocial = [
  {
    id: 1,
    nombre: 'Natural'
  },
  {
    id: 2,
    nombre: 'Jurídico'
  }
];

export const listTipoDocumento = [
  {
    id: 1,
    nombre: 'Cédula'
  },
  {
    id: 2,
    nombre: 'Cédula de extranjería'
  },
  {
    id: 3,
    nombre: 'Pasaporte'
  }
];

export const listCicloFacturacion = [
  {
    id: 1,
    nombre: 'Anual'
  },
  {
    id: 2,
    nombre: 'Bimestral'
  },
  {
    id: 3,
    nombre: 'Mensual'
  },
  {
    id: 4,
    nombre: 'Quincenal'
  },
  {
    id: 5,
    nombre: 'Semestral'
  },
  {
    id: 6,
    nombre: 'Trimestral'
  },
  {
    id: 7,
    nombre: 'Único'
  }
];

export const listTipoPago = [
  {
    id: 1,
    nombre: 'Anticipo'
  },
  {
    id: 2,
    nombre: 'Factura Avance'
  },
  {
    id: 3,
    nombre: 'Porcentaje según avance obra'
  },
  {
    id: 4,
    nombre: 'Fecha de vencimiento de factura'
  },
];


export const listRoles = [
  {
    value: EGroups.SUPERADMIN,
    label: 'Administrador Comforce'
  },
  {
    value: EGroups.ADMINISTRADOR,
    label: 'Administrador'
  },
  {
    value: EGroups.APROBADOR,
    label: 'Aprobador'
  },
  {
    value: EGroups.ABOGADO,
    label: 'Abogado'
  },
  {
    value: EGroups.FIRMANTE,
    label: 'Coordinador'
  },
  {
    value: EGroups.SOLICITANTE,
    label: 'Solicitante'
  },
  {
    value: EGroups.TERCERO,
    label: 'Tercero'
  },
  {
    value: EGroups.FIRMANTE,
    label: 'Firmante'
  },
];

export const listPlazoEjecucion = [
  {
    id: '1',
    nombre: 'Días Hábiles (L - V 8:00 a.m. a 5:00 p.m., no incluye festivos)',
  },
  {
    id: '2',
    nombre: 'Días Corrientes',
  },
  {
    id: '3',
    nombre: 'Horas Hábiles (L - V 8:00 a.m. a 5:00 p.m., no incluye festivos)',
  },
  {
    id: '4',
    nombre: 'Semana(s)',
  },
  {
    id: '5',
    nombre: 'Mes(es)',
  },
  {
    id: '6',
    nombre: 'Año(s)',
  }
];

export const listObjetoAdenda = [
  {
    value: 1,
    label: 'Valor'
  },
  {
    value: 2,
    label: 'Vigencia'
  },
  {
    value: 3,
    label: 'Valor y Vigencia'
  },
  {
    value: 4,
    label: 'Alcance'
  },
];

export const listMoneda = [
  {
    id: 1,
    nombre: 'Peso Colombiano',
    siglas: 'COP',
  },
  {
    id: 2,
    nombre: 'Dólar Estadounidense',
    siglas: 'USD',
  },
  {
    id: 3,
    nombre: 'Euro',
    siglas: 'EUR'
  }
];

export const listPlazos = [
  {
    id: 2,
    nombre: 'Día(s) Corriente(s)'
  },
  {
    id: 4,
    nombre: 'Mes(es)'
  },
  {
    id: 5,
    nombre: 'Año(s)'
  }
];

export const listDecision = [
  {
    id: 1,
    nombre: 'Si'
  },
  {
    id: 2,
    nombre: 'No'
  }
];

export const listCondicionPago = [
  {
    id: 1,
    nombre: 'Otra modalidad pago'
  },
  {
    id: 2,
    nombre: 'Por hitos de pago'
  },
  {
    id: 3,
    nombre: 'Según avance mensual'
  },
  {
    id: 4,
    nombre: 'Según avance por trabajos efectivamente desarrollados'
  },
  {
    id: 5,
    nombre: 'Valor fijo mensual'
  },
  {
    id: 6,
    nombre: 'Valor fijo mensual más valor variable'
  },
];


export const listRolesContractuales = [
  {
    id: 1,
    nombre: 'Solicitante'
  },
  {
    id: 2,
    nombre: 'Coordinador'
  },
  {
    id: 3,
    nombre: 'Abogado'
  },
  {
    id: 4,
    nombre: 'Aprobador'
  },
  {
    id: 5,
    nombre: 'Firmante'
  },
  {
    id: 6,
    nombre: 'Jefe de área'
  },
  {
    id: 15,
    nombre: 'Abogado externo'
  }
];

export const listRolesContraContractual = [
  {
    id: 1,
    nombre: 'Solicitante'
  },
  {
    id: 2,
    nombre: 'Coordinador'
  },
  {
    id: 3,
    nombre: 'Abogado'
  },
  {
    id: 4,
    nombre: 'Aprobador'
  },
  {
    id: 5,
    nombre: 'Firmante'
  },
];

export const listRolesAsesorias = [
  {
    id: 1,
    nombre: 'Solicitante'
  },
  {
    id: 2,
    nombre: 'Coordinador'
  },
  {
    id: 3,
    nombre: 'Abogado'
  },
];

export const listValor = [
  {
    id: 1,
    nombre: '% sin IVA'
  },
  {
    id: 3,
    nombre: '$ sin IVA'
  },
]

export const listNuevo = [
  {
    id: 1,
    nombre: 'Referente'
  },
  {
    id: 2,
    nombre: 'Solicitud'
  }
]

export const listSolicitud = [
  {
    id: 1,
    nombre: "Cartas de contratación directa",
    autogestion: false,
    complejidad: 1,
  },
  {
    id: 2,
    nombre: "Revisión de escrituras públicas",
    autogestion: false,
    complejidad: 1,
  },
  // {
  //   id: 3,
  //   nombre: "Conceptos relacionados con los temas que se han incluido en el listado de PYR frecuentes.",
  //   autogestion: false,
  //   complejidad: 2,
  // },
  {
    id: 4,
    nombre: "Revisión de documentos de contenido legal",
    autogestion: false,
    complejidad: 2,
  },
  {
    id: 5,
    nombre: "Solicitudes de concepto relacionadas con una situación contractual",
    autogestion: false,
    complejidad: 3,
  },
  {
    id: 6,
    nombre: "Solicitudes de concepto relacionadas con el análisis de normatividad.",
    autogestion: false,
    complejidad: 3,
  },
  {
    id: 7,
    nombre: "Elaboración de estudio de títulos",
    autogestion: false,
    complejidad: 2,
  },
  {
    id: 8,
    nombre: "Acuerdo de cesión base de datos",
    autogestion: true,
    complejidad: 2,
  },
  {
    id: 9,
    nombre: "Elaboración de acuerdo de confidencialidad",
    autogestion: true,
    complejidad: 1,
  },
  {
    id: 10,
    nombre: "Acuerdo de tratamiento de datos",
    autogestion: true,
    complejidad: 2,
  },
  {
    id: 11,
    nombre: "Compromiso de confidencialidad de proponentes",
    autogestion: true,
    complejidad: 1,
  },
  {
    id: 12,
    nombre: "Certificación de prestadores de servicios persona jurídica",
    autogestion: true,
    complejidad: 1,
  },
  {
    id: 13,
    nombre: "Certificación de prestadores de servicios persona natural",
    autogestion: true,
    complejidad: 1,
  },
  {
    id: 14,
    nombre: "Certificado de contratistas médicos",
    autogestion: true,
    complejidad: 1,
  },
  {
    id: 15,
    nombre: "Poder para asistencia a asamblea",
    autogestion: true,
    complejidad: 1,
  },
  {
    id: 16,
    nombre: "Poder para notificaciones",
    autogestion: true,
    complejidad: 1,
  },
  {
    id: 17,
    nombre: "Poder para tramitar licencias",
    autogestion: true,
    complejidad: 1,
  },
  {
    id: 18,
    nombre: "Poder conciliación glosas",
    autogestion: true,
    complejidad: 1,
  },
  {
    id: 19,
    nombre: "Respuesta a derecho de petición",
    autogestion: false,
    complejidad: 3,
  },
  {
    id: 20,
    nombre: "Acta de inicio",
    autogestion: false,
    complejidad: 1,
  },
  {
    id: 21,
    nombre: "Suspensión",
    autogestion: false,
    complejidad: 1,
  },
  {
    id: 22,
    nombre: "Reinicio",
    autogestion: false,
    complejidad: 1,
  },
  {
    id: 23,
    nombre: "Liquidación",
    autogestion: false,
    complejidad: 1,
  },
  {
    id: 24,
    nombre: "Acta de cierre de contratos",
    autogestion: true,
    complejidad: 1,
  },
  {
    id: 25,
    nombre: "Minuta Otrosí a contrato de promesa de compraventa - Cambio de estado civil",
    autogestion: true,
    complejidad: 1,
  },
  {
    id: 26,
    nombre: "Minuta acta de resciliación sin arras.",
    autogestion: true,
    complejidad: 1,
  },
  {
    id: 27,
    nombre: "Contrato de promesa de compraventa",
    autogestion: true,
    complejidad: 1,
  },
  {
    id: 28,
    nombre: "Carta de no siniestro.",
    autogestion: true,
    complejidad: 1,
  },
  {
    id: 29,
    nombre: "Requerimiento de entidades del estado",
    autogestion: false,
    complejidad: 2,
  },
  {
    id: 30,
    nombre: "Evaluación de posibles siniestros",
    autogestion: false,
    complejidad: 2,
  },
  {
    id: 31,
    nombre: "Conceptos sobre pólizas a solicitar en las convocatorias",
    autogestion: false,
    complejidad: 2,
  },
  {
    id: 32,
    nombre: "Conceptos sobre pólizas de contratos con cuantía mayor a 132 SMLMV",
    autogestion: false,
    complejidad: 2,
  },
  {
    id: 33,
    nombre: "Minuta otrosí a contrato de promesa de compraventa - Modificación forma de pago",
    autogestion: true,
    complejidad: 1,
  },
  {
    id: 34,
    nombre: "Minuta otrosí a contrato de promesa de compraventa - Cesión",
    autogestion: true,
    complejidad: 1,
  },
  {
    id: 35,
    nombre: "Minuta acta de resciliación con arras.",
    autogestion: true,
    complejidad: 1,
  },
  {
    id: 36,
    nombre: "Acta de cierre del contrato.",
    autogestion: true,
    complejidad: 1,
  },
  {
    id: 37,
    nombre: "Minuta acta de resciliación sin arras.",
    autogestion: true,
    complejidad: 1,
  },
  {
    id: 38,
    nombre: "Minuta acta de resciliación con arras.",
    autogestion: true,
    complejidad: 1,
  },
  {
    id: 39,
    nombre: "Minuta carta no siniestro.",
    autogestion: true,
    complejidad: 1,
  },
  {
    id: 40,
    nombre: "Minuta otrosi a contrato de promesa de compraventa - cambio de estado civil.",
    autogestion: true,
    complejidad: 1,
  },
  {
    id: 41,
    nombre: "Minuta otrosi a contrato de promesa de compraventa - cesión",
    autogestion: true,
    complejidad: 1,
  },
  {
    id: 42,
    nombre: "Minuta otrosi a contrato promesa de compraventa - modificación forma de pago",
    autogestion: true,
    complejidad: 1,
  },
];
export const listSolicitudOld = [
  {
    id: 1,
    nombre: 'Concepto',
    autogestion: false
  },
  {
    id: 2,
    nombre: 'Revisión de documentos de contenido legal',
    autogestion: false
  },
  {
    id: 3,
    nombre: 'Respuesta a derecho de petición',
    autogestion: false
  },
  {
    id: 4,
    nombre: 'Revisión escrituras públicas',
    autogestion: false
  },
  {
    id: 5,
    nombre: 'Elaboración de estudios de títulos',
    autogestion: false
  },
  {
    id: 6,
    nombre: 'Elaboración de poderes',
    autogestion: true
  },
  {
    id: 7,
    nombre: 'Elaboración de acuerdos de confidencialidad',
    autogestion: true
  },
  {
    id: 8,
    nombre: 'Atención de requerimiento de entidades estatales',
    autogestion: false
  },
  {
    id: 9,
    nombre: 'Elaboración de acuerdos de transmisión o de cesión de datos',
    autogestion: true
  },
  {
    id: 10,
    nombre: 'Elaboración de las certificaciones',
    autogestion: true
  },
]

export const listTemaConsulta = [
  {
    id: 1,
    nombre: 'ENTREGA DE HISTORIAS CLÍNICAS'
  },
  {
    id: 2,
    nombre: 'PROTECCIÓN AL CESANTE'
  },
  {
    id: 3,
    nombre: 'MANUAL DE CONTRATACIÓN'
  },
  {
    id: 4,
    nombre: 'GARANTÍA LEGAL'
  },
  {
    id: 5,
    nombre: 'PAGO DE SUBSIDIOS'
  },
  {
    id: 6,
    nombre: 'PROTECCIÓN DE DATOS PERSONALES'
  },
]

export const listEncuesta = [
  {
    id: 1,
    nombre: 'Atención',
  },
  {
    id: 2,
    nombre: 'Calidad de la respuesta',
  },
  {
    id: 3,
    nombre: 'Completitud de la respuesta',
  },
  {
    id: 4,
    nombre: 'Información suministrada',
  },
  {
    id: 5,
    nombre: 'Se dió respuesta a su consulta',
  },
]

export const listComplejidad = [
  {
    id: 1,
    nombre: 'Baja'
  },
  {
    id: 2,
    nombre: 'Media'
  },
  {
    id: 3,
    nombre: 'Alta'
  },
]

export const listTipoSolicitud = [
  {
    value: ETipoSolicitud.LICITACION,
    label: 'Licitación',
  },
  {
    value: ETipoSolicitud.COTIZACION,
    label: 'Cotización Directa',
  },
  {
    value: ETipoSolicitud.RFI,
    label: 'RFI',
  },
]

export const listTipoLicitacion = [
  {
    value: 1,
    label: 'Abierta',
  },
  {
    value: 2,
    label: 'Cerrada',
  },
  {
    value: 3,
    label: 'RFP',
  },
  {
    value: 4,
    label: 'RFQ',
  },
]

export const listTipoOferta = [
  {
    value: 1,
    label: 'Valor Total',
  },
  {
    value: 2,
    label: 'Precios Unitarios',
  },
]

export const listSituacion = [
  {
    value: 1,
    label: 'Material/equipo o Servicio con Distribuidor Fabricante, Proveedores Exclusivos y/o Únicos		',
  },
  {
    value: 2,
    label: 'Procesos no competitivos',
  },
  {
    value: 3,
    label: 'Compras Delegadas',
  },
  {
    value: 4,
    label: 'Contratación de los Servicios Técnicos Especializados que se mencionan a continuación',
  },
  {
    value: 5,
    label: 'Condiciones del mercado',
  },
]

export const listDocsRequeridos = [
  {
    value: 1,
    label: 'Anexos Técnicos',
    codigo: 'anexos_tecnicos',
  },
  {
    value: 2,
    label: 'Anexos Funcionales',
    codigo: 'anexos_funcionales',
  },
  {
    value: 3,
    label: 'Convenios de Formalización de Contrato',
    codigo: 'convenios_contrato',
  },
  {
    value: 4,
    label: 'Formato de Precios',
    codigo: 'formato_precios',
  },
  {
    value: 5,
    label: 'Instrucciones a los Proponentes',
    codigo: 'instrucciones_proponentes',
  },
  {
    value: 6,
    label: 'Otros',
    codigo: 'otros',
  },
  {
    value: 7,
    label: 'Planes y Documentos del Contrato',
    codigo: 'planes_contrato',
  },
  {
    value: 8,
    label: 'Términos de Referencia',
    codigo: 'terminos_referencia',
  },
  {
    value: 9,
    label: 'Oferta Mercantil',
    codigo: 'oferta_mercantil',
  },
]

export const listTipoDocsRequeridos = [
  {
    value: 1,
    label: 'PDF',
  },
  {
    value: 2,
    label: 'XLSX',
  },
]

export const listSiNo = [
  {
    value: 1,
    label: 'Si'
  },
  {
    value: 2,
    label: 'No'
  }
];

export const listTipoContrato = [
  {
    value: 1,
    label: 'Contrato',
  },
  {
    value: 2,
    label: 'Orden',
  },
];

export const listAprobar = [
  {
    value: 1,
    label: 'Aprobado'
  },
  {
    value: 2,
    label: 'No aprobado'
  }
];

export const listDocsProveedores = [
  {
    value: 1,
    label: 'Anexos',
    default: true,
  },
]

export const listActividades = [
  {
    value: 1,
    label: 'Dar Intención de Participación',
    default: true,
  },
  {
    value: 2,
    label: 'Recepción de Propuestas',
    default: true,
  },
  {
    value: 3,
    label: 'Apertura de Propuestas',
    default: true,
  },
  {
    value: 4,
    label: 'Actividad 1',
    default: false,
  },
  {
    value: 5,
    label: 'Actividad 2',
    default: false,
  },
  {
    value: 6,
    label: 'Actividad 3',
    default: false,
  },
  {
    value: 7,
    label: 'Actividad 4',
    default: false,
  },
  {
    value: 8,
    label: 'Actividad 5',
    default: false,
  },
  {
    value: 9,
    label: 'Socialización de la Negociación',
    default: false,
  },
]

export const listCriterios = [
  {
    value: 1,
    label: 'Criterio 1',
  },
  {
    value: 2,
    label: 'Criterio 2',
  },
  {
    value: 3,
    label: 'Criterio 3',
  },
  {
    value: 4,
    label: 'Criterio 4',
  },
];

export const listTipoFecha = [
  {
    value: 1,
    label: 'Solo fecha',
  },
  {
    value: 2,
    label: 'Fecha y hora',
  },
  {
    value: 3,
    label: 'Sin fecha ni hora',
  },
]

export const listRolesPrecontractual = [
  {
    id: 1,
    nombre: "Solicitante",
    role: EGroupsNames.SOLICITANTE,
  },
  {
    id: 7,
    nombre: "Validador Listas Restrictivas",
    role: EGroupsNames.REGISTRO_TERCEROS,
  },
  {
    id: 8,
    nombre: "Evaluador",
    role: EGroupsNames.EVALUADOR,
  },
  {
    id: 101,
    nombre: "Aprobador Nivel 1 (Gerencia)",
    role: EGroupsNames.APROBADOR_NIV1,
  },
  {
    id: 102,
    nombre: "Aprobador Nivel 2 (Dirección)",
    role: EGroupsNames.APROBADOR_NIV2,
  },
  {
    id: 103,
    nombre: "Aprobador Nivel 3 (Jefatura)",
    role: EGroupsNames.APROBADOR_NIV3,
  },
]

// PROCESOS JURIDICOS
export const listMotivo = [
  {
    id: 1,
    nombre: "Accident in itinere / workplace",
  },
  {
    id: 2,
    nombre: "Different law interpretation of salary + benefits",
  },
  {
    id: 3,
    nombre: "Other",
  },
  {
    id: 4,
    nombre: "Rehired as staff claimed to Man",
  },
  {
    id: 5,
    nombre: "Rehired as staff claimed to Man´s client",
  },
  {
    id: 6,
    nombre: "Rehired as staff claimed to Man´s client and Man",
  },
  {
    id: 7,
    nombre: "Salaries + Other Benefitd",
  },
  {
    id: 8,
    nombre: "Severance",
  },
  {
    id: 9,
    nombre: "Severance + overtime + other benefits",
  },
];

export const listidareas = [
  {
    id: 1,
    nombre: "Administración salud",
  },
  {
    id: 2,
    nombre: "Servicios complementarios y gestión de la calidad",
  },
  {
    id: 3,
    nombre: "Prestación servicios de salud",
  },
  {
    id: 4,
    nombre: "Tegnología, procesos e información salud",
  },
  {
    id: 5,
    nombre: "Gerencia administrativa y financiera mercadeo social",
  },
  {
    id: 6,
    nombre: "Gerencia comercial droguerías",
  },
  {
    id: 7,
    nombre: "Gerencia comercial supermercados",
  },
  {
    id: 8,
    nombre: "Gerencia gestión de la demanda y logística",
  },
  {
    id: 9,
    nombre: "Gerencia de educación y cultura",
  },
  {
    id: 10,
    nombre: "Gerencia recreación y deportes",
  },
  {
    id: 11,
    nombre: "Gerencia de subsidio",
  },
  {
    id: 12,
    nombre: "Gerencia de fomento y desarrollo empresarial",
  },
  {
    id: 13,
    nombre: "Gerencia de vivienda",
  },
  {
    id: 14,
    nombre: "Gerencia de crédito",
  },
  {
    id: 15,
    nombre: "Gerencia de alianzas y alimentos y bebidas",
  },
  {
    id: 16,
    nombre: "Gerencia de mercadeo",
  },
  {
    id: 17,
    nombre: "Gerencia de canales y experiencia al cliente",
  },
  {
    id: 18,
    nombre: "Gerencia fidelizacíon y ventas",
  },
  {
    id: 19,
    nombre: "Gerencia de portafolio",
  },
  {
    id: 20,
    nombre: "Gerencia regional",
  },
  {
    id: 21,
    nombre: "Gerencia de ventas, pymes, personas y comunidades",
  },
  {
    id: 22,
    nombre: "Gerencia tegnología de informacíon",
  },
  {
    id: 23,
    nombre: "Gerencia de infraestructura",
  },
  {
    id: 24,
    nombre: "Gerencia de compras corporativas",
  },
  {
    id: 25,
    nombre: "Gerencia de seguridad corporativa",
  },
  {
    id: 26,
    nombre: "Gerencia financiera",
  },
  {
    id: 27,
    nombre: "Gerencia de transformación digital",
  },
  {
    id: 28,
    nombre: "Gerencia proyectos corporativos",
  },
  {
    id: 29,
    nombre: "Gerencia estratégica, procesos e innovació, gerencia gestión",
  },
  {
    id: 30,
    nombre: "Dirección de asuntos corporativos",
  },
];

export const listEntidades = [
  {
    id: 1,
    nombre: "Centros de conciliación",
  },
  {
    id: 2,
    nombre: "Fiscalía",
  },
  {
    id: 3,
    nombre: "Juzgados",
  },
  {
    id: 4,
    nombre: "Otros",
  },
  {
    id: 5,
    nombre: "UGPP",
  },
  {
    id: 6,
    nombre: "Procuraduría",
  },
  {
    id: 7,
    nombre: "SIC",
  },
  {
    id: 8,
    nombre: "Contraloría",
  },
  {
    id: 9,
    nombre: "Superintendencia de Subsidio Familiar",
  },
  {
    id: 10,
    nombre: "Dagma",
  },
  {
    id: 11,
    nombre: "Superintendencia Nacional de Salud",
  },
  {
    id: 12,
    nombre: "Ministerio de Trabajo",
  },
];

export const listTipoTiempo = [
  {
    id: 1,
    nombre: "Días corrientes",
  },
  {
    id: 2,
    nombre: "Días hábiles",
  },
  {
    id: 3,
    nombre: "Horas",
  },
];

export const listNivelProbabilidad = [
  {
    id: 1,
    nombre: "Remoto",
  },
  {
    id: 2,
    nombre: "Eventual",
  },
  {
    id: 3,
    nombre: "Probable",
  },
];

export const listTipoProceso = [
  {
    id: 1,
    nombre: "Tutelas",
  },
  {
    id: 2,
    nombre: "Requerimientos de Información",
  },
  {
    id: 3,
    nombre: "Derechos de petición",
  },
  {
    id: 4,
    nombre: "Procesos Administrativos",
  },
  {
    id: 5,
    nombre: "Conciliaciones",
  },
  {
    id: 6,
    nombre: "Otro",
  },
];

export const listMotivoJuridico = [
  {
    id: 1,
    nombre: "Atención en salud",
  },
  {
    id: 2,
    nombre: "Protección de datos personales / Habeas data",
  },
  {
    id: 3,
    nombre: "Contestación Derechos de Petición",
  },
  {
    id: 4,
    nombre: "Solicitud de Reconocimiento de Subsidio",
  },
  {
    id: 5,
    nombre: "Solicitud de Conciliación por Evento Médico",
  },
  {
    id: 6,
    nombre:
      "Solicitud de Conciliación por responsabilidad civil extracontractual",
  },
  {
    id: 7,
    nombre: "PQRs",
  },
  {
    id: 8,
    nombre: "Reclamación salarios y otros beneficios",
  },
];

export const listPretensiones = [
  {
    id: 1,
    nombre: "Económicas",
  },
  {
    id: 2,
    nombre: "Otras",
  },
  {
    id: 3,
    nombre: "Ambas",
  },
];

export const listParteEmpresa = [
  {
    id: 1,
    nombre: "Demandante",
  },
  {
    id: 2,
    nombre: "Demandado",
  },
  {
    id: 3,
    nombre: "N/A",
  },
];

export const listRolesJuriJuridico = [
  {
    id: 3,
    nombre: "Abogado",
  },
];

export const listTipoMoneda = [
  {
    id: 1,
    nombre: "Euro",
  },
  {
    id: 2,
    nombre: "Libras",
  },
  {
    id: 3,
    nombre: "Pesos",
  },
  {
    id: 4,
    nombre: "Dólar",
  },
];
