import React, { useState, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import dev from '../../core/enviroments';
import { downloadFile } from '../helpers/generales';

//{dev.URL_SERVER}/plantillas/nombre.docx
const DocInput: FC<IDocInput> = ({
  file,
  nombreDoc,
  modulo = 'plantillas'
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<Boolean>(false);
  const [thumb, setThumb] = useState<any>();

  useEffect(() => {
    if (file) {
      setLoading(true);
    }
    let reader = new FileReader();
    reader.onloadend = () => {
      setLoading(false);
      setThumb(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  }, [file])

  const handleView = () => {
    downloadFile(`${dev.URL_SERVER}/${modulo}/${nombreDoc}`, (file) ? file.name : t('Archivo cargado'));
  }

  if (loading) { return <p>{t('Cargando')}...</p>; }

  return (
    <span>
      {file ? (
        <div>
          <span>{`${t('Archivo cargado')}: ${file.name}`}</span>
        </div>
      ) : (
        <>
          {nombreDoc && (
            <Button
              title={t(`Descargar ${modulo}`)}
              onClick={handleView}
            >
              <i className="uil-file-download"></i>
              {t(`Descargar ${modulo}`)}
            </Button>
          )}
        </>
      )}

    </span>

  );
}

interface IDocInput {
  file: File | null
  nombreDoc: String | null
  modulo?: String | null
};

export default DocInput

