import { getRequest, updateItem, deleteItem } from '../peticionesService';
import urlApi from '../../api';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';
import { ICodigosPolizas, IFormCodigosPolizas } from '../../models/polizas/codigosPolizasModel';

/**
 * Permite obtener los registros en la bd
 */
export const obtenerCodigosPolizas = async (params?): Promise<ICodigosPolizas[]> => {
  let data: ICodigosPolizas[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_CODIGOS_POLIZAS, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const guardarCodigosPolizas = async (
  values: IFormCodigosPolizas,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = (!values.id) ? urlApi.CREAR_CODIGOS_POLIZAS : `${urlApi.EDIT_CODIGOS_POLIZAS}${values.id}`
  return await updateItem(url, values, notification);
};

/**
 * Permite borrar un registro
 * @param id 
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const borrarCodigosPolizas = async (
  id: number,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = `${urlApi.BORRAR_CODIGOS_POLIZAS}${id}`;
  return await deleteItem(url, notification);
};

/**
 * Permite mostrar un registro
 * @param token 
 * @returns 
 */
export const mostrarCodigosPolizas = async (
    codigo: string,
    token: string,
    notification?: (
      msj: string,
      type: mainTypeMsj
    ) => void
  ): Promise<ICodigosPolizas|null> => {
  let data: ICodigosPolizas|null = null;
  try {
    const resp = await getRequest(`${urlApi.SHOW_CODIGOS_POLIZAS}${codigo}/${token}`, null, true, notification);
    data = resp.data.record;
  } catch (error) {
    console.error(error);
  }
  return data;
};
