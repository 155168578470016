import { getRequest, updateItem, deleteItem } from '../peticionesService';
import urlApi from '../../api';
import { IFormTerceros, ITerceros } from '../../models/administracion/tercerosModel';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';

/**
 * Permite obtener los registros en la bd
 */
export const obtenerTerceros = async (keyParam?: string, valueParam?: string): Promise<ITerceros[]> => {
  let data: ITerceros[] = [];
  const url = (keyParam) ?
    `${urlApi.LIST_TERCEROS}?criteria=${keyParam}&valuecriteria=${valueParam}` : urlApi.LIST_TERCEROS;
  try {
    const resp = await getRequest(url);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener los registros en la bd
 */
export const consultaDetallada = async (keyParam: string, valueParam: any): Promise<ITerceros[]> => {
  let data: ITerceros[] = [];
  const url = `${urlApi.LIST_TERCEROS}?criteria=${keyParam}&valuecriteria=${valueParam}`;
  try {
    const resp = await getRequest(url);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const guardarTercero = async (
  values: IFormTerceros,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<ITerceros> => {
  const url = (!values.id) ? urlApi.CREAR_TERCERO : `${urlApi.EDIT_TERCERO}${values.id}`
  const resp = await updateItem(url, values, notification, false, true, true);
  return resp.data.record;
};

/**
 * Permite borrar un registro
 * @param id 
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const borrarTercero = async (
  id: number,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = `${urlApi.BORRAR_TERCERO}${id}`;
  return await deleteItem(url, notification);
};
