import { getRequest, updateItem, deleteItem } from '../peticionesService';
import urlApi from '../../api';
import { IEstado, IFormEstado, IModulos } from '../../models/administracion/estadosModel';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';

/**
 * Permite obtener los registros en la bd
 */
export const obtenerEstados = async (): Promise<IEstado[]> => {
  let data: IEstado[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_ESTADOS);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener los modulos registrados en la bd
 */
export const obtenerModulos = async (): Promise<IModulos[]> => {
  let data: IModulos[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_MODULES);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const guardarEstado = async (
  values: IFormEstado,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = (!values.id) ? urlApi.CREAR_ESTADO : `${urlApi.EDIT_ESTADO}${values.id}`
  return await updateItem(url, values, notification);
};

/**
 * Permite borrar un registro
 * @param id 
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const borrarEstado = async (
  id: number,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = `${urlApi.BORRAR_ESTADO}${id}`;
  return await deleteItem(url, notification);
};
