import { Field, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { Col, FormFeedback, FormGroup, Label, Row } from "reactstrap";
import FilesUpload from "../../../shared/components/FilesUpload";

const DocumentosPolizas = ({ disabled }) => {
  const { errors, touched } = useFormikContext<any>();
  const { t } = useTranslation();
  const errorPoliza = (errors?.anexos as any)?.poliza_cargada;
  const errorPago = (errors?.anexos as any)?.documento_pago;
  
  return (
    <Row>
      <Col sm={6}>
        <FormGroup>
          <Label>{t('Documentos de póliza')}</Label>
          <Field
            as={FilesUpload}
            type="select"
            name="anexos"
            categoria="documento_poliza"
            invalid={errors.anexos && touched.anexos}
            disabled={disabled}
          />
          <FormFeedback style={{ display: 'block' }}>{t(errorPoliza as string)}</FormFeedback>
        </FormGroup>
      </Col>
      <Col sm={6}>
        <FormGroup>
          <Label>{t('Documentos de pago')}</Label>
          <Field
            as={FilesUpload}
            type="select"
            name="anexos"
            categoria="documento_pago"
            invalid={errors.anexos && touched.anexos}
            disabled={disabled}
          />
          <FormFeedback style={{ display: 'block' }}>{t(errorPago as string)}</FormFeedback>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default DocumentosPolizas;
