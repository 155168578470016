import React, {useEffect, useState} from 'react';
import {MUIDataTableColumn} from 'mui-datatables';
import urlApi from '../../../../core/api';
import {useTranslation} from 'react-i18next';
import {useObtenerProcesosDinamicosAsesorias} from "../../../../core/services/asesorias/asesoriasService";
import {Link} from "react-router-dom";
import {EModulos} from "../../../../shared/utilities";

const initialState = {
  module: EModulos.ASESORIAS.nombre,
  loading: false,
  length: 5,
  columns: [],
  order: [],
  start: 0,
  count: 0,
  page: 0,
  searchText: '',
}

let timeSearch: any;

const url = urlApi.PENDIENTES_USUARIO;

export const useListPendientesAsesoria = () => {
  const {t} = useTranslation();
  const [datatable, setDatatable] = useState<any>(initialState);
  const [asesorias, setAsesorias] = useState<any[]>([]);
  const {handleGetAsesorias} = useObtenerProcesosDinamicosAsesorias(url);

  const columns: MUIDataTableColumn[] = [
    {
      name: t('Consecutivo'),
      options: {
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Consecutivo')}</h5>
        ),
        customBodyRender: ([consecutivo, id]) => {
          return (
            <Link to={`/asesorias/edit/${id}`}>{consecutivo}</Link>
          );
        }
      }
    },
    {
      name: t('Tipo Asesorías'),
      options: {
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Tipo Asesorías')}</h5>
        ),
      }
    },
    {
      name: t('Área Involucrada'),
      options: {
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Área Involucrada')}</h5>
        ),
      }
    },
    {
      name: t('Usuario Solicitante'),
      options: {
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Usuario Solicitante')}</h5>
        ),
      }
    },
    {
      name: t('Abogado'),
      options: {
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Abogado')}</h5>
        ),
      }
    },
    {
      name: t('Estado'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Estado')}</h5>
        ),
      }
    }
  ];

  /**
   * Obtiene los procesos guardados en la bd
   */
  const listarAsesorias = async () => {
    setDatatable({
      ...initialState,
      loading: true,
    });
    const {data = {}} = await handleGetAsesorias({
      ...initialState,
      order: undefined,
      count: undefined,
      loading: undefined,
      page: undefined,
    });
    const {data: records = [], recordsTotal = 0, page} = data;
    setDatatable({
      ...initialState,
      page: page - 1,
      count: recordsTotal,
      loading: false,
    });
    setAsesorias(records);
  };

  const changePage = async (pag) => {

    setDatatable({
      ...datatable,
      loading: true
    });
    const {data = {}} = await handleGetAsesorias({
      ...datatable,
      order:
        datatable?.order.length > 0 ? datatable?.order.length : undefined,
      count: undefined,
      loading: undefined,
      page: undefined,
      start: datatable?.length * pag,
    });
    const {data: records = [], recordsTotal = 0, page = 0} = data;
    setDatatable({
      ...datatable,
      count: recordsTotal,
      page: page - 1,
      loading: false,
    });
    setAsesorias(records);

  };

  const handleSearch = async (inputValue: string) => {

    if (inputValue?.trim().length < 3 || inputValue?.trim().length === undefined) {
      if (inputValue?.trim().length === 0) {
        listarAsesorias();
      }
      return inputValue;
    } else {
      (timeSearch) && clearTimeout(timeSearch);
      timeSearch = setTimeout(async () => {
        setDatatable({
          ...initialState,
          searchText: inputValue,
          loading: true
        })
        const { data = {} } = await handleGetAsesorias({
          ...initialState,
          searchText: inputValue,
          order: undefined,
          count: undefined,
          loading: undefined,
          page: undefined,
        });
        const { data: records = [], recordsTotal = 0, page = 0 } = data;
        setDatatable({
          ...datatable,
          searchText: inputValue,
          page: page - 1,
          count: recordsTotal,
          loading: false
        })
        setAsesorias(records);
      }, 1000);
    }
  };

  useEffect(() => {
    listarAsesorias();
  }, []);

  return {
    asesorias,
    columns,
    datatable,
    changePage,
    handleSearch
  }
};
