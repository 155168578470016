import { getRequest, updateItem, deleteItem, postRequestForm } from '../peticionesService';
import urlApi from '../../api';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';
import { IRequerimientos } from '../../models/polizas/requerimientosModel';
import { ICaracteristicas } from '../../models/polizas/caracteristicasModel';
import { IBasePolizas } from '../../models/polizas/baseModel';
import { IPolizas } from "../../models/polizas/polizasModel";
import { IAmparos } from "../../models/polizas/amparosModel";
import { IPoliToken } from "../../models/polizas/poliTokenModel";

/**
 * Permite obtener el listado de requerimientos desde la bd
 */
export const obtenerRequerimientos = async (params?): Promise<IRequerimientos[]> => {
  let data: IRequerimientos[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_POLIZA_REQUERIMIENTOS, params);
    data = resp.data.records;
  } catch (error) {
    console.error("Error al obtenerel listado de requerimientos desde el backend: ",error);
  }
  return data;
};

/**
 * Permite obtener el listado de características desde la bd
 */
export const obtenerCaracteristicas = async (params?): Promise<ICaracteristicas[]> => {
  let data: ICaracteristicas[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_POLIZA_CARACTERISTICAS, params);
    data = resp.data.records;
  } catch (error) {
    console.error("Error al obtener el listado de características desde el backend: ",error);
  }
  return data;
};

/**
 * Permite obtener los registros en la bd
 */
export const obtenerBasePolizas = async (params?): Promise<IBasePolizas[]> => {
  let data: IBasePolizas[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_BASE_POLIZAS, params);
    data = resp.data.records;
  } catch (error) {
    console.error("Error al obtener pólizas en token de tercero:",error);
  }
  return data;
};

/**
 * Obtiene el registro base de las pólizas si el token es válido
 * @param token
 * @returns
 */
export const obtenerPoliToken = async (token: string): Promise<IPoliToken|null> => {
  let data: IPoliToken|null = null;
  try {
    const resp = await getRequest(`${urlApi.SHOW_TOKEN_POLIZAS}${token}`);
    data = resp.data;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite mostrar un registro
 * @param token
 * @returns
 */
export const mostrarBasePoliza = async (token: string): Promise<IBasePolizas|null> => {
  let data: IBasePolizas|null = null;
  try {
    const resp = await getRequest(`${urlApi.SHOW_BASE_POLIZAS}${token}`);
    data = resp.data.record;
    if (resp.data.id && data) {
      data = resp.data.record;
    }
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema
 */
export const guardarBasePolizas = async (
  values: IBasePolizas,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = (!values.id) ? urlApi.CREAR_BASE_POLIZAS : `${urlApi.EDIT_BASE_POLIZAS}${values.id}`
  return await updateItem(url, values, notification);
};


/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const guardarPolizas = async (
  values: IPolizas,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = (!values.id) ? urlApi.CREAR_POLIZAS : `${urlApi.EDIT_POLIZAS}${values.id}`
  return await updateItem(url, values, notification);
};

/**
 * Permite borrar un registro
 * @param id 
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const borrarPolizas = async (
  id: number,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = `${urlApi.BORRAR_POLIZAS}${id}`;
  return await deleteItem(url, notification);
};

/**
 * Permite obtener los registros en la bd
 */
export const obtenerPolizas = async (params: any = undefined): Promise<any[]> => {
  let data: any[] = [];
  try {
    const url = urlApi.LIST_POLIZAS;
    const resp = await getRequest(url, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Obtiene el registro base de las pólizas si el token es válido
 * @param token
 * @returns
 */
export const obtenerPolizasToken = async (token: string|null): Promise<IPolizas[]> => {
  let data: IPolizas[] = [];
  try {
    const resp = await getRequest(`${urlApi.LIST_POLIZAS_TOKEN}${token}`);
    data = resp.data?.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener los registros en la bd
 */
export const obtenerPoliza = async (id): Promise<any | null> => {
  let data: any = null;
  try {
    const url = `${urlApi.SHOW_POLIZAS}${id}`;
    const resp = await getRequest(url);
    data = resp.data.record;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener los registros en la bd
 */
export const resendToken = async (baseId): Promise<any | null> => {
  let data: any = null;
  try {
    const url = `${urlApi.RESEND_TOKEN_POLIZAS}${baseId}`;
    const resp = await getRequest(url);
    data = resp.data;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema
 */
export const savePoliza = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = (!values.id) ? urlApi.CREAR_POLIZAS : `${urlApi.EDIT_POLIZAS}${values.id}`;
  return await updateItem(url, values, notification, true);
};

/**
 * Permite obtener los registros en la bd
 */
export const obtenerAmparos = async (params: any = undefined): Promise<any[]> => {
  let data: any[] = [];
  try {
    const url = urlApi.LIST_AMPAROS;
    const resp = await getRequest(url, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Obtiene el registro base de los amparos si el token es válido
 * @param token
 * @returns
 */
export const obtenerAmparosToken = async (params: any = undefined): Promise<any[]> => {
  let data: any[] = [];
  try {
    const resp = await getRequest(`${urlApi.LIST_AMPAROS_TOKEN}${params.token}`);
    data = resp.data?.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema
 */
export const saveAmparos = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = (!values.id) ? urlApi.CREAR_AMPAROS : `${urlApi.EDIT_AMPAROS}${values.id}`;
  return await updateItem(url, values, notification);
};
