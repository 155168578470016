import React, {FC, useState} from "react";
import DataTableSkeleton from "../../../../../shared/components/DataTableSkeleton";
import ListContratos from "./ListContratos";
import {useListContratos} from "./useListContratos";

const ContratosPorArea: FC<IContratosPorAreas> = ({ modulo, area, estado, handleLink }) => {

  const {
    procesos,
    columns,
    datatable,
    changePage
  } = useListContratos({modulo, estado, area, handleLink});

  return (
    <>
      {datatable?.loading ? (
        <DataTableSkeleton/>
      ) : (
        <ListContratos
          columns={columns}
          procesos={procesos}
          datatable={datatable}
          changePage={changePage}
        />
      )}
    </>
  );
}

interface IContratosPorAreas {
  modulo: string,
  area: string,
  estado: string,
  handleLink: any
}

export default ContratosPorArea;
