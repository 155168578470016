import React, {FC, useEffect, useState} from 'react';
import MUIDataTable, {MUIDataTableColumn} from 'mui-datatables';
import {optionsDataTable} from '../../../../../shared/utilities';
import {useHistory} from "react-router-dom";
import {IEstado} from "../../../../../core/models/administracion/estadosModel";
import {useUserContext} from '../../../../../shared/contexts/userContext';
import {useTranslation} from 'react-i18next';
import {listAreasContratos} from "../../../../../core/services/listasDesplegables";
import {useListContratos} from "../contratosPorArea/useListContratos";

const ListContractos: FC<IListProcesos> = ({modulo, area, handleLink}) => {

  const estado = "All";

  const {
    procesos,
    columns,
    datatable,
    changePage
  } = useListContratos({modulo, estado, area, handleLink});

  const {count, length, page, searchText} = datatable;

  const options = {
    ...optionsDataTable,
    search: false,
    count,
    rowsPerPage: length,
    page,
    rowsPerPageOptions: [],
    viewColumns: false,
    serverSide: true,
    sort: false,
    filter: false,
    searchText,
    caseSensitive: false,
    onChangePage(currentPage) {
      changePage(currentPage);
    },
  };

  return (
    <>
      <MUIDataTable
        title={""}
        data={procesos.map(proceso => ([
          [proceso.consecutivo, proceso.id],
          listAreasContratos.find(item => item.value == proceso.area)?.label,
          proceso.nombre,
        ]))}
        columns={columns}
        options={options}
      />
    </>
  );
}

interface IListProcesos {
  modulo: string,
  area: string,
  handleLink: (id: number, modulo: string) => void
}

export default ListContractos;
