import React from "react";
import {FC} from "react";
import PendientesContractual from "./PendientesContractual";
import PendientesPostContractual from "./PendientesPostContractual";
import PendienteAsesoria from "./PendientesAsesoria";
import PendienteJuridico from "./PendientesJuridico";

const Index: FC<any> = () => {

  return (
    <>
      <PendientesContractual />
      <PendientesPostContractual />
      <PendienteAsesoria />
      <PendienteJuridico />
    </>
  );
}

export default Index;
